import React, { useState, useEffect } from 'react';
import { VStack, Text, Button, useBreakpointValue } from '@chakra-ui/react';

import AngelAnimation from '../../PostCreation/AngelAnimation';
import { useNavigate } from 'react-router-dom';

const blessings = [
    "May the Lord bless you and keep you and make his face shine upon you and be gracious unto you and give you peace. In the name of the Father, the Son, and the Holy Spirit. Amen.",
    "The Lord will guide you always; he will satisfy your needs in a sun-scorched land and will strengthen your frame.",
    "May the God of hope fill you with all joy and peace as you trust in him, so that you may overflow with hope by the power of the Holy Spirit.",
    "The blessing of the Lord brings wealth, without painful toil for it.",
    "May the grace of the Lord Jesus Christ, and the love of God, and the fellowship of the Holy Spirit be with you.",
    "May the Lord you give strength and May the Lord bless you with peace!",
    "And my God will meet all your needs according to the riches of his glory in Christ Jesus.",
    "Blessed are those who hunger and thirst for righteousness, for they will be filled.",
    "May the Lord make your love increase and overflow for each other and for everyone else, just as ours does for you.",
    "Now may the Lord of peace himself give you peace at all times and in every way.",
    "The grace of the Lord Jesus Christ be with your spirit.",
    "May the Lord richly bless both you and your children.",
    "May the God of peace be with you all. Amen.",
    "May the Lord bless you and keep you; the Lord make his face shine on you and be gracious to you.",
    "May God bless you and keep you safe always.",
    "May The Lord keep you from all harm— he will watch over your life; the Lord will watch over your coming and going both now and forevermore.",
    "May the Lord grant you abundant prosperity",
    "May the Lord restore you to health and heal all your wounds",
    "Every good and perfect gift is from above, coming down from the Father of the heavenly lights. Your heavenly Father shall bless you.",
    "God is able to bless you abundantly, so that in all things at all times, having all that you need, you will abound in every good work."
];


const BlessingPage = () => {
    const [currentBlessing, setCurrentBlessing] = useState('');
    const isSmallScreen = useBreakpointValue({ base: true, sm: false, md: false });

    useEffect(() => {
        // Set a random blessing on initial render
        let randomIndex = Math.floor(Math.random() * blessings.length);
        setCurrentBlessing(blessings[randomIndex]);
    }, []);

    const navigate = useNavigate();
    const goToPrayerRequest = () => {

        navigate("/");
    }

    return (
        <VStack cursor="pointer" spacing={4}>
            <Text fontSize="16px" as="i" px="3rem" textAlign="center" pt={isSmallScreen ? null : "6rem"}>
                {currentBlessing}
            </Text>

            <VStack pt="3rem">

                <AngelAnimation />
            </VStack>

            <VStack pt="3rem">

                <Button
                    variant='outline'
                    color="#64a4d3"
                    colorScheme='#64a4d3'
                    size={isSmallScreen ? "sm" : "sm"}
                    alignItems="center"
                    cursor="pointer"
                    _hover={{ bg: "gray.100" }} // Change background color on hover
                    borderRadius="md" // Apply border radius to mimic button style
                    p="2" // Add padding for better click area
                    onClick={goToPrayerRequest}>Submit a prayer request


                </Button>
            </VStack>

        </VStack>
    );
}

export default BlessingPage;
