import React, { useState, useEffect, useRef } from 'react';
import { Box, Link, Flex, Image, HStack, Tooltip, Button, Text, Spacer, useBreakpointValue } from '@chakra-ui/react'


import { getAuth } from 'firebase/auth';


import { GoHome, GoPeople } from "react-icons/go";
import { GoHomeFill } from "react-icons/go";

import { MdChurch } from "react-icons/md";

import { MdOutlineChurch } from "react-icons/md";

import { IoSettingsOutline } from "react-icons/io5";

import { IoSettingsSharp } from "react-icons/io5";



import { IoMdNotificationsOutline, IoMdNotifications } from "react-icons/io";



import { BiMessageSquareAdd, BiSolidMessageSquareAdd } from "react-icons/bi";


import { IoPersonOutline } from "react-icons/io5";


import { useNavigate, useLocation } from 'react-router-dom';
import useUserUsername from '../pages/ProfilePage/ProfileUsername';

import { getUserInfo } from '../pages/ProfilePage/Profile';

import { currentUserId } from '../Utilities/firebase';

import { useData } from '../App';

const BottomNavigationBar = () => {
    const auth = getAuth();

    const user = auth.currentUser;


    const navigate = useNavigate();

    const { username } = useUserUsername();

    const [userProfilePhoto, setUserProfilePhoto] = useState(null);


    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const userInfo = await getUserInfo(currentUserId);
                if (userInfo) {
                    setUserProfilePhoto(userInfo.userProfilePhoto);
                }
         
            } catch (error) {
            
            }
        };

        fetchUserInfo();
    }, [currentUserId]);

 


    const currentYear = new Date().getFullYear();

    const isSmallScreen = useBreakpointValue({ base: true, lg: false });



    const { pathname } = useLocation()
    const {

        isHomeSelected, setHomeSelected,
        isNotificationsSelected, setNotificationsSelected,
        isAddNewPostSelected, setAddNewPostSelected,
        isProfileSelected, setProfileSelected,

        isSettingsSelected, setSettingsSelected,


        isUserOnProfilePages, setIsUserOnProfilePages,
       
        setShowBeforeLoginSignupPopup,

      

     

    } = useData();




    const goToPrayerRequests = () => {
        // Navigate to Profile
        navigate('/requests');


        setHomeSelected(true);
        setNotificationsSelected(false);
        setAddNewPostSelected(false);
        setProfileSelected(false);

        setSettingsSelected(false);

    };


    const goToNotifications = () => {

        if (user) {

            setHomeSelected(false);
            setNotificationsSelected(prevState => !prevState);
            setAddNewPostSelected(false);
            setProfileSelected(false);

            setSettingsSelected(false);

        } else {
            // User is not signed in, navigate to home page
            // navigate("/");
            presentLoginPopup();
        }

    };

   

    const goToSendPrayerRequest = () => {
        if (user) {
            setHomeSelected(false);
            setNotificationsSelected(false);
            setAddNewPostSelected(false);
            setProfileSelected(false);
    
            setSettingsSelected(false);
        navigate("/");



        } else {
            // User is not signed in, navigate to home page
            // navigate('/login');

            presentLoginPopup();
        }

    };

    const goToProfile = () => {




        if (user) {
            // User is signed in, navigate to profile page

            navigate(`/${username}`);



            setHomeSelected(true);
            setNotificationsSelected(false);
            setAddNewPostSelected(false);
            setProfileSelected(true);
    
            setSettingsSelected(false);

        } else {
            // User is not signed in, navigate to home page
            // navigate("/login");
            presentLoginPopup();

        }




    };


    const goToSettings = () => {

        setHomeSelected(true);
        setNotificationsSelected(false);
        setAddNewPostSelected(false);
        setProfileSelected(false);

        setSettingsSelected(true);


    };




    const presentLoginPopup = () => {

        if (!user) {
            
            setShowBeforeLoginSignupPopup(true);

        }
    };




    const includedPaths = [
        '/listen',
        '/requests',
     

    ];

    const isMainPathIncluded = includedPaths.some(path => pathname.includes(path));



  return (
    <Box
    position="fixed"
    bottom="0"
    left="0"
    width="100%"
    bg="rgba(0, 0, 0, 0.88)"
    color="white"
    borderTop="1px solid #e4e4e4"
    transition="transform 0.3s ease"
    zIndex="1"
    pt="1rem"
    pb="1rem"
    display="flex" // Add flex display
    justifyContent="center" // Center horizontally
>

            <HStack
                pl={['1rem', '6rem', '9rem']} // Responsive padding left
                pr={['1rem', '6rem', '1rem']} // Responsive padding right
            >
                <Flex flexDirection="row" alignItems="center" gap={20}>
      

        <Tooltip
            hasArrow
            label={"Prayer Requests"}
    
        
            openDelay={500}
         
        >
            <Flex
                gap={4}
                borderRadius={6}
                p={2}
                w={{ base: 10, md: "full" }}
                justifyContent="flex-start" // Align items to the left
                onClick={goToPrayerRequests}
                _hover={{
                    bg: 'whiteAlpha.400',
                    transform: "scale(1.033)",
                }}  style={{
                    transition: "transform 0.1s", 
                }}>

                <Flex alignItems="center"> {/* Wrap icon and text in a Flex container */}
                {isHomeSelected ? <MdChurch style={{ fontSize: "24px", fontWeight: "bold" }} /> : <MdOutlineChurch style={{ fontSize: "24px" }} />}

                </Flex>
            </Flex>
        </Tooltip>






        <Tooltip
            hasArrow
            label={"Add New Prayer Request"}
         
    
            openDelay={500}
         
        >
            <Flex
                gap={4}
                borderRadius={6}
                p={2}
                w={{ base: 10, md: "full" }}
                justifyContent="flex-start" // Align items to the left
                onClick={goToSendPrayerRequest}
                _hover={{
                    bg: 'whiteAlpha.400',
                    transform: "scale(1.033)",
                }}  style={{
                    transition: "transform 0.1s", 
                }}>
            
                <Flex alignItems="center"> {/* Wrap icon and text in a Flex container */}
                {isAddNewPostSelected ? <BiSolidMessageSquareAdd style={{ fontSize: "24px", fontWeight: "bold" }} /> : <BiMessageSquareAdd style={{ fontSize: "24px" }} />}
                  
                </Flex>
            </Flex>
        </Tooltip>

   


        <Tooltip
            hasArrow
            label={"Profile"}
       
     
            openDelay={500}
        
        >

            <Flex
                gap={4}
                borderRadius={6}
                p={2}
                w={{ base: 10, md: "full" }}
                justifyContent="flex-start" // Align items to the left
                onClick={goToProfile}
                _hover={{
                    bg: 'whiteAlpha.400',
                    transform: "scale(1.033)",
                }}  style={{
                    transition: "transform 0.1s", 
                }}>
            
                <Flex alignItems="center"> {/* Wrap icon and text in a Flex container */}



                    {user ? (
                        <Image
                        borderRadius="full"
                        boxSize="26px"
                        src={userProfilePhoto}
                        alt="profile pic"
                        border={isProfileSelected ? "1.5px solid black" : "none"} // Apply border if isProfileSelected is true
                        _hover={{
                            bg: 'whiteAlpha.400',
                            transform: "scale(1.033)",
                        }}  style={{
                            transition: "transform 0.1s", 
                        }}
                      />

                    ) : (
                        <>
                            <IoPersonOutline style={{ fontSize: "24px", fontWeight: "bold" }} />
                        </>
                    )}

                   
                </Flex>
            </Flex>
        </Tooltip>


        </Flex>
   
        </HStack>

</Box>
  )
}

export default BottomNavigationBar

