import React, { useEffect, useState } from 'react';
import { VStack, HStack, Flex, Box, Text, Image, Spacer, useBreakpointValue, CircularProgress, } from '@chakra-ui/react'


import { FaCirclePlay } from "react-icons/fa6";

import { BsThreeDots } from "react-icons/bs";
import { PiHandsPrayingFill } from "react-icons/pi";

import AngelAnimation from '../PostCreation/AngelAnimation'
import { Button } from '@chakra-ui/react'
import { useData } from '../App'
import { usePageTitle } from '../Utilities/PageTitles'


import usePrayerRequests from './PrayerRequestsUpload';

import { useNavigate } from 'react-router-dom';

import { getAuth } from 'firebase/auth';


import { checkIfUserExistAsPrayerSendUser } from '../pages/AuthPages/Login';





const PrayerRequests = () => {

  usePageTitle('Prayer Requests');

  const { setShowShareToPrayerSend } = useData();

  const [hoveredId, setHoveredId] = useState(null);


  const [showPrayerRequests, setShowPrayerRequests] = useState(true);

  

  const openInvitation = () => {
    setShowShareToPrayerSend(true);
  }

  // ------



  const navigate = useNavigate();
  const prayerRequests = usePrayerRequests();
  const { currentURL, getExtractedURL, FreeStoriesToListenTo, handleClick, setShowBeforeLoginSignupPopup, isNotificationsSelected } = useData();
  // storyPostIdSelected, setStoryPostIdSelected,   isFetchingStories, noStoriesFound} = useData();
  const isSmallScreen = useBreakpointValue({ base: true, md: false });



  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    if (user) {
    checkIfUserExistAsPrayerSendUser();
    }
}, [user]); 


  const generateAndNavigateToPost = (prayerRq) => {


    if (!user) {
      handleClick();
      // console.log('Current number of free stories: ' , FreeStoriesToListenTo);
      if (FreeStoriesToListenTo > 2) {
        setShowBeforeLoginSignupPopup(true);
        return
      }
    }




    navigate(`/listen=${prayerRq.url}`, {
      state: {
        name: prayerRq.name,
        username: prayerRq.username,
        userProfilePhoto: prayerRq.profilePhoto,
        postId: prayerRq.postId,
        postAudioDuration: prayerRq.postAudioDuration,
        postAudio: prayerRq.postAudio,
        postAccess: prayerRq.postAccess,
        postCaption: prayerRq.postCaption,
        prayers: prayerRq.prayers,
        postTimes_Listened: prayerRq.times_listened,
        postDate: prayerRq.postDate,
        uid: prayerRq.uid,
        url: prayerRq.url

      }
    });

    getExtractedURL(prayerRq.url);


 


  };



  useEffect(() => {
    document.body.style.overflow = isNotificationsSelected ? 'hidden' : 'auto';
  
    return () => {
      document.body.style.overflow = 'auto'; // Reset overflow when the component unmounts
    };
  }, [isNotificationsSelected]);


  const openEvents = () => {


      
       navigate("/give");
    
  }

  return (

    <VStack>

<VStack pb="5px">
          <Text fontSize="10px">Have you been blessed by PrayerSend and its ministries? 🙏</Text>
          <Text as="b" color="blue.300" fontSize="10px"    cursor="pointer"  _hover={{
                          transform: "scale(1.022)",
                        }} onClick={openEvents}>Click here to receive even more blessings.</Text>

          </VStack>

      {showPrayerRequests ? (
        <>



          <VStack>

            {prayerRequests.map((prayerRq) => (


              <Flex key={prayerRq.postId} onClick={() => generateAndNavigateToPost(prayerRq)} pl="0" alignItems="flex-start" justifyContent="center"
              onMouseEnter={() => setHoveredId(prayerRq.postId)}
              onMouseLeave={() => setHoveredId(null)}
                _hover={{
                  bg: 'rgba(180, 180, 180, 0.1)', // Light gray with 10% opacity on hover
                  transform: "scale(1.022)",
                }}
                style={{
                  transition: "transform 0.1s",
                }}>
                {/* 1st prayerRq POSTS GO HERE  */}
                <Flex alignItems="flex-start" justifyContent="center" style={{

                  minWidth: { base: '50px', sm: '200px', md: '300px' },

                }} > {/* Align items to the top */}
                  <Box mr="4"> {/* Margin right to create space between image and text */}

                  </Box>



                  <Box width="100%">

                    <Box width={{ base: "250px", sm: "350px", md: "400px" }} // Adjust width based on screen size
                      minWidth="100px">

                      <Flex direction="row" pt="0" maxWidth="300px"> {/* I like both 400 and 500 for the fontWeight*/}
                        <Text fontSize={{ base: '14px', sm: '16px', md: '18px' }} fontWeight= {isSmallScreen ? 500 : 400 } as="i">"{prayerRq.postCaption}.."</Text> {/* SubJect of prayerRq */}
                 

                      </Flex>



                      <Flex direction="row" pt="5px">
                    

<Flex direction="column" mr="12px" gap="2px">

                    <FaCirclePlay size={isSmallScreen ? 18 : 24} /> {/* Adjust icon size here */}
                      <Text fontSize="12" >{prayerRq.postAudioDuration}</Text>

                      </Flex>

                        <Image
                          borderRadius="full"
                          boxSize="30px"
                          src={prayerRq.profilePhoto}
                          alt="profile pic"

                        />
                        <Flex flexDirection="column" ml={2}>
                          <Text fontSize="12">{prayerRq.name}</Text>
                          <Text fontSize="12">{prayerRq.username}</Text>
                        </Flex>

                        <Spacer />

                     


                      </Flex>



                      <Flex pt="2" alignItems="flex-start"> {/* Align items to the top */}


                      <Text fontSize="12" color="gray.500">{prayerRq.times_listened}</Text>
                        <Text pl="1" fontSize="12" color="gray.500">👂</Text>
                        <Text pl="2" fontSize="12" color="gray.500">·</Text>

                        <Text pl="2" fontSize="12" color="gray.500">{prayerRq.postDate}</Text>


                        <Text pl="2" fontSize="12" color="gray.500">·</Text>

                        <Text pl="2" pr="1" fontSize="12" color="gray.500">{prayerRq.prayers}</Text>
                        <PiHandsPrayingFill />
                        <Text pl="1" fontSize="10px">prayed for {prayerRq.name}'s prayer request</Text>

<Spacer />

<Box mr="5px">

{hoveredId === prayerRq.postId && (
                     
                        <BsThreeDots /> )}
                    
                        </Box>
                      </Flex>



                      <Flex pt="2">


                        <Box
                          width={{ base: '66%', md: '100%' }} // Adjust width based on screen size
                          maxWidth="400px" // Maximum width
                          minWidth="auto" // Minimum width set to auto
                          height="0.5px" // Set the height explicitly
                          backgroundColor="gray.300" // Set the background color
                          marginBottom="4px" // Set the bottom margin
                          mx="auto" // Center horizontally
                        /> {/* Gray line */}
                      </Flex>


                    </Box>

                  </Box>

                  {/* PLAY BOX OF prayerRq  */}

                  <Flex pt="3" flexDirection="column" alignItems="flex-end" justifyContent="center" ml="auto"> {/* Align to right */}



                    <Box justifyContent="center" alignItems="center">
               
                 
                
             
                      

                 
                
                    </Box>
                  </Flex>


                </Flex>
              </Flex>


            ))}
          </VStack>







        </>

      ) : (

        <>

          <VStack mr="3rem" ml="3rem" gap={5}>

            <Text mt="4rem" fontSize={14} color="gray" textAlign="center">Prayer requests are being lifted up before God at this hour</Text>

            <AngelAnimation />

            <VStack mt="2rem">
              <Text fontSize={12} textAlign="center">
                All prayer requests sent on PrayerSend will be visible by Sunday, June 9, 2024, at 10:00 AM.
              </Text>

              <Text as="b">Invite a friend to PrayerSend</Text>

              <Button mt="2rem" onClick={openInvitation} color="#64a4d3" colorScheme="black" variant="outline" size="sm" mr={2}
                _hover={{
                  color: "white",
                  bg: '#FFCC00',
                  transform: "scale(1.022)",
                }} style={{
                  transition: "transform 0.5s",
                }}> Invite a friend
              </Button>

            </VStack>

          </VStack>



        </>

      )}

    </VStack>

  )
}

export default PrayerRequests