  import { Center, VStack, Flex, Text, Image, Box, HStack, useBreakpointValue, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react'
  import React, { useState, useEffect, useRef } from 'react';
  import { currentUserId } from '../../Utilities/firebase';
  import ShareOptions from '../../ShareOptions';
  import SlideUpPanel from '../../Footers/SlideUpPanel';
  import { useLocation } from 'react-router-dom';
  import { BsSoundwave } from "react-icons/bs";

  import { HiDotsHorizontal } from "react-icons/hi";

  import { getNumberOfUserPrayers, getNumberOfUserPrayerRequests } from '../ProfilePage/ProfileMetrics';
import UserProfilePrayerList from './UserProfilePrayerList';
  
  import { PiShareFat } from "react-icons/pi";
  // <PiShareFat />
  import { MdOutlineReportGmailerrorred } from "react-icons/md";
// <MdOutlineReportGmailerrorred />
import { MdBlock } from "react-icons/md";
// <MdBlock />
import { CgUnblock } from "react-icons/cg";
  import { IoSettingsOutline } from "react-icons/io5";
  // <IoSettingsOutline />
  
  import { MdOutlineReviews } from "react-icons/md";
  // MdOutlineReviews
  
  import { MdReviews } from "react-icons/md";
  // <MdReviews />
  
  
  import { LiaHandshakeSolid } from "react-icons/lia";
  // <LiaHandshakeSolid />
  
  
  //import useUserProfile from './Profile'
  // import useUserUsername from './ProfileUsername'
  
  import { PiHandsPrayingFill } from 'react-icons/pi';
  
  import { FaHands } from "react-icons/fa";
  
import UserPrayerRequestList from './UserPrayerRequestList';
  
  
  import { useData } from '../../App';

import { UserProfile } from './UserProfile';  


import { getAuth } from 'firebase/auth';

  const UserProfilePage = ( {profileURL}) => {

    const auth = getAuth();
    const user = auth.currentUser;
  

      // console.log("I'm ON THE PROFILE PAGE");
  
      const isBaseScreen = useBreakpointValue({ base: true, sm: false, md: false });
  

      const { bio, name, mUserUsername, profilePhoto, mUser } = UserProfile();

  
  
  
      document.title = `${name + " (@" + mUserUsername + ") · PrayerSend" }`;
  
  
  
      const [isUserBlocked, setUserBlocked] = useState(false);

      const [isBoxVisible, setIsBoxVisible] = useState(false);

  
      const {
          setSettingsSelected,
        //  showProfileShareOptions, setShowProfileShareOptions,

        setOpenReportFromProfile,
        setItemSelectedToReport,
        openBlockPopup, setOpenBlockPopup,
        personSelectedToBlock, setPersonSelectedToBlock,
        setShowLoginPopup,

          PrayerSendDomainName  } = useData();
  
          const [showProfileShareOptions, setShowProfileShareOptions] = useState(false);
  
  
     // console.log('Followers:', followers);
    //  console.log('Following:', following);
    //  console.log('Bio:', bio);
    //  console.log('Name:', name);
    //  console.log('Profile Photo:', profilePhoto);
    //  console.log('Stories Count:', storiesCount);
     // console.log('Username:', username);
  
  
      const isSmallScreen = useBreakpointValue({ base: true, md: false });
      const [scrollPosition, setScrollPosition] = useState(0);
  
  
      // SHARING POST ON SOCIAL MEDIA 
      const shareLink = (platform) => {
          // Logic to handle sharing based on the platform clicked
         // console.log("Sharing on", platform);
          // Add your sharing logic here
      };
  
  
  
      const handleShareButtonClick = () => {
  
          setShowProfileShareOptions(true);
  
          if (isSmallScreen) {
              // Scroll to the top of the page
              window.scrollTo({ top: 0, behavior: "smooth" });
          }
  
      };
  
      const handleCloseModal = () => {
          setShowProfileShareOptions(false);
  
      };
  
  
  
      // SCROLL HANDLING
  
      const handleScroll = () => {
          setScrollPosition(window.scrollY);
  
      };
  
      useEffect(() => {
          window.addEventListener('scroll', handleScroll);
          return () => {
              window.removeEventListener('scroll', handleScroll);
          };
      }, [scrollPosition]); // Add scrollPosition as a dependency to useEffect
  
      const [showStoriesTab, setShowStoriesTab] = useState(true);
      const [showSocialReviewsTab, setShowSocialReviewsTab] = useState(false);
      // only they can see the videos they liked
      const [showLikedVideosTab, setShowLikedVideosTab] = useState(false);
  
  
      const handleStoriesTap = () => {
          setShowStoriesTab(true);
          setShowSocialReviewsTab(false);
        
      };
  
      const handleSocialReviewsTap = () => {
          setShowStoriesTab(false);
          setShowSocialReviewsTab(true);
      
  
      };
  
      const handleConnectionsTap = () => {
          setShowStoriesTab(false);
          setShowSocialReviewsTab(false);
       
  
      };
  
  
  

      const [numberOfPrayers, setNumberOfPrayers] = useState(0);
  
      useEffect(() => {
        const fetchNumberOfPrayers = async () => {
          const prayersCount = await getNumberOfUserPrayers(mUser);
          setNumberOfPrayers(prayersCount);
        };
    
        fetchNumberOfPrayers();
      }, [mUser]);
   
   
  
      const [numberOfPrayerRequests, setNumberOfPrayerRequests] = useState(0);
  
      useEffect(() => {
        const fetchNumberOfPrayerRequests = async () => {
          const prayerRqCount = await getNumberOfUserPrayerRequests(mUser);
          setNumberOfPrayerRequests(prayerRqCount);
        };
    
        fetchNumberOfPrayerRequests();
      }, [mUser]);

  

    const openReport = () => {
      if (user) {
          setOpenReportFromProfile(true)
          // send the UID OVER
          setItemSelectedToReport(mUser);

      } else {
     
          setShowLoginPopup(true);
      }

  }

  const openBlockUserPopup = () => {
      setPersonSelectedToBlock({ uid: mUser, name: name, username: profileURL, profilePhoto: profilePhoto });

      setOpenBlockPopup(true)


  }

  const initializedUnblockUser = () => {

      setUserBlocked(false)

      unblockUser(mUser);



  }

  // temperaroy 
  const checkBlockUser  = async (mm) => {
return false;
  }

  useEffect(() => {
      const fetchData = async () => {
          if (currentUserId !== mUser) {
              const result = await checkBlockUser(mUser);
              setUserBlocked(result);
          }
      };

      fetchData();
  }, [currentUserId, mUser]); // Include dependencies for useEffect




      return (
          <VStack alignItems="center" mt={(isBaseScreen ? "1rem" : null)}>
  
  
  
  
              <VStack alignItems="flex-start">
                  {/* Profile */}
                  <Flex pt="10" pl="10" alignItems="flex-start" justifyContent="space-between"> {/* Align items to the top */}
  
                      <Box mr="4"> {/* Margin right to create space between image and text */}
                          <Image
                              src={profilePhoto}
                              alt="Profile"
                              borderRadius="10px"
                              boxShadow="base"
                              className="profile-image"
                              minHeight={{ base: "90px", sm: '120px', md: '180px' }} // Adjust height based on screen size
                              style={{
                                  height: 'auto', // Allow the height to adjust automatically based on aspect ratio
                              }}
  
                          />
                      </Box>
  
                      <Box>
  
  
                          <Text fontSize={isSmallScreen ? "20" : "30"} fontWeight="bold" mb="1">{name}</Text> {/* Add margin bottom to create space between name and username */}
                          <Text fontSize={isSmallScreen ? "14" : "18"} fontWeight="bold">{mUserUsername}</Text>
  
  
  
                         

                          <Box pt="2">
                                    <HStack spacing={5}>
                                        {/* FOLLOW BUTTON - SHARE BUTTON - MORE ("REPORT") BUTTON */}
                                        {isSmallScreen && (
                                            <>

                                              


                                                <Flex
                                                    alignItems="center"
                                                    onClick={handleShareButtonClick}
                                                    _hover={{
                                                        position: 'relative',
                                                        boxShadow: '0 0 20px 10px rgba(173, 216, 230, 0.8)',
                                                        transform: 'scale(1.2)',
                                                        borderRadius: '50%',
                                                        bg: 'rgba(173, 216, 230, 0.8)'
                                                    }}
                                                >
                                                    <PiShareFat />
                                                </Flex>





                                                <Flex
                                                    pl="3"
                                                    position="relative"
                                                    onMouseEnter={() => {
                                                        if (!isSmallScreen) {
                                                            setIsBoxVisible(true);
                                                        }
                                                    }} // Set visibility to true when hovered, but only if not on small screen
                                                    onMouseLeave={() => {
                                                        if (!isSmallScreen) {
                                                            setIsBoxVisible(false);
                                                        }
                                                    }} // Set visibility to false when not hovered, but only if not on small screen
                                                    onClick={() => {
                                                        if (isSmallScreen) {
                                                            setIsBoxVisible(true);
                                                        }
                                                    }} // Toggle visibility when clicked, but only if on small screen
                                                >

                                                    <Flex alignItems="center" mb={isBoxVisible ? "10px" : 0}>
                                                        <HiDotsHorizontal size={14} />
                                                    </Flex>

                                                    {/* Panel to appear on hover for large screens and on click for small screens */}
                                                    {(isBoxVisible) && (
                                                        <Box
                                                            position="absolute"
                                                            top="100%"
                                                            left="50%"
                                                            transform="translateX(-50%)"
                                                            bg="white"
                                                            borderRadius="5%" // Rounded corners with 5% radius
                                                            boxShadow="dark-lg" // Dark shadow effect
                                                            p="2"
                                                            opacity={isBoxVisible ? 1 : 0} // Set opacity based on isBoxVisible state
                                                            pointerEvents={isBoxVisible ? "auto" : "none"} // Enable pointer events only when box is visible
                                                            transition="opacity 0.2s"
                                                            onMouseEnter={() => setIsBoxVisible(true)} // Set visibility to true when pointer enters box
                                                            onMouseLeave={() => setIsBoxVisible(false)} // Set visibility to false when pointer leaves box
                                                        >
                                                            {/* Content inside the box */}
                                                            <HStack spacing={2} className="share-option" pr={3} pl={3}>
                                                                <MdOutlineReportGmailerrorred mr={2} />
                                                                <Text as='b' fontSize='xs' colorScheme="black" mb="1"
                                                                    onClick={openReport}>
                                                                    Report
                                                                </Text>
                                                            </HStack>
                                                            {/* Separator */}
                                                            {user && (  <Box justifyContent="center" width="90%" h="0.5px" bg="gray.300" mb="1" margin="auto" /> )}
                                                            {/* Block */}

                                                            {isUserBlocked ? (
                                                                <HStack spacing={2} className="share-option" pr={3} pl={3}>
                                                                    <CgUnblock mr={2} /> {/* Add margin to the right */}
                                                                    <Text as='b' fontSize='xs' colorScheme="black"
                                                                        onClick={initializedUnblockUser}>
                                                                        Unblock
                                                                    </Text>
                                                                </HStack>
                                                            ) : (
                                                                <>
                                                                {user && (
                                                                <HStack spacing={2} className="share-option" pr={3} pl={3}>
                                                                    <MdBlock mr={2} /> {/* Add margin to the right */}
                                                                    <Text as='b' fontSize='xs' colorScheme="black"
                                                                        onClick={openBlockUserPopup}>
                                                                        Block
                                                                    </Text>
                                                                </HStack>
                                                                )}
                                                                </>

                                                            )}


                                                        </Box>
                                                    )}
                                                </Flex>
                                            </>

                                        )}
                                    </HStack>
                                </Box>

  
  
  
  
                          <Box pt="2" display="flex" width={{ base: "100%", md: "300px" }} minWidth="auto" pr="10px">
  
                              {bio && (
  
                                  <Text fontSize={isSmallScreen ? "10" : "14"} >{bio}</Text>
  
                              )}
                          </Box>
  
  
  
  
                      </Box>
  
                      <HStack spacing={5}>
                        {/* FOLLOW BUTTON - SHARE BUTTON - MORE ("REPORT") BUTTON */}
                                {!isSmallScreen && (
                                    <>

                                    



                                        <Flex
                                            alignItems="center"
                                            onClick={handleShareButtonClick}
                                            _hover={{
                                                position: 'relative',
                                                boxShadow: '0 0 20px 10px rgba(173, 216, 230, 0.8)',
                                                transform: 'scale(1.2)',
                                                borderRadius: '50%',
                                                bg: 'rgba(173, 216, 230, 0.8)'
                                            }}
                                        >
                                            <PiShareFat />
                                        </Flex>


                                        <Flex
                                            pl="3"
                                            position="relative"
                                            onMouseEnter={() => {
                                                if (!isSmallScreen) {
                                                    setIsBoxVisible(true);
                                                }
                                            }} // Set visibility to true when hovered, but only if not on small screen
                                            onMouseLeave={() => {
                                                if (!isSmallScreen) {
                                                    setIsBoxVisible(false);
                                                }
                                            }} // Set visibility to false when not hovered, but only if not on small screen
                                            onClick={() => {
                                                if (isSmallScreen) {
                                                    setIsBoxVisible(true);
                                                }
                                            }} // Toggle visibility when clicked, but only if on small screen
                                        >


                                            <Flex alignItems="center" mb={isBoxVisible ? "10px" : 0} >
                                                <HiDotsHorizontal size={14} />
                                            </Flex>


                                            {/* Panel to appear on hover for large screens and on click for small screens */}
                                            {(isBoxVisible) && (
                                                <Box
                                                    position="absolute"
                                                    top="100%"
                                                    left="50%"
                                                    transform="translateX(-50%)"
                                                    bg="white"
                                                    borderRadius="5%" // Rounded corners with 5% radius
                                                    boxShadow="dark-lg" // Dark shadow effect
                                                    p="2"
                                                    opacity={isBoxVisible ? 1 : 0} // Set opacity based on isBoxVisible state
                                                    pointerEvents={isBoxVisible ? "auto" : "none"} // Enable pointer events only when box is visible
                                                    transition="opacity 0.2s"
                                                    onMouseEnter={() => setIsBoxVisible(true)} // Set visibility to true when pointer enters box
                                                    onMouseLeave={() => setIsBoxVisible(false)} // Set visibility to false when pointer leaves box
                                                >

                                                    {/* Content inside the box */}
                                                    <HStack spacing={2} className="share-option" pr={3} pl={3} onClick={openReport} >
                                                        <MdOutlineReportGmailerrorred boxSize={6} mr={2}/>
                                                        <Text as='b' fontSize='xs' colorScheme="black" mb="1"
                                                            onClick={openReport}>
                                                            Report
                                                        </Text>
                                                    </HStack>

                                                    {/* Separator */}
                                                    {user && (<Box justifyContent="center" width="90%" h="0.5px" bg="gray.300" mb="1" margin="auto" />)}

                                                    {/* Block */}

                                                    {isUserBlocked ? (

                                                        <HStack spacing={2} className="share-option" pr={3} pl={3}>
                                                            <CgUnblock boxSize={6} mr={2} /> {/* Add margin to the right */}
                                                            <Text as='b' fontSize='xs' colorScheme="black"
                                                                onClick={initializedUnblockUser}>
                                                                Unblock
                                                            </Text>
                                                        </HStack>
                                                    ) : (
                                                        <>
                                                            {user && (

                                                                <HStack spacing={2} className="share-option" pr={3} pl={3}>
                                                                    <MdBlock boxSize={6} mr={2} /> {/* Add margin to the right */}
                                                                    <Text as='b' fontSize='xs' colorScheme="black"
                                                                        onClick={openBlockUserPopup}>
                                                                        Block
                                                                    </Text>
                                                                </HStack>

                                                            )}
                                                        </>
                                                    )}
                                                </Box>
                                            )}
                                        </Flex>

                                    </>

                                )}
                      </HStack>
                  </Flex>
  
  
  
  
  
              </VStack>
  
              {/* TAB | STORIES | REVIEWS |  */}
  
  
  
              <VStack alignItems="center" >
  
  
                  {/* Prayers, Prayer Requests,  */}
                  <Flex pt="5" direction="row" alignItems="center" >
  
                      {showStoriesTab && (
                          <>
                              <HStack gap={{ base: 10, sm: 20, md: 20 }}>
                                  {/* Stories */}
                                  <Box >
                                      <Flex flexDirection="column" alignItems="center">
                                          <Text fontSize="20" fontWeight="bold">{numberOfPrayers}</Text> {/* Example number */}
                                          <Text fontSize="14">Prayers</Text>
                                      </Flex>
                                  </Box>
  
                                  {/* Reviews */}
                                  <Box>
                                      <Flex flexDirection="column" alignItems="center">
                                          <Text fontSize="20" fontWeight="bold">{numberOfPrayerRequests}</Text> {/* Example number */}
                                          <Text fontSize="14">Prayer Requests</Text>
                                      </Flex>
                                  </Box>
  
                                 
                              </HStack>
                          </>
  
                      )}
  
                      {showSocialReviewsTab && (
                          <>
                              <HStack gap={{ base: 10, sm: 20, md: 20 }}>
                              
                                 
                                  {/* Prayers */}
                                  <Box mr="13px">
                                      <Flex flexDirection="column" alignItems="center">
                                          <Text fontSize="20" fontWeight="bold">{numberOfPrayers}</Text> {/* Example number */}
                                          <Text fontSize="14">Prayers</Text>
                                      </Flex>
                                  </Box>
  
                                  {/* Prayer Requests */}
                                  <Box mr="13px">
                                      <Flex flexDirection="column" alignItems="center">
                                          <Text fontSize="20" fontWeight="bold">{numberOfPrayerRequests}</Text> {/* Example number */}
                                          <Text fontSize="14">Prayer Requests</Text>
                                      </Flex>
                                  </Box>
                              </HStack>
                          </>
  
                      )}
  
  
                    
  
                  </Flex>
  
  
              </VStack>
  
  
              <Box width={{ base: "100%", md: "400px" }} minWidth="auto" h="1px" bg="gray.300" mb="-6" />
  
              <Flex pt="5" direction="row" alignItems="center" gap={{ base: 1, sm: 15, md: 15 }}>
                  {/* Button 1 with small line */}
                  <VStack onClick={handleStoriesTap} position="relative"
                      _hover={{
                          bg: 'rgba(220, 220, 220, 0.1)',
                          transform: "scaleY(1.022)"
                      }} style={{
                          transition: "transform 0.1s",
                      }}>
                      <Box width="70px" h="1px" bg={showStoriesTab ? "black" : "gray"} position="absolute" top="-5px" />
                      <Button colorScheme='white' variant='ghost'>
                          <Flex justifyContent="center" alignItems="center" color={showStoriesTab ? "black" : "gray"} gap={isSmallScreen ? 1 : null}>
                              <PiHandsPrayingFill />
                              <Box display={{ base: "none", md: "block" }} style={{ marginLeft: '8px' }}></Box>
                              <Text>Prayers</Text>
                          </Flex>
                      </Button>
                  </VStack>
  
                  {/* Button 2 with small line */}
                  <VStack onClick={handleSocialReviewsTap} position="relative"
                      _hover={{
                          bg: 'rgba(220, 220, 220, 0.1)',
                          transform: "scaleY(1.022)"
                      }} style={{
                          transition: "transform 0.1s",
                      }}>
  
                      <Box width="70px" h="1px" bg={showSocialReviewsTab ? "black" : "gray"} position="absolute" top="-5px" />
                      <Button colorScheme='white' variant='ghost'>
                          <Flex justifyContent="center" alignItems="center" color={showSocialReviewsTab ? "black" : "gray"} gap={isSmallScreen ? 1 : null}>
                              <FaHands />
                              <Box display={{ base: "none", md: "block" }} style={{ marginLeft: '8px' }}></Box>
                              <Text>Prayer Requests</Text>
                          </Flex>
                      </Button>
                  </VStack>
  
  
  
  
  
              </Flex>
  
  
              {/* LIST OF STORIES HERE  */}
  
              {showStoriesTab && (
              <UserProfilePrayerList numberOfPrayers={numberOfPrayers} selfProfilePhoto={profilePhoto} mUser={mUser} profileOwnerName={name}/>
              
              )}
  
              {showSocialReviewsTab && (
  
               <UserPrayerRequestList numberOfPrayerRequests={numberOfPrayerRequests} mUser={mUser} name={name}/>
               
  
              )}
  
            
  
  
  
  
              {isSmallScreen && user && (
                  <SlideUpPanel isOpen={showProfileShareOptions && scrollPosition <= 100} onClose={handleCloseModal} shareLink={PrayerSendDomainName + mUserUsername} />
  
              )}
  
  
              {/* Modal */}
              {!isSmallScreen || !user && (
                  <Modal isOpen={showProfileShareOptions} onClose={handleCloseModal} size={{ base: "sm", sm:"sm", md: "md" }}>
                      <ModalOverlay />
                      <ModalContent>
                          <ModalHeader textAlign="center">Share to...</ModalHeader>
                          <ModalCloseButton />
                          <ModalBody>
                              <ShareOptions shareLink={PrayerSendDomainName + mUserUsername} onCloseModal={handleCloseModal} />
                          </ModalBody>
                          <ModalFooter>
                              {/* You can add additional footer actions if needed */}
                          </ModalFooter>
                      </ModalContent>
                  </Modal>
  
              )}
  
          </VStack>
  
  
  
      );
  };
  
export default UserProfilePage