
    import React, { useEffect, useState } from 'react'
    import { VStack, Flex, Box, Text } from '@chakra-ui/react'
    
    
import ProfilePrayerAudio from '../ProfilePage/ProfilePrayerAudio'
import useProfilePrayersList from '../ProfilePage/ProfilePrayersListUpload'    
   

    const UserProfilePrayerList = ({numberOfPrayers, selfProfilePhoto, mUser, profileOwnerName}) => {
     
    
        const prayers = useProfilePrayersList(mUser);
    
       
    
    
      return (
       
         
    <>
    
    <Text fontSize="12px">{profileOwnerName} sent {numberOfPrayers} {numberOfPrayers === 1 ? "prayer" : "prayers"}</Text>
    
                   {prayers.map((prayer) => (
    
    <VStack  >
    <Flex key={prayer.id}  justifyContent="center" 
               _hover={{
                bg: 'rgba(220, 220, 220, 0.1)', // Light gray with 10% opacity on hover
                transform: "scale(1.022)",
              }}
              style={{
                transition: "transform 0.1s",
              }}>
    
    
    
    <ProfilePrayerAudio      postAudio={prayer.postAudio}
                      postAudioDuration={prayer.postAudioDuration}
                      postTimes_Listened={prayer.postTimes_Listened}
                      creatorUID={prayer.uid}
                      userProfilePhoto={prayer.profilePhoto}
                      name={prayer.name}
                      username={prayer.username}
                       postDate={prayer.postDate}
                       prayerRequestTitle={prayer.prayerRequestTitle}
                       selfProfilePhoto={selfProfilePhoto}
                       userProfileId={mUser}
                       profileOwnerName={profileOwnerName}
                      />
    
    
    
    
                      </Flex>
    
    
                      <Flex mt="1px" justifyContent="center" width="100%">
                      <Box width={200} h="0.5px" bg="gray.300" mb="2rem" />
                    </Flex>
    
                    </VStack>
    
        ))}
    
    
      </>
      
    
      )
    }
    

export default UserProfilePrayerList