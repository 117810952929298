import React, { useState, useEffect } from 'react';
import { Center, Box, Image, Input, HStack, VStack, Flex, Text, Spacer, Button, useBreakpointValue, CircularProgress } from '@chakra-ui/react'
import ReplayAudio from './ReplayAudio';
import { IoPersonCircleOutline } from "react-icons/io5";
import { IoImageOutline } from 'react-icons/io5';
import { IoArrowBack } from "react-icons/io5";
import { getUserInfo } from '../pages/ProfilePage/Profile';
import { currentUserId } from '../Utilities/firebase';
import LanguageSelector from './LanguageSelectors';
import { validateImage } from '../pages/AuthPages/CreateAccount';
import generateUniqueURL from '../Utilities/urlGenerator';
import { getCurrentDateTime } from '../Utilities/currentDateTimeUtils';
import { useData } from '../App';
import { uploadStoryPost } from './CreatePostDataModel';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

import { GiAngelWings } from "react-icons/gi";

import PostActionSettings from './PostActionSettings';

import { checkIfUserExistAsPrayerSendUser } from '../pages/AuthPages/Login';


const PublisherSettings = ({ onShowStorySelection, onCloseModal }) => {
    const [progressWidth, setProgressWidth] = useState(0);
    const [buttonClicked, setButtonClicked] = useState(false); // 
    const [isLoading, setIsLoading] = useState(false);

    const { pathname } = useLocation();

    const includedWelcomePagePath = [     '/'  ];
    
    
   const WelcomePagePath = includedWelcomePagePath.some(path => pathname.includes(path));

    const auth = getAuth();
    const user = auth.currentUser;
    const uid = user ? user.uid : null;

    useEffect(() => {
        if (user) {
        checkIfUserExistAsPrayerSendUser();
        }
    }, [user]); 

    const [isFlying, setIsFlying] = useState(false);


    const navigate = useNavigate();

    const { createdPostAudio,
        createPostAudioDuration,
        createPostTitle,
        createdPostDataCategory,
        setProfileSelected,
        getExtractedURL,

        wasStoryJustPublished, setStoryJustPublished,
        setShowStoryTypeSelection,
        setShowPublisherSettings,


        postAudioType,
        postAudioBlob,



        setShowBeforeLoginSignupPopup,

        newUserSignedUp, setNewUserSignedUp

    } = useData();


    const includedTalkCirclePaths = [


        '/talkcircles',
        '/talkcircles/session='


    ];


 

    const TalkCirclePaths = includedTalkCirclePaths.some(path => pathname.includes(path));


    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [isLanguageListShown, setLanguageListShown] = useState(false);
    const [postImageError, setPostImageError] = useState('');
    const [unFormattedTime, setUnformattedTime] = useState('');

    useEffect(() => {
        const unformatTime = (formattedTime) => {
            const parts = formattedTime.split(':');
            if (parts.length === 2) {
                const minutes = parseInt(parts[0], 10);
                const seconds = parseInt(parts[1], 10);
                return minutes * 60 + seconds;
            }
            return parseInt(formattedTime, 10);
        };

        if (createPostAudioDuration) {
            const unformattedTime = unformatTime(createPostAudioDuration);
            setUnformattedTime(unformattedTime);
        }
    }, [createPostAudioDuration]);

    const closePublisherSettings = () => {
      
        /*
        onCloseModal();
        setProfileSelected(false);

        goToNewUploadedStory();
*/
    

       
    };



    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
        setLanguageListShown(false);
    };

    const showLanguageList = () => {
        setLanguageListShown(true);
    };

    const isSmallScreen = useBreakpointValue({ base: true, md: false });

    const [postImage, setPostImage] = useState(null);
    const [imageURL, setImageURL] = useState(null);


    const [userInfo, setUserInfo] = useState({
        userName: '',
        userProfilePhoto: '',
        userUsername: '',
        userLanguage: ''
    });


    useEffect(() => {
        if (!user) return;
        const fetchData = async () => {
            try {
                const { userName, userProfilePhoto, userUsername, userLanguage } = await getUserInfo(uid);
                setUserInfo({ userName, userProfilePhoto, userUsername, userLanguage });
            } catch (error) {
                console.error('Error fetching user information:', error.message);
                // Handle error gracefully
            }
        };

        fetchData();
    }, [user]);


    useEffect(() => {
        const fetchData = async () => {
            if (newUserSignedUp && currentUserId) {
                try {
                const { userName, userProfilePhoto, userUsername, userLanguage } = await getUserInfo(uid);
                setUserInfo({ userName, userProfilePhoto, userUsername, userLanguage });

                setNewUserSignedUp(false);
            } catch (error) {
                console.error('Error fetching user information:', error.message);
                // Handle error gracefully
            }
        }
    };

    fetchData();
}, [newUserSignedUp, currentUserId]);



    let name = userInfo.userName;
    let profilePhoto = userInfo.userProfilePhoto;
    let username = userInfo.userUsername;
    let Language = userInfo.userLanguage;



    let postURL;


    //  console.log('Name:', name);
    // console.log('Profile Photo:', profilePhoto);
    //   console.log('Username:', username);
    //  console.log('Language:', Language);

    const goBack = () => {
        onShowStorySelection();

    };

    const removeImage = () => {
        setPostImage(null);
        setPostImageError('')
        setImageURL('');
    };



    const handleInputChange = (e) => {
        const file = e.target.files[0]; // Access the first file from the files array
        setPostImage(file);
        let imageURL = URL.createObjectURL(file);
        setImageURL(imageURL);
    };

    const publishPrayerRequest = async () => {
        // Start the progress bar animation at the beginning of publishPrayerRequest

        console.log("LANGUAGE IS ", Language)
        console.log("caption IS ", createPostTitle)
        


        if (!user)  {
            setShowBeforeLoginSignupPopup(true); 
        } else {
        setIsLoading(true);
        setIsFlying(true);

     


        if (!buttonClicked) {

            setButtonClicked(true);

            setProgressWidth(0);
            setButtonClicked(true);

            const startTime = Date.now();


            if (postImage) {
                if (!validateImage(postImage)) {
                    setPostImageError("Please upload a valid image file (JPG, PNG, JPEG)");
                    return;
                }
            }

            try {
                // Finalize final data additions 
                let languageToSave = selectedLanguage ? selectedLanguage.langName : Language;
                //     console.log("++ 1.WE have an Image ", imageURL);

                //     console.log("++ Language we have here : ", languageToSave);
                //     console.log("++ 3.WE have an Image ", imageURL);
                // PUBLISH THE STORY
                postURL = generateUniqueURL();
                const dateAdded = getCurrentDateTime();

                console.log('3.postURL:', postURL);
                console.log('dateAdded:', dateAdded);
                console.log('createPostAudioDuration:', createPostAudioDuration);
                console.log('createPostTitle:', createPostTitle);
                console.log('createdPostDataCategory:', createdPostDataCategory);
                console.log('imageURL:', imageURL);
                console.log('languageToSave:', languageToSave);
                console.log('postAudioType:', postAudioType);
                console.log('postAudioBlob:', postAudioBlob);
             
                await uploadStoryPost(postURL,
                    dateAdded,
                    createPostAudioDuration,
                    createPostTitle,
                    createdPostDataCategory,
                    imageURL,
                    languageToSave,
                    postAudioType,
                    postAudioBlob);







                //    console.log("NOW WE PUBLISH THE STORY!!!");

                const endTime = Date.now();
                const duration = endTime - startTime;

                // If the function executes too quickly, set a minimum duration to ensure smooth animation
                const minDuration = 3000; // 3 second
                const actualDuration = Math.max(duration, minDuration);

                setProgressWidth(100); // Set progress to 100% after completion
                // Reset progress bar after a delay to indicate completion before resetting
                setTimeout(() => {
                    setProgressWidth(0);
                }, actualDuration);

                closePublisherSettings();

             //  setUserHaveStory(true);

             setTimeout(() => {
                setIsLoading(false);
                setIsFlying(false);

                setStoryJustPublished(true);

            }, actualDuration);



            } catch (error) {
                console.error("Error publishing the story:", error);
            }

        }

    }




    };



    function showImage() {
        const fileInput = document.getElementById("postImageInput");
        fileInput.click();
    }


    const goToNewUploadedStory = () => {

        if (TalkCirclePaths) return;
        getExtractedURL(postURL);
        navigate(`/listen=${postURL}`);

    };



    return (
        <Center h="100vh" flexDirection="column">


{wasStoryJustPublished ? (<>
<PostActionSettings />
</>  ) : (<>




            {isLanguageListShown ? (
                <>

                    <LanguageSelector onSelectLanguage={handleLanguageChange} />

                    <Spacer />

                </>
            ) : (

                <>



                    {/* Background */}
                    <Box
                        position="absolute"
                        bottom={isSmallScreen ? "99.12%" : "99.12%"}
                        left="50%"
                        transform="translate(-50%, -50%)"
                        width="100%"
                        height="3px"
                        background={progressWidth > 0 ? "gray" : "white"}
                    >
                        {/* Progress bar */}
                        <Box
                            position="absolute"
                            top="0"
                            left="0"
                            width={`${progressWidth}%`}
                            height="100%"
                            background="#39FF14"
                            zIndex="1"
                        />
                    </Box>

                    <Box
                        position="absolute"
                        left="20px"
                        top="20px"
                        display="flex"
                        alignItems="center"
                    >
                        <>
                            <IoArrowBack size={isSmallScreen ? "18px" : "24px"} onClick={goBack} />


                        </>



                    </Box>

                    {!isFlying && !isLoading && (
                    <Text pt="10px" textAlign="center" as='b' fontSize={isSmallScreen ? "sm" : "md"}   >Send your prayer request</Text>
                )}

                    <Box
                        position="absolute"
                        right="20px"
                        top="20px"
                        display="flex"
                        alignItems="center"
                        onClick={publishPrayerRequest}

                    >

                        <>
                            <Text
                                as='b'
                                fontSize={isSmallScreen ? "sm" : "md"}
                                style={{
                                    color: "#007AFF",
                                    marginRight: "5px",
                                    display: "inline-block",
                                    transition: "color 0.3s",
                                    cursor: "pointer",

                                }}
                                onMouseEnter={(e) => { e.target.style.color = "#3BD528"; }} // Change color on hover
                                onMouseLeave={(e) => { e.target.style.color = "#007AFF"; }} // Revert color when not hovered
                            >
                                Share
                            </Text>

                        </>

                    </Box>
                    <Flex
                        as="button"
                        variant='outline'
                        size={isSmallScreen ? "xs" : "sm"}
                        alignItems="center"
                        cursor="pointer"
                        // Change background color on hover
                        borderRadius="md" // Apply border radius to mimic button style
                        p="2" // Add padding for better click area
                        onClick={publishPrayerRequest}

                    >
                         <div
        className={isFlying ? "glow-fly glow-effect" : "glow-effect"}
        style={{
            "--translate-x": isFlying ? Math.random() * 100 : 0,
            "--translate-y": isFlying ? Math.random() * 100 : 0,
        }}
    >
                            <GiAngelWings size={isSmallScreen ? "14px" : "18px"} color="white" />
                        </div>

                        {!isFlying && !isLoading && (
                        <Button
                            ml="5"
                            mr="5"
                            color="#64a4d3"
                            colorScheme='#64a4d3'
                            variant="outline"
                            _hover={{
                                bg: "rgba(255, 204, 0, 0.8)", // Use RGBA with reduced opacity
                                color: "white",// Use !important to ensure the color change
                            }}
                        >
                            Send
                        </Button>
                        )}


                        <div
        className={isFlying ? "glow-fly glow-effect" : "glow-effect"}
        style={{
            "--translate-x": isFlying ? Math.random() * 100 : 0,
            "--translate-y": isFlying ? Math.random() * 100 : 0,
        }}
    >
                            <GiAngelWings size={isSmallScreen ? "14px" : "18px"} color="white" />
                        </div>

                    </Flex>



                    <Box pt="2">

                        {postImageError && <Text fontSize={12} color="red.500">{postImageError}</Text>}

                        {postImage && (
                            <label htmlFor="postImageInput" className="profile-image" width={{ base: "100%", md: "300px" }}>
                                <Image
                                    boxSize="220px"
                                    src={URL.createObjectURL(postImage)}
                                    alt="Profile Post image"
                                    className="post-image"
                                />
                            </label>
                        )}

{!isFlying && !isLoading && (
<>
                        {postImage ? (
                            <>
                                <Flex pt="2" alignItems="center" justifyContent="center"> {/* Add justifyContent="center" */}
                                    <>
                                        <Button variant='outline' size={isSmallScreen ? "xs" : "sm"} onClick={removeImage}>
                                            <Flex alignItems="center">
                                                <Text fontSize={isSmallScreen ? "xs" : "sm"} color="gray.500">Remove Image</Text>

                                            </Flex>
                                        </Button>

                                        {/* Add the file input here, hidden */}
                                        <Input
                                            id="postImageInput"
                                            type="file"
                                            style={{ display: "none" }}
                                            name="postImage"
                                            onChange={handleInputChange}
                                        />
                                    </>
                                </Flex>
                            </>
                        ) : (
                            <label htmlFor="postImageInput" className="profile-image">
                                <Flex
                                    as="button"
                                    variant='outline'
                                    size={isSmallScreen ? "xs" : "sm"}
                                    alignItems="center"
                                    cursor="pointer"
                                    _hover={{ bg: "gray.100" }} // Change background color on hover
                                    borderRadius="md" // Apply border radius to mimic button style
                                    p="2" // Add padding for better click area
                                    onClick={showImage}
                                >
                                    <IoImageOutline size={isSmallScreen ? "18px" : "24px"} />
                                    <Text ml="2" fontSize={isSmallScreen ? "xs" : "sm"} color="gray.500">Add Image</Text>
                                    {/* Include the file input here, hidden */}
                                    <Input
                                        id="postImageInput"
                                        type="file"
                                        style={{ display: "none" }}
                                        name="postImage"
                                        onChange={handleInputChange}
                                    />
                                </Flex>
                            </label>
                        )}
</>
                    )}



                    </Box>


                    <Box>



                        <ReplayAudio
                            postAudio={createdPostAudio}
                            postAudioDuration={unFormattedTime} />

                        <VStack>



                            <Text pt="3px" as='b' fontSize={isSmallScreen ? "sm" : "md"} >{(createPostTitle)}</Text>


                            {isLoading && <CircularProgress color='#07BEFF' size="20px" isIndeterminate />} {/* Loading Spinner */}

                            <Flex pt="2" alignItems="center" >
                                {/* Profile picture (small) */}

                                {user && profilePhoto ? (
                                    <Flex flexDirection="row">
                                        <Image
                                            borderRadius="full"
                                            boxSize="30px"
                                            src={profilePhoto}
                                            alt="profile pic"
                                        />

                                        {/* Name and username */}
                                        <Flex flexDirection="column" ml={2}>
                                            <Text fontSize="12">{name}</Text>
                                            <Text fontSize="12">{username}</Text>
                                        </Flex>
                                    </Flex>
                                ) : (

                                    <IoPersonCircleOutline size="24px" color="gray" />


                                )}


                                <Box pl={4}>

                                </Box>



                            </Flex>

                            <Flex pt="5" alignItems="center" >
                                <Text fontSize={isSmallScreen ? "xs" : "sm"} color="gray.500" > Access: </Text>
                                <Text as='b' pl="1.5" fontSize={isSmallScreen ? "xs" : "sm"} color="gray.500" >Everyone</Text>
                            </Flex>
                            <Box width={{ base: "100%", md: "300px" }}
                                minWidth="auto" h="0.5px" bg="gray.300" mb="1" /> {/* Gray line */}
                            <Button variant='outline' onClick={showLanguageList}>
                                <Flex alignItems="center" >

                                    <Text fontSize={isSmallScreen ? "xs" : "sm"} color="gray.500" > Language: </Text>

                                    {selectedLanguage ? (
                                        <Text as="b" pl="1.5" fontSize="sm" color="#50CBF5">
                                            {selectedLanguage.name}
                                        </Text>
                                    ) : (

                                        <Text as='b' pl="1.5" fontSize={isSmallScreen ? "xs" : "sm"} color="#50CBF5" >{Language} </Text>
                                    )}


                                </Flex>
                            </Button>

                        </VStack>




                    </Box>



                    <Spacer />
                </>
            )}

</> )}

        </Center>
    )
}

export default PublisherSettings