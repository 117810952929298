import { auth, signInWithEmailAndPassword } from "../../Utilities/firebase";
import { getAuth } from "firebase/auth";
import { userInformationRef, userInformationPrayerSendRef, set, get, child } from "../../Utilities/firebase";
import { currentUserId } from "../../Utilities/firebase";

export const validateEmail = (email) => {
  // Regular expression for email validation
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
};

export const validatePassword = (password) => {
  return password.length >= 8;
};


export const authenticateUser = async (email, password) => {
  try {

    const loginEmail = email
    const loginPassword = password
    const userCredential = await signInWithEmailAndPassword(auth, loginEmail, loginPassword)
    // Handle successful authentication
    const user = userCredential.user;

    return user;
  } catch (error) {
    // Handle authentication errors
    console.error(error.code, error.message);
    throw error; // Rethrow the error to be caught by the caller
  }
};

export const checkUserExists = async () => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    const uid = user ? user.uid : null;

   // console.log("otest uid =", uid);

    const userSnapshot = await get(child(userInformationRef, uid)); 

    if (userSnapshot.exists()) { 
     // console.log("otest uid exist?", userSnapshot.exists());
      checkIfUserExistAsPrayerSendUser();
      return true;
    } else {
     // console.log("otest uid exist?", userSnapshot.exists());
      return false;
    }

  } catch (error) {
    console.error("Error checking user existence:", error);
    throw error; // Rethrow the error to be caught by the caller
  }
};



export const checkIfUserExistAsPrayerSendUser = async () => {

  try {
    const auth = getAuth();
    const user = auth.currentUser;
    const uid = user ? user.uid : null;

   // console.log("otest uid =", uid);

    const userSnapshot = await get(child(userInformationPrayerSendRef, uid)); 

    if (userSnapshot.exists()) { 
      // do nothing
    } else {
     copyUserData(uid);

    }

  } catch (error) {
    console.error("Error checking user existence:", error);
    throw error; // Rethrow the error to be caught by the caller
  }
};


async function copyUserData(uid) {

  try {
      // Retrieve the data from the first reference
      const snapshot = await get(child(userInformationRef, `${uid}`));
      if (snapshot.exists()) {
          const userData = snapshot.val();
  
          // Write the data to the second reference
          await set(child(userInformationPrayerSendRef, `${uid}`), userData);
          console.log("Data copied successfully.");
      } else {
          console.log("No data available.");
      }
  } catch (error) {
      console.error("Error copying data: ", error);
  }
}


export const checkPrayerSendUserExists = async () => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    const uid = user ? user.uid : null;

   // console.log("otest uid =", uid);

    const userSnapshot = await get(child(userInformationPrayerSendRef, uid)); 

    if (userSnapshot.exists()) { 

      return true;
    } else {

      return false;
    }

  } catch (error) {
    console.error("Error checking user existence:", error);
    throw error; // Rethrow the error to be caught by the caller
  }
};

