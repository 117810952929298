import { Center, VStack, Flex, Text, Image, Box, HStack, useBreakpointValue, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react'
import React, { useState, useEffect, useRef } from 'react';
import { currentUserId } from '../../Utilities/firebase';
import ShareOptions from '../../ShareOptions';
import SlideUpPanel from '../../Footers/SlideUpPanel';
import { useLocation } from 'react-router-dom';
import { BsSoundwave } from "react-icons/bs";
import { getNumberOfUserPrayers, getNumberOfUserPrayerRequests } from './ProfileMetrics';

import ProfilePrayersList from './ProfilePrayersList';

import { PiShareFat } from "react-icons/pi";
// <PiShareFat />

import { IoSettingsOutline } from "react-icons/io5";
// <IoSettingsOutline />

import { MdOutlineReviews } from "react-icons/md";
// MdOutlineReviews

import { MdReviews } from "react-icons/md";
// <MdReviews />


import { LiaHandshakeSolid } from "react-icons/lia";
// <LiaHandshakeSolid />


import useUserProfile from './Profile'
import useUserUsername from './ProfileUsername'

import { PiHandsPrayingFill } from 'react-icons/pi';

import { FaHands } from "react-icons/fa";

import ProfilePrayerRequestList from './ProfilePrayerRequestList';


import { useData } from '../../App';

const ProfilePage = () => {
    // console.log("I'm ON THE PROFILE PAGE");

    const isBaseScreen = useBreakpointValue({ base: true, sm: false, md: false });


    const { bio, name, profilePhoto, } = useUserProfile();

    const { username } = useUserUsername();



    document.title = `${name + " (@" + username + ") · PrayerSend" }`;


    const { socialScore } = "0"

    const { reviewNumber } = "0"

    const { numberOfConnections } = "0"

    const {
        setSettingsSelected,
      //  showProfileShareOptions, setShowProfileShareOptions,
        PrayerSendDomainName  } = useData();

        const [showProfileShareOptions, setShowProfileShareOptions] = useState(false);


   // console.log('Followers:', followers);
  //  console.log('Following:', following);
  //  console.log('Bio:', bio);
  //  console.log('Name:', name);
  //  console.log('Profile Photo:', profilePhoto);
  //  console.log('Stories Count:', storiesCount);
   // console.log('Username:', username);


    const isSmallScreen = useBreakpointValue({ base: true, md: false });

    const [scrollPosition, setScrollPosition] = useState(0);


    // SHARING POST ON SOCIAL MEDIA 
    const shareLink = (platform) => {
        // Logic to handle sharing based on the platform clicked
       // console.log("Sharing on", platform);
        // Add your sharing logic here
    };



    const handleShareButtonClick = () => {

        setShowProfileShareOptions(true);

        if (isSmallScreen) {
            // Scroll to the top of the page
            window.scrollTo({ top: 0, behavior: "smooth" });
        }

    };

    const handleCloseModal = () => {
        setShowProfileShareOptions(false);

    };
  

    const goToEditProfile = () => {
        window.open('https://www.yenzek.com/prayersend/profile', '_blank');
    };
    // SCROLL HANDLING

    const handleScroll = () => {
        setScrollPosition(window.scrollY);

    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrollPosition]); // Add scrollPosition as a dependency to useEffect

    const [showStoriesTab, setShowStoriesTab] = useState(true);
    const [showSocialReviewsTab, setShowSocialReviewsTab] = useState(false);
    // only they can see the videos they liked
    const [showLikedVideosTab, setShowLikedVideosTab] = useState(false);


    const handleStoriesTap = () => {
        setShowStoriesTab(true);
        setShowSocialReviewsTab(false);
      
    };

    const handleSocialReviewsTap = () => {
        setShowStoriesTab(false);
        setShowSocialReviewsTab(true);
    

    };

    const handleConnectionsTap = () => {
        setShowStoriesTab(false);
        setShowSocialReviewsTab(false);
     

    };




    const [numberOfPrayers, setNumberOfPrayers] = useState(0);

    useEffect(() => {
      const fetchNumberOfPrayers = async () => {
        const prayersCount = await getNumberOfUserPrayers(currentUserId);
        setNumberOfPrayers(prayersCount);
      };
  
      fetchNumberOfPrayers();
    }, [currentUserId]);
 
 

    const [numberOfPrayerRequests, setNumberOfPrayerRequests] = useState(0);

    useEffect(() => {
      const fetchNumberOfPrayerRequests = async () => {
        const prayerRqCount = await getNumberOfUserPrayerRequests(currentUserId);
        setNumberOfPrayerRequests(prayerRqCount);
      };
  
      fetchNumberOfPrayerRequests();
    }, [currentUserId]);


    return (
        <VStack alignItems="center" mt={ isBaseScreen ? "1rem" : null}>




            <VStack alignItems="flex-start" pt={isSmallScreen ? "3rem" : null} >
                {/* Profile */}
                <Flex pt="10" pl="10" alignItems="flex-start" justifyContent="space-between"> {/* Align items to the top */}

                    <Box mr="4"> {/* Margin right to create space between image and text */}
                        <Image
                            src={profilePhoto}
                            alt="Profile"
                            borderRadius="10px"
                            boxShadow="base"
                            className="profile-image"
                            minHeight={{ base: "90px", sm: '120px', md: '180px' }} // Adjust height based on screen size
                            style={{
                                height: 'auto', // Allow the height to adjust automatically based on aspect ratio
                            }}

                        />
                    </Box>

                    <Box>


                        <Text fontSize={isSmallScreen ? "20" : "30"} fontWeight="bold" mb="1">{name}</Text> {/* Add margin bottom to create space between name and username */}
                        <Text fontSize={isSmallScreen ? "14" : "18"} fontWeight="bold">{username}</Text>



                        <Box pt="2">
                            {/* FOLLOW BUTTON - SHARE BUTTON - MORE ("REPORT") BUTTON */}
                            <HStack spacing={5}>
                                {isSmallScreen && (
                                    <>

                                        <Button     onClick={() => goToEditProfile()} variant='outline' size='xs'
                                            _hover={{
                                                bg: 'rgba(220, 220, 220, 0.1)',
                                                transform: "scale(1.022)",
                                            }}>
                                            Edit Profile
                                        </Button>

                                        <Flex alignItems="center" onClick={handleShareButtonClick}
                                            _hover={{
                                                position: 'relative',
                                                boxShadow: '0 0 20px 10px rgba(173, 216, 230, 0.8)',
                                                transform: 'scale(1.2)',
                                                borderRadius: '50%',
                                                bg: 'rgba(173, 216, 230, 0.8)'
                                            }}
                                        >
                                            <PiShareFat />
                                        </Flex>




                                        <Flex alignItems="center"
                                            _hover={{
                                                bg: 'rgba(220, 220, 220, 0.1)',
                                                transform: "scale(1.022)",
                                            }}>

                                            <IoSettingsOutline onClick={() => setSettingsSelected(true)} />

                                        </Flex>




                                    </>

                                )}
                            </HStack>
                        </Box>





                        <Box pt="2" display="flex" width={{ base: "100%", md: "300px" }} minWidth="auto" pr="10px">

                            {bio && (

                                <Text fontSize={isSmallScreen ? "10" : "14"} >{bio}</Text>

                            )}
                        </Box>




                    </Box>

                    <HStack spacing={5}>
                        {/* FOLLOW BUTTON - SHARE BUTTON - SETTINGS BUTTON */}
                        {!isSmallScreen && (
                            <>

                                <Button onClick={() => goToEditProfile()} variant='outline' size='xs' _hover={{
                                    bg: 'rgba(220, 220, 220, 0.1)',
                                    transform: "scale(1.022)",
                                }}>
                                    Edit Profile
                                </Button>

                                <Flex alignItems="center" onClick={handleShareButtonClick} _hover={{
                                    position: 'relative',
                                    boxShadow: '0 0 20px 10px rgba(173, 216, 230, 0.8)',
                                    transform: 'scale(1.2)',
                                    borderRadius: '50%',
                                    bg: 'rgba(173, 216, 230, 0.8)'
                                }}
                                >
                                    <PiShareFat />
                                </Flex>

                                <Flex alignItems="center"
                                    _hover={{
                                        bg: 'rgba(220, 220, 220, 0.1)',
                                        transform: "scale(1.022)",
                                    }}>

                                    <IoSettingsOutline onClick={() => setSettingsSelected(true)} />

                                </Flex>
                            </>

                        )}
                    </HStack>
                </Flex>





            </VStack>

            {/* TAB | STORIES | REVIEWS |  */}



            <VStack alignItems="center" >


                {/* Prayers, Prayer Requests,  */}
                <Flex pt="5" direction="row" alignItems="center" >

                    {showStoriesTab && (
                        <>
                            <HStack gap={{ base: 10, sm: 20, md: 20 }}>
                                {/* Stories */}
                                <Box >
                                    <Flex flexDirection="column" alignItems="center">
                                        <Text fontSize="20" fontWeight="bold">{numberOfPrayers}</Text> {/* Example number */}
                                        <Text fontSize="14">Prayers</Text>
                                    </Flex>
                                </Box>

                                {/* Reviews */}
                                <Box>
                                    <Flex flexDirection="column" alignItems="center">
                                        <Text fontSize="20" fontWeight="bold">{numberOfPrayerRequests}</Text> {/* Example number */}
                                        <Text fontSize="14">Prayer Requests</Text>
                                    </Flex>
                                </Box>

                               
                            </HStack>
                        </>

                    )}

                    {showSocialReviewsTab && (
                        <>
                            <HStack gap={{ base: 10, sm: 20, md: 20 }}>
                            
                               
                                {/* Prayers */}
                                <Box mr="13px">
                                    <Flex flexDirection="column" alignItems="center">
                                        <Text fontSize="20" fontWeight="bold">{numberOfPrayers}</Text> {/* Example number */}
                                        <Text fontSize="14">Prayers</Text>
                                    </Flex>
                                </Box>

                                {/* Prayer Requests */}
                                <Box mr="13px">
                                    <Flex flexDirection="column" alignItems="center">
                                        <Text fontSize="20" fontWeight="bold">{numberOfPrayerRequests}</Text> {/* Example number */}
                                        <Text fontSize="14">Prayer Requests</Text>
                                    </Flex>
                                </Box>
                            </HStack>
                        </>

                    )}


                  

                </Flex>


            </VStack>


            <Box width={{ base: "100%", md: "400px" }} minWidth="auto" h="1px" bg="gray.300" mb="-6" />

            <Flex pt="5" direction="row" alignItems="center" gap={{ base: 1, sm: 15, md: 15 }}>
                {/* Button 1 with small line */}
                <VStack onClick={handleStoriesTap} position="relative"
                    _hover={{
                        bg: 'rgba(220, 220, 220, 0.1)',
                        transform: "scaleY(1.022)"
                    }} style={{
                        transition: "transform 0.1s",
                    }}>
                    <Box width="70px" h="1px" bg={showStoriesTab ? "black" : "gray"} position="absolute" top="-5px" />
                    <Button colorScheme='white' variant='ghost'>
                        <Flex justifyContent="center" alignItems="center" color={showStoriesTab ? "black" : "gray"} gap={isSmallScreen ? 1 : null}>
                            <PiHandsPrayingFill />
                            <Box display={{ base: "none", md: "block" }} style={{ marginLeft: '8px' }}></Box>
                            <Text>Prayers</Text>
                        </Flex>
                    </Button>
                </VStack>

                {/* Button 2 with small line */}
                <VStack onClick={handleSocialReviewsTap} position="relative"
                    _hover={{
                        bg: 'rgba(220, 220, 220, 0.1)',
                        transform: "scaleY(1.022)"
                    }} style={{
                        transition: "transform 0.1s",
                    }}>

                    <Box width="70px" h="1px" bg={showSocialReviewsTab ? "black" : "gray"} position="absolute" top="-5px" />
                    <Button colorScheme='white' variant='ghost'>
                        <Flex justifyContent="center" alignItems="center" color={showSocialReviewsTab ? "black" : "gray"} gap={isSmallScreen ? 1 : null}>
                            <FaHands />
                            <Box display={{ base: "none", md: "block" }} style={{ marginLeft: '8px' }}></Box>
                            <Text>Prayer Requests</Text>
                        </Flex>
                    </Button>
                </VStack>





            </Flex>


            {/* LIST OF STORIES HERE  */}

            {showStoriesTab && (
               <ProfilePrayersList numberOfPrayers={numberOfPrayers} selfProfilePhoto={profilePhoto} profileOwnerName={name}/>
            )}

            {showSocialReviewsTab && (

              <ProfilePrayerRequestList numberOfPrayerRequests={numberOfPrayerRequests}/>

            )}

          




            {isSmallScreen && (
                <SlideUpPanel isOpen={showProfileShareOptions && scrollPosition <= 100} onClose={handleCloseModal} shareLink={PrayerSendDomainName + username} />

            )}


            {/* Modal */}
            {!isSmallScreen && (
                <Modal isOpen={showProfileShareOptions} onClose={handleCloseModal} size={{ base: "sm", sm:"sm", md: "md" }}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader textAlign="center">Share to...</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <ShareOptions shareLink={PrayerSendDomainName + username} onCloseModal={handleCloseModal} />
                        </ModalBody>
                        <ModalFooter>
                            {/* You can add additional footer actions if needed */}
                        </ModalFooter>
                    </ModalContent>
                </Modal>

            )}

        </VStack>



    );
};

export default ProfilePage;