/*
import { useState, useEffect } from 'react';
import { StoryPostsRef, userInformationRef, currentUserId, get, set, child } from '../../firebase/firebase';
import { userLanguageName } from '../../components/BrowserLanguage/language'
import { formatDate, goFormatDate } from '../../Utilities/dateUtils';
import { StoriesURLRef } from '../../firebase/firebase';
import { useData } from '../App';
import PostAudio from '../pages/PostPage/PostAudio';
*/



import { v4 as uuidv4 } from 'uuid';
import { currentUserId, set, child, storage, PrayersRef } from '../Utilities/firebase';
import { getUserInfo } from '../pages/ProfilePage/Profile';
import { getDownloadURL, uploadBytesResumable } from "../Utilities/firebase";
import { ref } from 'firebase/storage';
import { useData } from '../App';
import { PrayerRequestsRef, NotificationsRef } from '../Utilities/firebase';
/*
const CreatePostDataModal = () => {

  
}
*/



// audioURL gets sent as a hook and passed to "audioFile" to upload to Firebase Storage 

const uploadAudioToStorage = async (uid, postId, postAudioType, postAudioBlob, postType) => {
  try {
    // Define storage reference
    //console.log("ZTest:  audioFile:, ", audioFile)
   /// console.log("ZTest postAudioType:, ", postAudioType)
   // console.log("ZTest postAudioBlob:, ", postAudioBlob)

    const filename = `${postId}_${Date.now()}_${uuidv4()}`;
    let storageRef = ref(storage, `AudioWeb/${postType}/${uid}/${filename}`);


    const metadata = {
      contentType: postAudioType,
  };


    // Upload file
    const snapshot = await uploadBytesResumable(storageRef, postAudioBlob, metadata);

    // Get download URL
    const downloadURL = await getDownloadURL(snapshot.ref);

   // console.log('Audio uploaded successfully');
    return downloadURL;
  } catch (error) {
    console.error('Error uploading audio:', error);
    throw error;
  }
};




const uploadPostImageToStorage = async (uid, imageFile) => {
  try {
    // Define storage reference
    // Sprobuj to -> let storageRef = ref(storage, `profileImageWeb/${uid}`); 
    let storageRef = ref(storage, `ImageWeb/${uid}`);

    // Upload file
    await uploadBytesResumable(storageRef, imageFile);

    // Get download URL
    const downloadURL = await getDownloadURL(storageRef);

   // console.log('Image uploaded successfully');
    return downloadURL;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};



export const uploadStoryPost = async (
  postURL, 
  dateAdded, 
  createPostAudioDuration, 
  createPostTitle, 
  createdPostDataCategory, createdPostImage, createdPostLanguage,    
   postAudioType,
  postAudioBlob) => {
    try {
 
  

      let postType ="PrayerRequests"

  
// iOS user already has username, becuase they signed up on our website
/// iOS stories not loading as soon as you log in!
/// iOS test sign up with iOS device (see if Profile URLs get added)

console.log("1.UID is", currentUserId)


        const { userCountry, userProfilePhoto } = await getUserInfo(currentUserId);
        const postLocation = userCountry + createdPostLanguage; 
        const postId = dateAdded + currentUserId;


        const audioStorageURLPromise = uploadAudioToStorage(currentUserId, postId, postAudioType, postAudioBlob, postType);

        const postAudioURL = await audioStorageURLPromise;


        let postImageURLPromise;
        if (createdPostImage) {
          postImageURLPromise = uploadPostImageToStorage(currentUserId, createdPostImage);
        } else {
           postImageURLPromise = userProfilePhoto;
        }

        const postImageURL = await postImageURLPromise;

    

        let postData = {
          id: postURL,
          postAccess: "Everyone",
          postAudio: postAudioURL,
          postAudioDuration: createPostAudioDuration,
          postCaption: createPostTitle,
          postCategory: createdPostDataCategory,
          postCountry: userCountry,
          postDate: dateAdded,
          postId: postId,
          postImage: postImageURL,
          postLanguage: createdPostLanguage,
          postLink: "",
          postType: postType,
          postLocation: postLocation,
          prayers: 0,
          times_listened: 0,
          type: "postPublicAuthor",
          uid: currentUserId,
          url: postURL
      };


      const storiesURLRefPromise = set(child(PrayerRequestsRef, `${postURL}`), postData);

      //  const storiesURLRefPromise = set(child(StoriesURLRef, `${postURL}`), postData);

       // const storyPostRefPromise = set(child(StoryPostsRef, `${postId}`), postData);

        
       
      //  await Promise.all([storiesURLRefPromise, storyPostRefPromise]);

      await Promise.all([storiesURLRefPromise]);

       // console.log('post uploaded successfully:', postURL);
        return postURL;
      } catch (error) {
        console.error('Post upload error:', error.code, error.message);
        throw error;
      }
};


export const uploadPrayer = async (
  postUUID, 
  dateAdded, 
  createPostAudioDuration, 
   createdPostLanguage,    
   postAudioType,
  postAudioBlob,
receiverUID,
prayerRqURL,
titleOfUserPrayerRequest) => {
    try {
 


      let postType ="Prayers"

  
// iOS user already has username, becuase they signed up on our website
/// iOS stories not loading as soon as you log in!
/// iOS test sign up with iOS device (see if Profile URLs get added)


        const { userCountry } = await getUserInfo(currentUserId);
        const postLocation = userCountry + createdPostLanguage; 
        const postId = dateAdded + currentUserId + postUUID;


        const audioStorageURLPromise = uploadAudioToStorage(currentUserId, postId, postAudioType, postAudioBlob, postType);

        const postAudioURL = await audioStorageURLPromise;


 
        let postData = {
          id: postUUID,
          postAccess: "Public",
          postAudio: postAudioURL,
          postAudioDuration: createPostAudioDuration,
          postCountry: userCountry,
          postDate: dateAdded,
          postId: postId,
          postLanguage: createdPostLanguage,
          postType: "Prayer",
          postLocation: postLocation,
          times_listened: 0,
          uid: currentUserId,
          url: postUUID,
          sender: currentUserId,
          receiver: receiverUID,
          postURL: prayerRqURL,
          prayerRequestTitle: titleOfUserPrayerRequest
      };

      let postDataNotification = {
        id: postUUID,
        postAudio: postAudioURL,
        postAudioDuration: createPostAudioDuration,
        postCountry: userCountry,
        postDate: dateAdded,
        postId: postId,
        postLanguage: createdPostLanguage,
        postType: "Prayer",
        postLocation: postLocation,
        uid: receiverUID,
        url: postUUID,
        sender: currentUserId,
        receiver: receiverUID,
        postURL: prayerRqURL,
        prayerRequestTitle: titleOfUserPrayerRequest,
        status: "unread"
    };


    //  set(child(TalkCircleStoriesHeardList, `${currentUserId}/${mUserStoryPostId}`), {

      const UsersPrayersRefPromise = set(child(PrayersRef,  `Users/${currentUserId}/${postUUID}`), postData);

      const PostsPrayersRefPromise = set(child(PrayersRef,  `Posts/${prayerRqURL}/${postUUID}`), postData);

      const PrayersRefPromise = set(child(PrayersRef,  `Prayers/${postUUID}`), postData);

      const ReceiverNotificationsRefPromise = set(child(NotificationsRef,  `${receiverUID}/${postUUID}`), postDataNotification);

      
    
       
      await Promise.all([UsersPrayersRefPromise, PostsPrayersRefPromise, PrayersRefPromise, ReceiverNotificationsRefPromise]);

   

      } catch (error) {
        console.error('Post upload error:', error.code, error.message);
        throw error;
      }
};



const forbiddenWords = ['fuck', 'rape', 'slut',  'shit', 'phat', 'dirty','boobs', 'porn', 'breasts', 'cock', 'suck', 'whore', 'cum', 'cunt', 'dick', 'pussy', 'fag', 'fart', 'nigga', 'nigger', 'xxx', 'pornhub', 'xvideos', 'redtube', 'ass', 'tits', 'boob'];


export const validateStoryTitleLength = (title) => {
  return title.length <= 70;
};




export const validateStoryTitleContent = (title) => {
    const lowercaseTitle = title.toLowerCase();
    for (const word of forbiddenWords) {
        if (lowercaseTitle.includes(word)) {
            return false; // Title contains a forbidden word
        }
    }
    return true; // Title is clean
};