import React, { useState, useEffect } from 'react';
import { Center, Image, HStack, Box, Text, Link, Button, Flex, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useBreakpointValue, VStack } from '@chakra-ui/react'; // Import Chakra UI components} from '@chakra-ui/react';
import { useData } from '../App';
import { IoPersonCircleOutline } from "react-icons/io5";
import ReplayAudio from '../PostCreation/ReplayAudio';



const BeforeLoginSignup = () => {


    const { 
        setShowLoginPopup,
        setIsLogin,

        createPostTitle,

        createdPostAudio,
        createPostAudioDuration,
        setShowBeforeLoginSignupPopup,
        setShowFirstStepSignUp,

    } = useData();

    const [unFormattedTime, setUnformattedTime] = useState('');

    useEffect(() => {
        const unformatTime = (formattedTime) => {
            const parts = formattedTime.split(':');
            if (parts.length === 2) {
                const minutes = parseInt(parts[0], 10);
                const seconds = parseInt(parts[1], 10);
                return minutes * 60 + seconds;
            }
            return parseInt(formattedTime, 10);
        };

        if (createPostAudioDuration) {
            const unformattedTime = unformatTime(createPostAudioDuration);
            setUnformattedTime(unformattedTime);
        }
    }, [createPostAudioDuration]);

    const isSmallScreen = useBreakpointValue({ base: true, md: false });


    const LoginNow = () => {
        setIsLogin(true);
        setShowLoginPopup(true);
        setShowBeforeLoginSignupPopup(false); 

    }
    const SignUp = () => {
        setShowLoginPopup(true);
        setIsLogin(false);
        setShowFirstStepSignUp(true);
        setShowBeforeLoginSignupPopup(false); 


    }


  return (

<VStack>

<VStack>
              <Image src='/PrayerSendLogoText.png' h={20} alt='PrayerSend Logo'></Image>

<Flex mt="-30px" mb="3px" flexDirection="column">
<Text fontSize={10} color="gray" >from</Text>

              <Image src='/yenzeknamelogo.png' h={8} alt='YenZek Logo'></Image>
              </Flex>
              </VStack>
{createdPostAudio && (
    <>
<ReplayAudio
                            postAudio={createdPostAudio}
                            postAudioDuration={unFormattedTime} />

<Text pt="3px" as='b' fontSize={isSmallScreen ? "sm" : "md"} >{(createPostTitle)}</Text>
<IoPersonCircleOutline size="24px" color="gray" />

</>
)}



    <Button mb="2rem" onClick={SignUp} color='black'  _hover={{ bg: "#64a4d3", color: "white" }}>Sign up</Button>

    <Button
    variant='outline'
    bg="transparent"
    color="081b4f"
    size={isSmallScreen ? "xs" : "sm"}
    alignItems="center"
    cursor="pointer"
    _hover={{ bg: "gray.100" }} // Change background color on hover
    borderRadius="md" // Apply border radius to mimic button style
    p="2" // Add padding for better click area
    onClick={LoginNow}
>

<Image src='/yenzeklogoonly.png' h={3} alt='YenZek Logo'></Image>

    <Text ml="2" fontSize={isSmallScreen ? "xs" : "sm"} color="black" as="b">Log in with YenZek</Text>


</Button>

</VStack>
 
  )
}

export default BeforeLoginSignup