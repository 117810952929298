import React, { useState } from 'react'
import { useData } from '../App'
import { VStack, Text, HStack, Box, Button, Flex, Image, CircularProgress } from '@chakra-ui/react';
import { ImBlocked } from "react-icons/im";
import { useNavigate } from 'react-router-dom';

import BlockUser from './BlockUser';

const BlockUserPopup = ({ onCloseBlockPopup }) => {
    const { openBlockPopup, setOpenBlockPopup,
        personSelectedToBlock, setPersonSelectedToBlock } = useData();




    const navigate = useNavigate();

    const [wasBlockCreated, setBlockCreated] = useState(false);



    const initiatBlockOnUser = async () => {

        // SEND TO DATABASE
    //    console.log("RTest User to Block Name: ", personSelectedToBlock.name);
     //   console.log("RTest User to Block UID: ", personSelectedToBlock.uid);
        setBlockCreated(true);

        BlockUser(personSelectedToBlock.uid);


        setTimeout(() => {
            navigate("/requests")
            setBlockCreated(false);
            onCloseBlockPopup();
        }, 3000);

    };

    return (
        <VStack>
            <Text as='b' pb="10px">Block {personSelectedToBlock.name}?</Text>

            <Image
                src={personSelectedToBlock.profilePhoto}
                borderRadius='full'
                boxSize='90px'
                boxShadow="base"
                className="profile-image-circle"
            />
            <Text>{personSelectedToBlock.name}</Text>
            <Text mt={-3} fontSize="11px">{personSelectedToBlock.username}</Text>

            {!wasBlockCreated ? (
                <>
                    <Text m={5} fontSize={12} textAlign="center">{personSelectedToBlock.name} will no longer be able to contact you, send you messages, or meet you in a talkcircle.</Text>

                </>

            ) : (
                <>

                    <ImBlocked color="red" fontSize={24} />

                    <Text as='b' textAlign="center" fontSize={14}> You blocked {personSelectedToBlock.name}!</Text>



                </>


            )}

            {!wasBlockCreated && (

<Flex flexDirection="row" justifyContent="space-between" gap={10}> 
              <Button size='md' variant='outline' onClick={onCloseBlockPopup}>Cancel</Button>

                    <Button size='md'
                        variant='outline'
                        color='red'
                        onClick={() => initiatBlockOnUser()} // 
                        width={{ base: "100%", md: "100px" }}
                        border="2px"
                        pl={{ base: "2rem", md: "2rem" }}
                        pr={{ base: "2rem", md: "2rem" }}
                        maxWidth="200px"
                        bg='white'
                        _hover={{
                            bg: 'red',
                            color: 'white',
                        }}>Block</Button>



                </Flex>

            )}

        </VStack>



    )
}

export default BlockUserPopup