import { Box, VStack, Image, Flex, Input, Button, Link, Text } from '@chakra-ui/react';
import React from 'react';
import { useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';
import { useData } from '../../App';

const AuthForm = ({ onLoginSuccess, onSignUp, onSignUpSuccess, onFrontPageSignUpSuccess }) => {





  const {


    showFirstStepSignUp, setShowFirstStepSignUp,
    showSecondStepSignUp, setShowSecondStepSignUp,
    showThirdStepSignUp, setShowThirdStepSignUp, profileURL,
    isLogin, setIsLogin,
    isSignUp, setSignUp,
    isYenZekHomeOpen, setIsYenZekHomeOpen,
    isLoginPathOpen, setIsLoginPathOpen,
    isSignUpPathOpen, setIsSignUpPathOpen,

    eventActionTaken,

  } = useData();



  const renderSignUpPage = () => {

    setIsLogin(false);
    setShowFirstStepSignUp(true);
    // onSignUp();

  }






  const toggleAuthPage = () => {


    if (isLogin) {
      setIsLogin(false);
      setSignUp(true);
      setShowFirstStepSignUp(true);
    } else {
      setSignUp(false);
      setIsLogin(true);
      setShowFirstStepSignUp(false);
    }

  };


  return (
    <VStack>

        <>


          <VStack spacing={2} alignItems={'stretch'}>
            <Box maxW="md" mx="auto" p={10} borderWidth="1px" borderRadius="lg">

              <VStack>
                <Image src='/PrayerSendLogoText.png' h={20} alt='PrayerSend Logo'></Image>

                <Flex mt="-30px" mb="3px" flexDirection="column">
                  <Text fontSize={10} color="gray" >from</Text>

                  <Image src='/yenzeknamelogo.png' h={8} alt='YenZek Logo'></Image>
                </Flex>
              </VStack>


              {/*LOGIN FORM*/}

              {isLogin ? <LoginForm onLoginSuccess={onLoginSuccess} /> : <SignupForm onSignUpSuccess={onSignUpSuccess} />}


              {/*LOGIN FORM*/}


            </Box>

            {/* SIGN UP VIA POPUP */}


            <>
              <Box pt={5} pb={5} pl={10} pr={10} borderWidth="1px" borderRadius="lg">
                <VStack spacing={2}>
                  <Text textAlign="center" fontSize={14} pl={27} pr={27}>
                    {isLogin ? "Don't have an account? " : "Have an account? "}
                    <Link color="blue.500" onClick={toggleAuthPage}>
                      {isLogin ? "Sign up" : "Log in"}
                    </Link>
                  </Text>
                </VStack>
              </Box>
            </>





          </VStack>
        </>
     



    </VStack>
  );
};


export default AuthForm