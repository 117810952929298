
import { useState, useEffect } from 'react';
import { Box, Flex, VStack, Text, HStack, useBreakpointValue } from '@chakra-ui/react'
import { IoArrowBack } from "react-icons/io5";
import { useData } from '../App';
import SidebarSmall from './SidebarSmall'
import { useLocation } from 'react-router-dom';
import NotificationsList from './NotificationsList';
import { checkIfNotificationExists } from './NotificationsData';
import { currentUserId } from '../Utilities/firebase';
import { getNumberOfNotifications } from './NotificationsData';

const NotificationsSideBar = () => {

    const isBaseScreen = useBreakpointValue({ base: true, sm: false, md: false });

    const { setNotificationsSelected } = useData();

    const [isVisible, setIsVisible] = useState(false);


    const [notificationExists, setNotificationExists] = useState(false);

    useEffect(() => {
        const checkNotification = async () => {
            const exists = await checkIfNotificationExists(currentUserId);
            setNotificationExists(exists);
        };

        checkNotification();
    }, [currentUserId]);



    const [numberOfNotifications, setNumberOfNotifications] = useState(0);

    
    useEffect(() => {
        const fetchNotifications = async () => {
            if (currentUserId) {
                const notifications = await getNumberOfNotifications(currentUserId);
                setNumberOfNotifications(notifications);
            }
        };

        fetchNotifications();
    }, [currentUserId]);




    useEffect(() => {
        setIsVisible(true);

    }, []); // 


    return (
        <Box
            position="fixed"
            top="0"
            left={isVisible ? "0" : "-240px"} // Position based on visibility
            height="100%"
            bg="white"
            borderRight="1px solid #e4e4e4"
            transition="left 0.6s ease" // Slower transition
            zIndex="2"
            minW="470px"
            pb="8px"
            // mt={TalkCirclePaths || isBaseScreen ? "5rem" : null}
            mt="5rem"
            mb="60rem"
            style={{ borderRadius: '20px' }}>


            <HStack>

                {!isBaseScreen && (
                    <SidebarSmall />
                )}

                <VStack paddingInlineStart="6px" paddingInlineEnd="8px" pl={isBaseScreen ? "1rem" : "5rem"}>
                    <Flex direction="column">

                        <HStack pt="1rem" pb="1rem">
                            {isBaseScreen && (
                                <IoArrowBack onClick={() => setNotificationsSelected(false)} fontSize='24px' />
                            )}
                            <Text as='b' fontSize='24px' pt="1rem" pb="1rem ">Notifications</Text>

                        </HStack>

                        {notificationExists ? (
                                    <div style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>

                            <NotificationsList numberOfNotifications={numberOfNotifications} />
                            </div>
                        ) : (
                            <VStack>
                                <Text>No notifications</Text>
                            </VStack>

                        )}







                    </Flex>
                </VStack>


            </HStack>

        </Box>
    )
}


export default NotificationsSideBar