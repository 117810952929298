import React, { useState, useEffect, useRef } from 'react';
import { Center, Image, HStack, Box, Text, Link, Button, Flex, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useBreakpointValue, VStack } from '@chakra-ui/react'; // Import Chakra UI components} from '@chakra-ui/react';
import NavigationTop from './Headers/NavigationTop'
import Footer from './Footers/Footer'
import AuthForm from './pages/AuthPages/AuthForm';
import BeforeLoginSignup from './Popups/BeforeLoginSignup';
import { useData } from './App';

import SlideUpLoginSignUp from './Footers/SlideUpLoginSignUp';

import BottomNavigationBar from './Footers/BottomNavigationBar';

import useUserUsername from './pages/ProfilePage/ProfileUsername';

import ShareOptions from './ShareOptions';

import { getAuth } from 'firebase/auth';

import Sidebar from './Navigators/Sidebar';
import NotificationsSideBar from './Navigators/NotificationsSideBar';

import { useLocation, useNavigate } from 'react-router-dom';


import BlockUserPopup from './Popups/BlockUserPopup';

import PrayerSenderPopup from './Popups/PrayerSenderPopup';

import SettingsSideBar from './Navigators/SettingsSideBar';

const PageLayouts = ({ children }) => {


    const auth = getAuth();
    const user = auth.currentUser;


    const { username } = useUserUsername();

    const { pathname } = useLocation();
    const location = useLocation();


    const isBigScreen = useBreakpointValue({ base: false, md: true });



    const {


        showLoginPopup, setShowLoginPopup,
        showBeforeLoginSignupPopup, setShowBeforeLoginSignupPopup,
        showFirstStepSignUp, setShowFirstStepSignUp,
        showSecondStepSignUp, setShowSecondStepSignUp,
        showThirdStepSignUp, setShowThirdStepSignUp,
        isLogin, setIsLogin,
        isSignUp, setSignUp,


        showShareToPrayerSend, setShowShareToPrayerSend,
        ReferalEventLink, setReferalEventLink,


        openBlockPopup, setOpenBlockPopup,
        personSelectedToBlock, setPersonSelectedToBlock,

        openPrayerSenderPopup, setOpenPrayerSenderPopup,
        personSelectedToPrayerSend, setPersonSelectedToPrayerSend,

        isNotificationsSelected, setNotificationsSelected,



        isSettingsSelected, setSettingsSelected,

        isUserOnProfilePages,


    } = useData();


    const boxRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (boxRef.current.contains(event.target)) {

                setNotificationsSelected(false);
                setSettingsSelected(false);

            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const closeSignUpPopup = () => {
        setShowLoginPopup(false);
        setShowSecondStepSignUp(false);
        setShowThirdStepSignUp(false);




    };

    const closeBeforeSignUpPopup = () => {
        setShowBeforeLoginSignupPopup(false);




    };

    const closePrayerSendInvitePopup = () => {
        setShowShareToPrayerSend(false);




    };



    const closeBlockPopup = () => {
        setOpenBlockPopup(false);
        setPersonSelectedToBlock('');

    };


    const closePrayerSenderPopup = () => {
        setOpenPrayerSenderPopup(false);
        setPersonSelectedToPrayerSend('');

    };

    const isSmallScreen = useBreakpointValue({ base: true, md: false });
    const includedPostPagePath = ['/listen'];

    const isPrayRequestPathIncluded = includedPostPagePath.some(path => pathname.includes(path));

    const includedMainPagePath = ['/requests'];

    const isMainPathIncluded = includedMainPagePath.some(path => pathname.includes(path));

    const includedWelcomePagePath = ['/'];

    const isWelcomePage = includedWelcomePagePath.some(path => pathname.includes(path));


    const excludedPaths = [
        '/',
        '/login',
        '/signup',
        '/onboarding',
        '/account/getstarted',
        '/account/login',
        '/general',
        '/about',
        '/contact-us',
        '/company',
        '/help',
        '/terms-of-service',
        '/privacy-policy',
        '/community-guidelines',
        '/faqs',
        '/brand',
        '/legal',
        '/general/faqs/delete-account',
        '/social-reviews',
        '/prayersend',
        '/prayersend/privacy-policy',
        '/prayersend/terms',
        '/give',
        '/blessings'


    ];


    return (
        <Flex pt={!user && isBigScreen ? "5rem" : null}>
            <Box zIndex={2}>
                <NavigationTop />
            </Box>
            <Box
                ref={boxRef}
                flex={1}
                w={{ base: "calc(100% - 70px)", md: "calc(100% - 240px)" }}
                pt={isUserOnProfilePages || isMainPathIncluded ? (isSmallScreen ? "6rem" : "1rem") : (includedPostPagePath ? (isSmallScreen ? null : "1rem") : "5rem")}
                pb={isBigScreen ? "6rem" : "0rem"}
                pl="1rem"

            >

                {/*  SIDE BAR */}

                {!excludedPaths.includes(pathname) && (
                    <>

                        {user && !isSmallScreen && !isPrayRequestPathIncluded && !isSettingsSelected ? (
                            <Box w={{ base: "70px", sm: "70px", md: "70px", lg: "240px" }} pt="5rem" >
                                <Sidebar />
                            </Box>
                        ) : (
                            <>

                                {user && !isSmallScreen && !isPrayRequestPathIncluded && !isUserOnProfilePages && !isSettingsSelected && (
                                    <Box w={{ base: "70px", sm: "70px", md: "70px", lg: "240px" }} pt="5rem" >
                                        <Sidebar />
                                    </Box>
                                )}

                            </>
                        )}


                    </>
                )}






                {children}
            </Box>


            {isBigScreen && (
                <Footer />)}

            {/* SIDE BAR IS NOT SHOWING BUT EVENTSIDEBAR IS */}

            {user && isSettingsSelected && (
                <Box w={{ base: "70px", sm: "70px", md: "240px" }} pt="5rem" pl="30px">
                    <SettingsSideBar />
                </Box>


            )}

            {user && isNotificationsSelected && (
                <Box w={{ base: "70px", sm: "70px", md: "240px" }} pt="5rem" pl="30px">
                    <NotificationsSideBar />
                </Box>
            )}



            {/*  BOTTOM BAR  // FOOTER */}

            {!excludedPaths.includes(pathname) && isSmallScreen && user && (
                <BottomNavigationBar style={{ zIndex: 4 }} />
            )}


            {!user && !excludedPaths.includes(pathname) && (
                <SlideUpLoginSignUp />
            )}



            {/*  POPUP - MODAL :: LOGIN // SIGN UP */}


            <Modal isOpen={showLoginPopup} onClose={closeSignUpPopup} size={{ base: "sm", sm: "sm", md: "md" }}>
                <ModalOverlay />
                <ModalContent >

                    <ModalHeader as='b' fontSize='md' textAlign="center">
                        {showFirstStepSignUp || showSecondStepSignUp || showThirdStepSignUp ? "Sign up for PrayerSend with YenZek" : "Log in to PrayerSend with YenZek"}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <AuthForm onSignUpSuccess={closeSignUpPopup} />
                    </ModalBody>
                    <ModalFooter>
                        {/* You can add additional footer actions if needed */}
                    </ModalFooter>
                </ModalContent>
            </Modal>


            {/*  POPUP - MODAL :: LOGIN // SIGN UP */}


            <Modal isOpen={showBeforeLoginSignupPopup} onClose={closeBeforeSignUpPopup} size={{ base: "sm", sm: "sm", md: "md" }}>
                <ModalOverlay />
                <ModalContent >

                    <ModalHeader as='b' fontSize='md' textAlign="center" mx="2rem">
                        Sign up or log in to send your prayer request
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <BeforeLoginSignup />
                    </ModalBody>
                    <ModalFooter>
                        {/* You can add additional footer actions if needed */}
                    </ModalFooter>
                </ModalContent>
            </Modal>


            {/*  POPUP - MODAL :: SHARE PRAYERSEND */}


            <Modal isOpen={showShareToPrayerSend} onClose={closePrayerSendInvitePopup} size={{ base: "sm", sm: "sm", md: "md" }}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader textAlign="center">Share to...</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <ShareOptions shareLink={user ? `${ReferalEventLink}${username}` : "https://prayersend.com"} onCloseModal={closePrayerSendInvitePopup} />
                    </ModalBody>
                    <ModalFooter>
                        {/* You can add additional footer actions if needed */}
                    </ModalFooter>
                </ModalContent>
            </Modal>



            {/*   POPUP - MODAL  :: Block USER */}


            <Modal isOpen={openBlockPopup && user} onClose={closeBlockPopup} size={{ base: "sm", sm: "sm", md: "md" }}>
                <ModalOverlay />
                <ModalContent >

                    <ModalHeader as='b' fontSize='md' textAlign="center">

                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <BlockUserPopup onCloseBlockPopup={closeBlockPopup} />

                    </ModalBody>
                    <ModalFooter>
                        {/* You can add additional footer actions if needed */}
                    </ModalFooter>
                </ModalContent>
            </Modal>


            {/*   POPUP - MODAL  :: Prayer Sender  */}


            <Modal isOpen={openPrayerSenderPopup && user} onClose={closePrayerSenderPopup} size={{ base: "sm", sm: "sm", md: "md" }}>
                <ModalOverlay />
                <ModalContent >

                    <ModalHeader as='b' fontSize='md' textAlign="center">

                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <PrayerSenderPopup onClosePrayerSender={closePrayerSenderPopup} />
                    </ModalBody>
                    <ModalFooter>
                        {/* You can add additional footer actions if needed */}
                    </ModalFooter>
                </ModalContent>
            </Modal>




        </Flex>
    )
}

export default PageLayouts