import { useEffect, useRef, useState } from 'react';
import { useToast } from "@chakra-ui/react";
import { Box, Flex, VStack, Image, Text, HStack, Button, useBreakpointValue } from '@chakra-ui/react'
import { useData } from '../../App';
import { getAuth } from 'firebase/auth';

import AngelAnimation from '../../PostCreation/AngelAnimation';
import { GiAngelWings } from "react-icons/gi";


// import { currentUserId } from '../../Utilities/firebase';

// import useUserUsername from '../ProfilePage/ProfileUsername';

import { useNavigate } from 'react-router-dom';


// import { getUserInfo } from '../UserProfilePage/UserProfile';

// import UserProfile from '../UserProfilePage/UserProfile';




const InvitationOnboarding = () => {


    const isBaseScreen = useBreakpointValue({ base: true, sm: false, md: false });


    const auth = getAuth();
    const user = auth.currentUser;


    const navigate = useNavigate();




    const {



        getUsernameURL,


        inviteSenderName,
        inviteSenderProfilePic,
        inviteSenderUsername,
        inviteSenderUID,

    } = useData();





    const goSendPrayerRequest = () => {

        navigate('/');

    };



    // document.title = `${"Win Free Trips to Ibiza, Spain - Summer 2024 Vacation Giveaway"}`;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    useEffect(() => {

        getUsernameURL(inviteSenderUsername);


    }, []);



    return (


        <VStack>


            {inviteSenderName && (
                <>
                    <Image
                        src={inviteSenderProfilePic}
                        borderRadius='full'
                        boxSize='90px'
                        boxShadow="base"
                        className="profile-image-circle"
                    />
                    <Text>{inviteSenderName}</Text>
                    <Text mt={-3} fontSize="11px">{inviteSenderUsername}</Text>
                    <Text m={2} fontSize={12} textAlign="center" as="b">{inviteSenderName} invited you to PrayerSend</Text>
                </>
            )}

<VStack>
              <Image src='/PrayerSendLogoText.png' h={14} alt='PrayerSend Logo'></Image>

<Flex mt="-30px" mb="3px" flexDirection="column">
<Text fontSize={10} color="gray" >from</Text>

              <Image src='/yenzeknamelogo.png' h={8} alt='YenZek Logo'></Image>
              </Flex>
              </VStack>

            <Text mx="2rem" textAlign="center" fontSize={isBaseScreen ? 12 : 14} as="b">Share your prayer requests on PrayerSend and let people from around the world unite in prayer for you.</Text>


            <AngelAnimation />

            <HStack>
           

                <Button mt="2rem" onClick={goSendPrayerRequest} color="#64a4d3" colorScheme="black" variant="outline" size="sm" mr={2}
                    _hover={{
                        color: "white",
                        bg: '#FFCC00',
                        transform: "scale(1.022)",
                    }} style={{
                        transition: "transform 0.5s",
                    }}> Get started
                </Button>
                
              
            </HStack>

        </VStack>












    )
}

export default InvitationOnboarding