import React, { useState, useRef, useEffect } from 'react';
import { FaPlay, FaPause } from "react-icons/fa";
import './seekbar.css';
import { useLocation } from 'react-router-dom';
import { currentUserId } from '../Utilities/firebase';
import { updateNumberOfListens } from './PostDataModel';
import { VStack } from '@chakra-ui/react';
import { FaCirclePlay } from "react-icons/fa6";
import { FaCirclePause } from "react-icons/fa6";

import { useData } from '../App';

const PostAudio = ({ postAudio, postAudioDuration, postTimes_Listened, postId, storyURL, creatorUID}) => {
    const [isPlaying, setIsPlaying] = useState(true); // Change initial state to true
    const [currentTime, setCurrentTime] = useState(0);
    const [triggered, setTriggered] = useState(false);
    const [userInteracted, setUserInteracted] = useState(false);

    const { openPrayerSenderPopup, prayerIsPlaying, setPrayerRequestIsPlaying} = useData();

    
    const audioRef = useRef(null);

    const { pathname } = useLocation();

   


    


    const includedTalkCirclePaths = [ '/talkcircles'];
    const isUserOnTalkCircle = includedTalkCirclePaths.some(path => pathname.includes(path));

          // Convert time format "M:SS" to seconds
          const convertTimeToSeconds = (time) => {
            if (!time || !time.includes(':')) {
                console.error('Invalid time format:', time);
                return 0; // Return 0 or a default value to avoid further errors
            }
            const [minutes, seconds] = time.split(':').map(Number);
            return minutes * 60 + seconds;
        };
        const [durationInSeconds, setDurationInSeconds] = useState(0);
    
        useEffect(() => {
            if (postAudioDuration) {
            setDurationInSeconds(convertTimeToSeconds(postAudioDuration));
            }
        }, [postAudioDuration]);


        useEffect(() => {
            const audioElement = audioRef.current;
            // Check if the audio element is paused
            if (audioElement.paused) {
                audioElement.play();
                setIsPlaying(true);
            }
        }, [postAudio]);
 

  useEffect(() => {
    const audioElement = audioRef.current;

    // Start playing audio only when isPlaying state is true
    if (isPlaying) {
        audioElement.play();

        const playPromise = audioElement.play();
        if (playPromise !== undefined && !isUserOnTalkCircle) {
            playPromise.catch(error => {
        
               // console.error("Playback prevented by the browser. User interaction required.", error);
                audioElement.play();
                setPrayerRequestIsPlaying(true);
           
            });
        }
      
    } else {
        audioElement.pause();

        setTriggered(false); 
        setPrayerRequestIsPlaying(false);
      
    }

    // Cleanup function to pause audio when the component unmounts
    return () => {
        audioElement.pause();
    };
}, [isPlaying, postAudio]);




const updateListenCount = () => {
    // Convert string to integer, increment, and possibly update database

    const newListenCount = parseInt(postTimes_Listened, 10) + 1;

    if (newListenCount) {

        updateNumberOfListens(storyURL, newListenCount);

    }
 
};




    
useEffect(() => {
    const audioElement = audioRef.current;
    // Check if the audio element is paused
    if (prayerIsPlaying) {
        audioElement.pause();
        setIsPlaying(false);

    }
}, [prayerIsPlaying]);






// Add this useEffect to handle the end of audio playback
useEffect(() => {
    const audioElement = audioRef.current;

    const handleEnded = () => {
        setIsPlaying(false); // Set isPlaying to false when playback ends

        setPrayerRequestIsPlaying(false);
       
          
        
    };

    audioElement.addEventListener('ended', handleEnded);

    return () => {
        audioElement.removeEventListener('ended', handleEnded);
    };
}, [postAudio]); 


useEffect(() => {
    const audioElement = audioRef.current;

    if (openPrayerSenderPopup) {
        audioElement.pause();
        setIsPlaying(false); // Set isPlaying to false when playback ends

    }
          
    
}, [openPrayerSenderPopup]); 






    useEffect(() => {
        
        if (!triggered && durationInSeconds < 30 && (durationInSeconds - currentTime <= 2) && currentUserId !== creatorUID) {
 
            if (!isUserOnTalkCircle) {
                updateListenCount();  
            }
            setTriggered(true);  

        } 
        
        if (!triggered && currentTime >= 30 && currentUserId !== creatorUID) {
            if (!isUserOnTalkCircle) {
            updateListenCount();  
        }
            setTriggered(true); 
        } 
    }, [currentTime, currentUserId, creatorUID, triggered]);




 
const togglePlay = () => {
  

    setIsPlaying(prevIsPlaying => !prevIsPlaying);
    setPrayerRequestIsPlaying(prevIsPlaying => !prevIsPlaying);

};


const handleTimeUpdate = () => {
        setCurrentTime(audioRef.current.currentTime);
    };
    


    const handleSeek = (e) => {
        const newTime = e.target.value;
        setCurrentTime(newTime);
        audioRef.current.currentTime = newTime;
    };



    const playedPercentage = (currentTime / durationInSeconds) * 100;

    const seekBarStyle = {
        '--played-width': `${playedPercentage}%`,
        background: `linear-gradient(to right, #64a4d3 ${playedPercentage}%, #c4c4c4 ${playedPercentage}%)`

    };

    

    

return (
    <div className="post-audio">
        <audio
            ref={audioRef}
            src={postAudio}
            onTimeUpdate={handleTimeUpdate}
       
           // onPlay={() => setIsPlaying(true)}
           // onPause={() => setIsPlaying(false)}
        />

        <div className="audio-controls">

         
            <button className="play-pause-button" onClick={togglePlay}>
                {isPlaying ? <FaCirclePause size={24} /> : <FaCirclePlay size={24} />}
            </button>


            <div className="seek-bar-container">
                <input
                    type="range"
                    className="seek-bar"
                    min="0"
                    max={durationInSeconds}
                    value={currentTime}
                    onChange={handleSeek}
                    style={seekBarStyle}
                />
            </div>
            <div className="time-display">
                <span className="current-time">{formatTime(currentTime)}</span>
                <span className="time-divider">{'/ '}</span>
                <span className="total-time">{formatTime(durationInSeconds)}</span>
            </div>

        </div>
    </div>
);
};

const formatTime = (time) => {
const minutes = Math.floor(time / 60);
const seconds = Math.floor(time % 60);
return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

export default PostAudio;