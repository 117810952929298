import { useState } from 'react';
import { useData } from '../App';

const useAudioRecording = () => {
    const [recording, setRecording] = useState(null);
    const [audioURL, setAudioURL] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const { setPostAudioType, setPostAudioBlob } = useData();



    const getSupportedAudioType = () => {
        const types = [
  
            { type: 'audio/mp4', label: 'AAC' },
            { type: 'audio/webm', label: 'WebM' },

        ];

        for (const t of types) {
            if (MediaRecorder.isTypeSupported(t.type)) {
            //    console.log(`ZTest: ${t.label} is supported in this browser.`);
                return t.type;
            }
        }
    //    console.log('ZTest: No supported audio types found.');
        setErrorMessage('Your browser doesnt support our auio formats. Please use a compatible browser (i.e. Chrome or Safari)');

        return null; // You might want to handle this case explicitly in your UI
    };

 

    const startAudioRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const audioType = getSupportedAudioType(); // Determine the supported type

            if (!audioType) {
           //     console.log("ZTest: No compatible audio types found. Adjusting settings or notifying the user.");
                setErrorMessage('No supported audio formats. Please use a compatible browser.');
                return; // Stop the function if no supported types are found
            }

         //   console.log(`ZTest: Using ${audioType} for recording.`);
            const recorder = new MediaRecorder(stream, { mimeType: audioType });
            const chunks = [];

            recorder.ondataavailable = (e) => {
                chunks.push(e.data);
            };

            recorder.onstop = async () => {
                const blob = new Blob(chunks, { type: audioType });
                const url = URL.createObjectURL(blob);
            //    console.log("1.Audio FILE: " + url);
                setAudioURL(url);
                setPostAudioBlob(blob); 
                setPostAudioType(audioType);

            };

            recorder.start();
            setRecording(recorder);
        } catch (error) {
            // Handle permission denied or other errors
            console.error('Error accessing microphone:', error.message);
            setErrorMessage('Error accessing microphone. Please grant permission to record audio.');
            // If recording was started but permission was denied, stop the recording
            if (recording) {
                recording.stop();
                setRecording(null);
            }
            throw error; // Propagate the error to the caller
        }
    };

    const stopAudioRecording = () => {
        if (recording) {
            recording.stop();
            setRecording(null);
        }
    };

    return { startAudioRecording, stopAudioRecording, recording, audioURL, errorMessage, setErrorMessage };
};

export default useAudioRecording;