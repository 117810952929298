import React, { useEffect } from 'react'
import { Text, VStack, Box, useBreakpointValue } from '@chakra-ui/react'
const DonatePage = () => {

    const isSmallScreen = useBreakpointValue({ base: true, sm: false, md: false });

    useEffect(() => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);
      }, []); // Empty dependency array ensures this runs once on mount
    

    return (
        <VStack pt={isSmallScreen ? null : "6rem"}>
            <Text as="b" fontSize="18px" textAlign="center">Give to PrayerSend and Receive a Blessing</Text>
            <Text fontSize="11px" as="i" px="4rem" textAlign="center">"Give as you have decided in your heart, not reluctantly or under compulsion, for God loves a cheerful giver. And thereby put me to the test, says the Lord of hosts, if I will not open the windows of heaven for you and pour down for you a blessing until there is no more need."</Text>
            <Text  fontSize="11px"  textAlign="center">2 Corinthians 9:7 & Malachi 3:10b</Text>

            <Box display="flex" alignItems="center" justifyContent="center" width="100%" mx="1rem">
                <VStack spacing={4} align="center" width="100%">
                    <Box display="flex" alignItems="center" ml={isSmallScreen ? null : "6rem"} justifyContent="center" width="100%">
                        <iframe
                            src="https://donorbox.org/embed/prayersend?language=en"
                            name="donorbox"
                            allowpaymentrequest="allowpaymentrequest"
                            seamless="seamless"
                            frameBorder="0"
                            scrolling="no"
                            height="900px"
                            width="100%"
                            style={{ maxWidth: '500px', minWidth: '250px', maxHeight: 'none!important' }}
                            allow="payment"
                        ></iframe>
                    </Box>
                </VStack>
            </Box>

        </VStack>
    )
}

export default DonatePage