import React from 'react'
import { VStack, Text } from '@chakra-ui/react'
import { usePageTitle } from '../Utilities/PageTitles';
import { Helmet } from 'react-helmet';


const ContactUs = () => {

  usePageTitle("Contact Us")
  return (
    <VStack mt="6rem">

<Helmet>
        <title>{`Contact PrayerSend`}</title>
        <meta name="description" content="Contact us. " />
        <meta charset="UTF-8" />
      </Helmet>

      <VStack pt='6rem' px={{ base: '2rem', sm: '2rem', md: '4rem', lg: '14rem' }}>

<Text as="b" fontSize="lg">Contact Us</Text>

     <p>Contact us via the YenZek Portal or via the information we provide below.</p>
    <p>YenZek Support will never reach out to you first. We will only respond if contacted.</p>

    <p>Get in touch with YenZek Support by emailing Us at - social [ at ] yenzek.com</p>

    <p>Contact with YenZek or any YenZek representative will only come from an @yenzek.com email address not from within the app.</p>

    </VStack>
    </VStack>
  )
}

export default ContactUs