import React, { useEffect, useState } from 'react';
import { currentUserId } from '../Utilities/firebase';
import { VStack, useBreakpointValue } from '@chakra-ui/react';
import { useData } from '../App';
import LoadingPage from '../LoadingPage';
import NotFound from '../NotFound';
import useUserUsername from '../pages/ProfilePage/ProfileUsername';

import getUserByUsername from '../pages/UserProfilePage/UserProfile';

import ProfilePage from '../pages/ProfilePage/ProfilePage';
import UserProfilePage from '../pages/UserProfilePage/UserProfilePage';

import { getAuth } from 'firebase/auth';

const ProfileDeterminator = () => {
    const {  setNotFoundPageVisible,
          profileURL } = useData();
    const [userExists, setUserExists] = useState(null); // State to track if the user exists
    const { username } = useUserUsername();
  const [isLoading, setIsLoading] = useState(true); 

  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  const auth = getAuth();
  const user = auth.currentUser;
    useEffect(() => {
        setIsLoading(true);

        const checkUserExists = async () => {
            try {
                const exists = await getUserByUsername(profileURL); // Call getUserByUsername function
                setUserExists(exists); // Update userExists state based on function result
                if (exists) {
                    setNotFoundPageVisible(false);
                }

            } catch (error) {
                console.error('Error checking if user exists:', error);
                setUserExists(false); // Set userExists to false in case of an error
            } finally {
                setIsLoading(false); // Set loading to false when useEffect finishes
           
             
            }

           
        };

        if (profileURL) {
            checkUserExists(); // Call the function to check if user exists

           
        }
    }, [profileURL]);

    // Render loading indicator while determining the page
    /*
    if (isLoading) {
        return (
        
            <LoadingPage />
          
        );
    }
*/
    // Render ProfilePage if username matches profileURL and currentUserId is available
    if (username === profileURL && currentUserId) {
        return (
        
            <VStack pt={user && isSmallScreen ? "3rem" : null }>
        <ProfilePage />

        </VStack>

        )
    }

    // Render UserProfilePage if user exists
    if (userExists === true) {
       
        return  (
            <VStack pt={user && isSmallScreen ? "3rem" : null }>
        
        <UserProfilePage  profileURL={profileURL}/>
        </VStack>
        )
      
    } 

    // Render NotFound if profileURL doesn't match any valid user or currentUserId is not available
    if (!isLoading && !userExists) {
        return <NotFound />;

        }
};

export default ProfileDeterminator;