import React, { useState, useEffect } from 'react'
import { useData } from '../App'
import { VStack, Text, HStack, Box, Center, Button, Flex, Image, UnorderedList, ListItem, Spacer, CircularProgress, Input, useBreakpointValue } from '@chakra-ui/react';
import { CgRedo } from "react-icons/cg";
import { FaArrowCircleRight } from "react-icons/fa";
import ReplayAudio from '../PostCreation/ReplayAudio';
import AngelAnimation from '../PostCreation/AngelAnimation';
import { AiFillAudio } from "react-icons/ai";
import useAudioRecording from '../Utilities/recordAudioUtils';
import LanguageSelector from '../PostCreation/LanguageSelectors';
import { useNavigate } from 'react-router-dom';
import { generateUUID } from '../Utilities/urlGenerator';

import { getCurrentDateTime } from '../Utilities/currentDateTimeUtils';

import { uploadPrayer } from '../PostCreation/CreatePostDataModel';



import { getAuth } from 'firebase/auth';

import { userLanguageName } from '../Utilities/BrowserLanguage/language';

import PrayerActionSettings from '../Navigators/PrayerActionSettings';

const PrayerSenderPopup = ({ onClosePrayerSender }) => {
    const [buttonClicked, setButtonClicked] = useState(false); // 
    const [isLoading, setIsLoading] = useState(false);



    // -----
    const auth = getAuth();
    const user = auth.currentUser;




    const { openPrayerSenderPopup, setOpenPrayerSenderPopup, postAudioBlob, postAudioType, personSelectedToPrayerSend, saveRecordingData, setShowPublisherSettings, setStoryJustPublished, setShowBeforeLoginSignupPopup,
     
        setPersonSelectedToPrayerSend,  } = useData();

    const { startAudioRecording, stopAudioRecording, errorMessage, setErrorMessage, audioURL } = useAudioRecording();
    const [isPermissionGranted, setIsPermissionGranted] = useState(false);
    const [audioBlob, setAudioBlob] = useState(null); // State to store the recorded audio blob
    const [isRecording, setIsRecording] = useState(false);
    const [hasFinishedRecording, setHasFinishedRecording] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [recordingTime, setRecordingTime] = useState(0);
    const [isHovered, setIsHovered] = useState(false);
    const [progressWidth, setProgressWidth] = useState(0); // State to manage progress bar width
    const [errorInRecording, setErrorInRecording] = useState(false);
    const [redoStoryMessage, setRedoStoryMessage] = useState(false);


    const [prayerJustSent, setPrayerJustSent] = useState(false);

    const [didAgreeToTerms, setDidAgreeToTerms] = useState(false);



    const handleHover = (color) => {
        document.querySelectorAll('.hoverable').forEach(element => {
            element.style.color = color;
        });
    };

    const isSmallScreen = useBreakpointValue({ base: true, md: false });






    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [isLanguageListShown, setLanguageListShown] = useState(false);

    const showLanguageList = () => {
        setLanguageListShown(true);
    };
    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
        setLanguageListShown(false);
    };


    const agreeToTerms = () => {
        cancelRecording();
        setDidAgreeToTerms(true);

    }


    const cancelRecording = () => {
        stopAudioRecording();
        setIsRecording(false);
        setHasFinishedRecording(false);
        resetRecording();
        setAudioBlob('');

        // for pages only
        window.scrollTo(0, 0);

        finalCheckAndHandleMicrophonePermission();
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const startRecording = () => {

        setIsRecording(true);
        startAudioRecording();

        setErrorMessage("");
        setRedoStoryMessage("");

    };




    const finishRecording = () => {


        // AUDIO MUST GO IN HERE 

        stopAudioRecording();
        setIsRecording(false);
        setHasFinishedRecording(true);
        setProgressWidth(100);

        if (errorMessage) {
            setErrorMessage("Audio failed! Please grant permission to record audio before recording your prayer.");

        }

    };

    const resetToRecord = () => {
        setHasFinishedRecording(false);
        resetRecording();
        setAudioBlob('');
        setProgressWidth(0);

        // for pages only
        window.scrollTo(0, 0);

        if (!isPermissionGranted) {
            setErrorMessage("Your prayer request was not fully recorded, because you didn't grant permission. Please grant permission to record audio before recording your prayer.");

        }
    };

    // Add this condition to call finishRecording at 5 minutes
    const maxDuration = 300
    const redTimeTigger = 290
    const yellowTimeTigger = 270


    // FOR TESTING ONLY
    //const maxDuration = 30
    // const redTimeTigger = 25
    // const yellowTimeTigger = 20


    useEffect(() => {
        let timer;
        if (isRecording && !hasFinishedRecording) {
            timer = setInterval(() => {
                setRecordingTime(prevTime => prevTime + 1);
            }, 1000);
        } else {
            clearInterval(timer);
        }



        if (hasFinishedRecording) {
            setProgressWidth(100); // Set progressWidth to 100 if recording has finished
        } else {
            const newWidth = (recordingTime / maxDuration) * 100;
            setProgressWidth(newWidth);
        }

        if (recordingTime >= maxDuration) {
            finishRecording();
        }

        return () => clearInterval(timer);
    }, [isRecording, hasFinishedRecording, recordingTime]);


    useEffect(() => {
        if (audioURL) {
            setAudioBlob(audioURL);

            //     console.log("2.Audio FILE: " + audioURL)

            //     console.log("3.Audio FILE: " + audioURL)

        }
    }, [audioURL]);


    const resetRecording = () => {
        setRecordingTime(0);
    };

    const onShowPublisherSettings = () => {
        setStoryJustPublished(false);
        setShowPublisherSettings(true);


        window.scrollTo(0, 0);


        // Temprarily False (these two)
        setHasFinishedRecording(false);
        resetRecording();
    };


    // Format the time in MM:SS format
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };



    useEffect(() => {
        let timer;
        if (isRecording && !hasFinishedRecording) {
            timer = setInterval(() => {
                setRecordingTime(prevTime => prevTime + 1);
            }, 1000);
        } else {
            clearInterval(timer);
        }

        return () => clearInterval(timer);
    }, [isRecording, hasFinishedRecording]);



    useEffect(() => {


        // Check if navigator.permissions and its methods are supported
        if (navigator.permissions && navigator.permissions.query) {
            navigator.permissions.query({ name: 'microphone' }).then(permissionStatus => {
                if (permissionStatus.state === 'denied') {
                    // Microphone permission denied, set error message
                    cancelRecording();
                    setErrorMessage('Error accessing microphone. Please grant permission to record audio in your browser settings.');
                    setErrorInRecording("Your prayer was not fully recorded. Please grant permission to record audio in your browser settings.");

                } else {
                    // Microphone permission granted or prompt required
                    // Attach event listener for permission changes
                    navigator.permissions.addEventListener('change', checkAndHandleMicrophonePermission);
                }

            });
        }

        return () => {
            if (navigator.permissions && navigator.permissions.removeEventListener) {
                navigator.permissions.removeEventListener('change', checkAndHandleMicrophonePermission);
            }
        };
    }, []);

    const checkMicrophonePermission = async () => {
        try {
            const permissionStatus = await navigator.permissions.query({ name: 'microphone' });
            return permissionStatus.state === 'granted';


        } catch (error) {
            console.error('Error checking microphone permission:', error);
            return false; // Return false if there's an error
        }
    };

    // Check microphone permission and handle the result
    const checkAndHandleMicrophonePermission = async () => {

        const isMicrophoneGranted = await checkMicrophonePermission();
        setIsPermissionGranted(isMicrophoneGranted);

        if (isMicrophoneGranted && errorMessage) {
            cancelRecording();
            setIsPermissionGranted(true);
        }

    };

    // Check microphone permission and handle the result
    const finalCheckAndHandleMicrophonePermission = async () => {
        const isMicrophoneGranted = await checkMicrophonePermission();
        setIsPermissionGranted(isMicrophoneGranted);

        if (isMicrophoneGranted && errorMessage) {

            setIsPermissionGranted(true);
            setErrorInRecording('');
            setRedoStoryMessage('');
            setErrorMessage('');
        }
    };

    useEffect(() => {
        if (isRecording && !isPermissionGranted) {
            setErrorMessage("Your prayer is not being recorded. Please grant permission to record audio in your browser settings.");
            setErrorInRecording("Your prayer was not fully recorded. Please grant permission to record audio in your browser settings.");
            setRedoStoryMessage("Your prayer is not recording. Please agree to the community guidelines!")
        }
    }, [isRecording, isPermissionGranted]);





    const publishPrayer = async () => {
        await checkAndHandleMicrophonePermission();

        if (!isPermissionGranted && errorInRecording) {
            setErrorMessage("Your prayer was not fully recorded. Please grant permission to record audio in your browser settings.");
            return;
        }

        if (!user)  {
            setShowBeforeLoginSignupPopup(true); 
        } else {
        setIsLoading(true);




        if (!buttonClicked) {

            setButtonClicked(true);

            setProgressWidth(0);
            setButtonClicked(true);

            const startTime = Date.now();

           

            try {
                // Finalize final data additions 
                let languageToSave = selectedLanguage ? selectedLanguage.langName : userLanguageName;
                //     console.log("++ 1.WE have an Image ", imageURL);

                //     console.log("++ Language we have here : ", languageToSave);
                //     console.log("++ 3.WE have an Image ", imageURL);
                // PUBLISH THE STORY
                let postUUID = generateUUID();
                const dateAdded = getCurrentDateTime();

                let timeDur = formatTime(recordingTime);

             
                saveRecordingData(audioBlob, timeDur);



                console.log("1.postUUID type", postUUID)
                console.log("2.dateAdded type", dateAdded)
                console.log("3.createPostAudioDuration type", timeDur)
                console.log("4.languageToSave type", languageToSave)

       
            
                console.log("5.posttAudio type", postAudioType)
                console.log("6.audioBlob type", postAudioBlob)


                console.log("7.receiever UID type", personSelectedToPrayerSend.uid)
                console.log("8.postURL Type type", personSelectedToPrayerSend.postURL)
                console.log("9.postURL Ttitle of prayer request", personSelectedToPrayerSend.postCaption)

             
             
             
                
                await uploadPrayer(postUUID,
                    dateAdded,
                    timeDur,
                    languageToSave,
                    postAudioType,
                    postAudioBlob,
                    personSelectedToPrayerSend.uid,
                    personSelectedToPrayerSend.postURL,
                    personSelectedToPrayerSend.postCaption

                );

               

                const endTime = Date.now();
                const duration = endTime - startTime;

                // If the function executes too quickly, set a minimum duration to ensure smooth animation
                const minDuration = 3000; // 3 second
                const actualDuration = Math.max(duration, minDuration);

                setProgressWidth(100); // Set progress to 100% after completion
                // Reset progress bar after a delay to indicate completion before resetting
                setTimeout(() => {
                    setProgressWidth(0);
                }, actualDuration);

           

       

             setTimeout(() => {
                setIsLoading(false);
        
              
               // setStoryJustPublished(true);

               setPrayerJustSent(true);
            


            }, actualDuration);



            } catch (error) {
                console.error("Error publishing the story:", error);
            }

        }

    }
    };






    return (

     <>
    

    {prayerJustSent ? (
        
            <PrayerActionSettings onClosePopup={onClosePrayerSender} />
                
        ) : (
 <>
        <VStack>

{isLanguageListShown ? (
                <>

                    <LanguageSelector onSelectLanguage={handleLanguageChange} />

                    <Spacer />

                </>
            ) : (

                <>
      
        <VStack>
            <Text as='b' pb="10px" fontSize="18px">Pray for {personSelectedToPrayerSend.name}</Text>



            <HStack>
                <VStack>
                    <Image
                        src={personSelectedToPrayerSend.profilePhoto}
                        borderRadius='full'
                        boxSize='90px'
                        boxShadow="base"
                        className="profile-image-circle"
                    />
                    <Text>{personSelectedToPrayerSend.name}</Text>
                    <Text mt={-3} fontSize="11px">{personSelectedToPrayerSend.username}</Text>

                </VStack>
                <Box mb="3rem">
                    <Text fontSize="18" fontWeight="400" as="i">"{personSelectedToPrayerSend.postCaption}..."</Text>
                </Box>
            </HStack>

            {isRecording && !errorMessage && !redoStoryMessage && (
                <Box mb="2rem">


                    <AngelAnimation />

                </Box>

            )}


            <VStack>

                {!isRecording && !hasFinishedRecording && (
                    <Text m={5} fontSize={12} textAlign="center">Send a prayer to {personSelectedToPrayerSend.name}</Text>
                )}

                {!isRecording && !hasFinishedRecording || errorMessage && (

                    <VStack mt="5rem">
                        <Text fontSize={isSmallScreen ? "10px" : "14px"} textAlign="center" pt="0px" color="red.500" mt="-4rem">{redoStoryMessage}</Text>

                        <Text as="b" textAlign="center" fontSize="sm" fontWeight="bold" color="#373635">
                            Community guidelines
                        </Text>
                        <Flex flexDirection="column">
                            <Text fontSize="sm" fontWeight="bold" color="#gray">
                                i. No politics when sending prayers!
                            </Text>
                            <UnorderedList sx={{ paddingLeft: "2rem" }}>
                                <ListItem color="gray" fontSize="14px">Please avoid including political rhetoric in your prayers unless it is specifically mentioned within the prayer request.
                                </ListItem>
                            </UnorderedList>
                            <Text fontSize="sm" fontWeight="bold" color="#gray">
                                ii. No denominational references when sending prayers!
                            </Text>
                            <UnorderedList sx={{ paddingLeft: "2rem" }}>
                                <ListItem color="gray" fontSize="14px">Do not mention the name of the denomination you’re affiliated with in your prayers. We’re here to unite, not divide.
                                </ListItem>
                            </UnorderedList>
                            <Text fontSize="sm" fontWeight="bold" color="#gray">
                                iii. Focus on the person when sending prayers!
                            </Text>
                            <UnorderedList sx={{ paddingLeft: "2rem" }}>
                                <ListItem color="gray" fontSize="14px">Remember, your prayer should focus on the person you are praying for. It’s about them not you!
                                </ListItem>
                            </UnorderedList>
                        </Flex>
                    </VStack>

                )}


                {isRecording && redoStoryMessage && !hasFinishedRecording && (

                    <>



                        <Button
                            color="#64a4d3" colorScheme="black" variant="outline" size="sm" mr={2}
                            _hover={{
                                color: "white",
                                bg: '#64a4d3',
                                transform: "scale(1.022)",
                            }} style={{
                                transition: "transform 0.5s",
                            }}
                            p="2" // Add padding for better click area
                            onClick={agreeToTerms}
                        >
                            I agree

                        </Button>
                    </>
                )}
            </VStack>

            {hasFinishedRecording ? (
                <Box alignItems="center" flexDirection='column' display="flex">
                    <Text textAlign="center" mb="3px" fontSize='lg' color="#2DC42A ">{formatTime(recordingTime)}</Text>
                    <Text textAlign="center" mb="10px" fontSize='sm' color="#2DC42A ">PRAYER COMPLETED</Text>


                    <div className="circle-finish">
                        <AiFillAudio className="audio-icon-finish" />
                    </div>

                    {isLoading && <CircularProgress color='#07BEFF' size="20px" isIndeterminate />} {/* Loading Spinner */}


                    <Flex pt="5" alignItems="center" >
                                <Text fontSize={isSmallScreen ? "xs" : "sm"} color="gray.500" > Access: </Text>
                                <Text as='b' pl="1.5" fontSize={isSmallScreen ? "xs" : "sm"} color="gray.500" >Public</Text>
                            </Flex>

                            <Box width={{ base: "100%", md: "300px" }}
                                minWidth="auto" h="0.5px" bg="gray.300" mb="1" /> {/* Gray line */}
                            <Button variant='outline' onClick={showLanguageList}>
                                <Flex alignItems="center" >

                                    <Text fontSize={isSmallScreen ? "xs" : "sm"} color="gray.500" > Language: </Text>

                                    {selectedLanguage ? (
                                        <Text as="b" pl="1.5" fontSize="sm" color="#50CBF5">
                                            {selectedLanguage.name}
                                        </Text>
                                    ) : (

                                        <Text as='b' pl="1.5" fontSize={isSmallScreen ? "xs" : "sm"} color="#50CBF5" >{userLanguageName} </Text>
                                    )}


                                </Flex>
                            </Button>


                    <Box pt="5" display="flex" alignItems="center" justifyContent="center" gap={5}>

                        {hasFinishedRecording && (
                            <>
                                <Flex
                                    as="button"
                                    variant='outline'
                                    size={isSmallScreen ? "xs" : "sm"}
                                    alignItems="center"
                                    cursor="pointer"
                                    _hover={{ bg: "gray.100" }} // Change background color on hover
                                    borderRadius="md" // Apply border radius to mimic button style
                                    p="2" // Add padding for better click area
                                    onClick={resetToRecord}
                                >

                                    <CgRedo size={isSmallScreen ? "14px" : "16px"} color="gray" />
                                    <Text ml="2" fontSize={isSmallScreen ? "sm" : "sm"} color="gray">Redo</Text>


                                </Flex>


                                <Button
                                    variant='outline'
                                    size={isSmallScreen ? "sm" : "sm"}
                                    alignItems="center"
                                    cursor="pointer"
                                    color="#64a4d3"
                                    colorScheme='#64a4d3'
                                    rightIcon={<FaArrowCircleRight color="#64a4d3" />}
                                    _hover={{ bg: "gray.100" }} // Change background color on hover
                                    borderRadius="md" // Apply border radius to mimic button style
                                    p="2" // Add padding for better click area
                                    onClick={publishPrayer}
                                  
                                >
                                   Send
                                


                                </Button>

                            </>


                        )}


                    </Box>

                    <ReplayAudio
                        postAudio={audioBlob}
                        postAudioDuration={recordingTime}
                    />

                   

                </Box>


            ) : (
                <>
                    <Flex flexDirection="column" alignItems="center" justifyContent="center">
                        {!isRecording ? (

                            <div className="circle">
                                <AiFillAudio className="audio-icon" onClick={startRecording} />
                            </div>

                        ) : (
                            <>

                                {didAgreeToTerms && (
                                    <Box pb="4">
                                        <Text
                                            color={recordingTime >= yellowTimeTigger && recordingTime < redTimeTigger ? "#FFD501" : recordingTime >= redTimeTigger ? "red" : "black"}
                                            fontSize={{ base: '14px', sm: '16px', md: '18px' }}
                                            className={recordingTime >= yellowTimeTigger && recordingTime < redTimeTigger ? "pulse-animation" : recordingTime >= redTimeTigger ? "pulse-animation" : ""}
                                        >
                                            {formatTime(recordingTime)}
                                        </Text>
                                    </Box>
                                )}

                                {didAgreeToTerms && (
                                    <div className="circle-rec">
                                        <AiFillAudio className={isRecording ? "audio-icon-rec fade-in-out" : "audio-icon-rec"} onClick={finishRecording} />
                                    </div>
                                )}



                            </>
                        )}
                    </Flex>
                </>
            )}
            <Center>

                {!isRecording && !hasFinishedRecording && (
                    <Text mt="10px" fontSize={isSmallScreen ? "xs" : "sm"} color="#BEBEBE">Tap to record</Text>
                )}
            </Center>



        </VStack>
</>
            )}

  </VStack>
</>
        )}

</>
     
       
  
    )
}

export default PrayerSenderPopup