
import React, { useEffect, useState } from 'react'
import { VStack, Flex, Box, Text } from '@chakra-ui/react'

import NotificationsPrayerAudio from './NotificationsPrayerAudio'

import useNotificationPrayersList from './NotificationsData'

import { currentUserId } from '../Utilities/firebase'

import { getUserInfo } from '../pages/ProfilePage/Profile'

import { setNotificationsAsRead } from './NotificationsData'

const NotificationsList = ( {numberOfNotifications} ) => {


    const prayers = useNotificationPrayersList(currentUserId);

    const [userProfilePhoto, setUserProfilePhoto] = useState(null);




    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const userInfo = await getUserInfo(currentUserId);
                if (userInfo) {
                    setUserProfilePhoto(userInfo.userProfilePhoto);
                }

            } catch (error) {

            }
        };

        fetchUserInfo();
    }, [currentUserId]);


    useEffect(() => {
        const timer = setTimeout(async () => {
            await setNotificationsAsRead(currentUserId); // Replace yourUserData with actual user data
        }, 5000); // 5 seconds delay

        return () => clearTimeout(timer); // Cleanup timer on component unmount
    }, []); 

    return (


        <>
      {numberOfNotifications > 0 && (
<Box mb="2rem">
<Text textAlign="center" fontSize="14px">You received {numberOfNotifications} {numberOfNotifications === 1 ? "new prayer" : "new prayers"}</Text>
</Box>
    )}

            {prayers.map((prayer) => (

                <VStack  >
                    <Flex key={prayer.id} justifyContent="center"
                        _hover={{
                            bg: 'rgba(220, 220, 220, 0.1)', // Light gray with 10% opacity on hover
                            transform: "scale(1.022)",
                        }}
                        style={{
                            transition: "transform 0.1s",
                        }}>



                        <NotificationsPrayerAudio postAudio={prayer.postAudio}
                            postAudioDuration={prayer.postAudioDuration}
                            userProfilePhoto={prayer.profilePhoto}
                            name={prayer.name}
                            username={prayer.username}
                            postDate={prayer.postDate}
                            prayerRequestTitle={prayer.prayerRequestTitle}
                            selfProfilePhoto={userProfilePhoto}
                            postURL={prayer.postURL}
                            status={prayer.status}
                        />




                    </Flex>


                    <Flex mt="1px" justifyContent="center" width="100%">
                        <Box width={200} h="0.5px" bg="gray.300" mb="2rem" />
                    </Flex>

                </VStack>

            ))}


        </>


    )
}

export default NotificationsList