import React, { useEffect, useState } from 'react'
import { VStack, Flex, Box, Text } from '@chakra-ui/react'
import usePrayers from './PrayersUpload'

import { checkForPrayers } from './PrayersUpload'
import { getNumberOfPrayers } from './PostDataModel'
import PrayerAudio from './PrayerAudio'

const PostPrayersList = ({postURL, postCreatorName}) => {



    const prayers = usePrayers(postURL);


    const [numberOfPrayers, setNumberOfPrayers] = useState(0);

    useEffect(() => {
      const fetchNumberOfPrayers = async () => {
        const prayersCount = await getNumberOfPrayers(postURL);
        setNumberOfPrayers(prayersCount);
      };
  
      fetchNumberOfPrayers();
    }, [postURL]);



    const generateConsole = (prayer) => {
        console.log({
          postAudio: prayer.postAudio,
          postAudioDuration: prayer.postAudioDuration,
          postTimes_Listened: prayer.postTimes_Listened,
          creatorUID: prayer.uid,
          userProfilePhoto: prayer.profilePhoto,
          name: prayer.name,
          username: prayer.username,
          postDate: prayer.postDate,
        });
      };

  return (
   
     
<>


               {prayers.map((prayer) => (

<VStack  >
<Flex key={prayer.id} onClick={() => generateConsole(prayer)}  justifyContent="center" 
           _hover={{
            bg: 'rgba(220, 220, 220, 0.1)', // Light gray with 10% opacity on hover
            transform: "scale(1.022)",
          }}
          style={{
            transition: "transform 0.1s",
          }}>



<PrayerAudio      postAudio={prayer.postAudio}
                  postAudioDuration={prayer.postAudioDuration}
                  postTimes_Listened={prayer.postTimes_Listened}
                  creatorUID={prayer.uid}
                  userProfilePhoto={prayer.profilePhoto}
                  name={prayer.name}
                  username={prayer.username}
                   postDate={prayer.postDate}
                  />




                  </Flex>


                  <Flex mt="1px" justifyContent="center" width="100%">
                  <Box width={200} h="0.5px" bg="gray.300" mb="2rem" />
                </Flex>

                </VStack>

    ))}


  </>
  

  )
}

export default PostPrayersList