import { auth, userInformationRef, child, get } from '../../Utilities/firebase';
import { useEffect, useState } from 'react';


const useUserUsername = () => {
    const [userData, setUserData] = useState({});

    useEffect(() => {
        const fetchUserProfile = async () => {
            const unsubscribe = auth.onAuthStateChanged((currentUser) => {
                if (currentUser) {
                    const userUID = currentUser.uid;
                 

                    // Check if the user UID exists under userInformationRef
                    // Get the user's data from the database
                    get(child(userInformationRef, `${userUID}/username`))
                        .then((snapshot) => {
                            if (snapshot.exists()) {
                                // User data exists, extract the username
                                const username = snapshot.val();
                              //  console.log('Username:', username);
                                setUserData({ username: username });
                            } else {
                              //  console.log('No username available for this user');
                            }
                        })
                        .catch((error) => {
                            console.error('Error fetching user data:', error);
                        });
                } else {
                  //  console.log('User not logged in');
                }
            });

            return () => unsubscribe();
        };

        fetchUserProfile();
    }, []);

    return userData;
};

export default useUserUsername;