
import {  userInformationRef, userInformationPrayerSendRef, child, get, StoryPostsRef, ProfilesRef, update } from '../../Utilities/firebase';
import { useEffect, useState } from 'react';
import { useData } from '../../App';



export const UserProfile = () => {

  const [userData, setUserData] = useState({});
  const { profileURL } = useData();



  useEffect(() => {
      const fetchUserProfile = async () => {


          if (profileURL) {
            


    
            //  console.log('3. THE EXTRACTED Username:', profileURL);

             

// ----------------
get(ProfilesRef)
.then((snapshot) => {
  if (snapshot.exists()) {
snapshot.forEach((childSnapshot) => {
const url = childSnapshot.child('url').val();
if (url === profileURL) {
  const userUID = childSnapshot.child('uid').val();
 // console.log('Found matching URL. UID:', userUID);

                  // ----------------


              get(child(userInformationPrayerSendRef, `${userUID}`)) // Fetch the entire user object
                  .then((snapshot) => {
                      if (snapshot.exists()) {
                          // User data exists, extract the user object
                          const userData = snapshot.val();
                          const { bio, name, username, profilePhoto, uid } = userData; // Destructure the fields from the user object
                //          console.log('User Data:', userData);

                       

                                      // Pass the stories count string to setUserData
                                      setUserData({
                                          bio: bio, name: name, mUserUsername: username, profilePhoto: profilePhoto, mUser: uid
                                      });
                                




                      } else {
                 //         console.log('No data available for this user');
                      }
                  })
                  .catch((error) => {
                      console.error('Error fetching user data:', error);
                  });
    
              }
          });
        } else {
     //     console.log('ProfilesRef does not exist or is empty.');
        }
      })
      .catch((error) => {
        console.error('Error fetching ProfilesRef:', error);
      });
      

          } else {
       //       console.log('User not logged in');
          }
      };

      fetchUserProfile();
  }, []);

  return userData;
  
};




export const getUserInfo = async (usernameURL) => {
    try {
      // Await the get operation to retrieve the snapshot
      const snapshot = await get(ProfilesRef);
  

      if (snapshot.exists()) {
        let userUID = null;

        snapshot.forEach((childSnapshot) => {
          const url = childSnapshot.child('url').val();
          if (url === usernameURL) {
            userUID = childSnapshot.child('uid').val();
          }
        });
  
        if (userUID) {
          const userInfoSnapshot = await get(child(userInformationRef, `${userUID}`));
          
          // Check if the snapshot exists
          if (userInfoSnapshot.exists()) {
            // Extract values from the snapshot
            const userName = userInfoSnapshot.child('name').val();
            const userProfilePhoto = userInfoSnapshot.child('profilePhoto').val();
            const userUsername = userInfoSnapshot.child('username').val();
            const userUID = userInfoSnapshot.child('uid').val();
            const userBio = userInfoSnapshot.child('bio').val();
            
            // Return the extracted values
            return {
              userName,
              userProfilePhoto,
              userUsername,
              userUID,
              userBio,
            };
          } else {
            console.log("No data available");
            return null; // or handle no data case as needed
          }
        } else {
          console.log("User UID not found for the given URL");
          return null;
        }
      } else {
        console.log('ProfilesRef does not exist or is empty.');
        return null;
      }
    } catch (error) {
      console.error("Error getting user information:", error);
      throw error; // or handle the error as needed
    }
  };
  




  export const checkIfUserExists = async (uid) => {
    try {
        const userInfoSnapshot = await get(child(userInformationRef, `${uid}`));
          
        // Check if the snapshot exists
        if (userInfoSnapshot.exists()) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error("Error checking user existence:", error);
        return false;
    }
};



const getUserByUsername = (profileURL) => {
  return new Promise((resolve, reject) => {
      get(userInformationPrayerSendRef)
          .then((snapshot) => {
              if (snapshot.exists()) {
                  let userExists = false;
                  snapshot.forEach((childSnapshot) => {
                      if (childSnapshot.child('username').val() === profileURL) {
                          userExists = true;
                      }
                  });
                  resolve(userExists);
              } else {
                  resolve(false); // User not found if snapshot doesn't exist
              }
          })
          .catch((error) => {
              console.error('Error fetching user data:', error);
              reject(error);
          });
  });
};

export default getUserByUsername;