import React from 'react';
import { css, keyframes } from '@emotion/react'; // Import css and keyframes from @emotion/react
import { PiHandsPrayingFill } from 'react-icons/pi';




const PrayingHandsGlow = ({isPlaying, status}) => {
  return (
<>
{isPlaying ? (   
<div className="glowing-effect" >
      <PiHandsPrayingFill className="praying-hands-icon" size={20} color="white" />
    </div>
     ) :   ( 
<>
      {status === "unread" ? (
        <>
        <PiHandsPrayingFill className="praying-hands-icon" size={20} color="#FFCC00" />
</>
      ) : (
        <>
      <PiHandsPrayingFill className="praying-hands-icon" size={20} color="#64a4d3" />
      </>
      )}
</>
   
       )}

</>
  
  );
};
/// #
export default PrayingHandsGlow;

