import React, { useState, useRef, useEffect } from 'react';
import { FaPlay, FaPause } from "react-icons/fa";
import { useBreakpointValue } from '@chakra-ui/react'
import './replayseekbar.css'

const ReplayAudio = ({ postAudio, postAudioDuration }) => {
    const [isPlaying, setIsPlaying] = useState(false); // Change initial state to false
    const [currentTime, setCurrentTime] = useState(0);
    const audioRef = useRef(null);
    const isSmallScreen = useBreakpointValue({ base: true, md: false });

    useEffect(() => {
        const audioElement = audioRef.current;
    
        // Start playing audio only when isPlaying state is true
        if (isPlaying) {
            audioElement.play();
        } else {
            audioElement.pause();
        }
    
        // Cleanup function to pause audio when the component unmounts
        return () => {
            audioElement.pause();
        };
    }, [isPlaying, postAudio]);
    


    
    // Add this useEffect to handle the end of audio playback
    useEffect(() => {
        const audioElement = audioRef.current;
    
        const handleEnded = () => {
            setIsPlaying(false); // Set isPlaying to false when playback ends
        };
    
        audioElement.addEventListener('ended', handleEnded);
    
        return () => {
            audioElement.removeEventListener('ended', handleEnded);
        };
    }, [postAudio]); 







    const togglePlay = () => {
        setIsPlaying(prevIsPlaying => !prevIsPlaying);
    };

    const handleTimeUpdate = () => {
        setCurrentTime(audioRef.current.currentTime);
    };

    const handleSeek = (e) => {
        const newTime = e.target.value;
        setCurrentTime(newTime);
        audioRef.current.currentTime = newTime;
    };

    return (
        <div className="post-audio">
            <audio
                ref={audioRef}
                src={postAudio}
                autoPlay={false} // Set autoPlay to false
                onTimeUpdate={handleTimeUpdate}
            />
            <div className="audio-controls">
                <button className="play-pause-button" onClick={togglePlay}>
                    {isPlaying ? <FaPause /> : <FaPlay />}
                </button>
                {!isSmallScreen && (
                    <div className="seek-bar-container">
                        <input
                            type="range"
                            className="seek-bar"
                            min="0"
                            max={postAudioDuration}
                            value={currentTime}
                            onChange={handleSeek}
                            style={{ '--thumb-position': `${(currentTime / postAudioDuration) * 100}%` }}
                        />
                    </div>
                )}
                <div className="time-display">
                    {isPlaying ? (
                        <span className="current-time">{formatTime(currentTime)}</span>
                    ) : (
                        <span className="total-time">{formatTime(postAudioDuration)}</span>
                    )}
                </div>
            </div>
        </div>
    );
};


const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

export default ReplayAudio;