import React from 'react'
import { BlockedUsersRef, set, get, child, remove } from "../Utilities/firebase";
import { currentUserId } from '../Utilities/firebase';

const BlockUser = async (blockedUserId) => {

    set(child(BlockedUsersRef, `${currentUserId}/${blockedUserId}`), {
        uid: blockedUserId,
        userId: currentUserId,
        // date Added (iOS + Android + Web)
      });


      // Remove from friends list
      // Remove from unfollow each other

 
}

export default BlockUser



export const checkBlockUser = async (mUser) => {

    try {
        const snapshot = await get(child(BlockedUsersRef, currentUserId));
    //    console.log("1. CTesty inside Database TRUE", currentUserId)

        if (snapshot.exists()) {
       //     console.log("2. CTesty inside Database TRUE")

            let isBlocked = false;
            snapshot.forEach((childSnapshot) => {
         //       console.log("3. CTesty inside Database TRUE")

                // Check if the post contains the user's UID
                if (childSnapshot.child('uid').val() === mUser) {
                    isBlocked = true;
             //       console.log("4. CTesty inside Database TRUE")
                }
            });
            return isBlocked;
        } else {
            return false;
            ("1. CTesty inside Database False")
        }
    } catch (error) {
        console.error("Error checking blocked user:", error);
        ("1. CTesty inside Database False w/ ERROR ")

        return false; // Return false in case of any error
    }
};





export const checkIfBlockedByUser = async (mUser) => {

    try {
        const snapshot = await get(child(BlockedUsersRef, mUser));
    //    console.log("1. WTesty inside Database TRUE", mUser)

        if (snapshot.exists()) {
       //     console.log("2. WTesty inside Database TRUE")

            let isBlocked = false;
            snapshot.forEach((childSnapshot) => {
          //      console.log("3. WTesty inside Database TRUE")

                // Check if the post contains the user's UID
            //    console.log("3.5. WTesty VALUES BLOCKED  ", childSnapshot.child('uid').val())

                if (childSnapshot.child('uid').val() === currentUserId) {
                    isBlocked = true;
              //      console.log("4. WTesty you are BLOCKED  !!!!! by", mUser)
                }
            });

         //   console.log(" FINAL -> WTesty Are you blocked?:)", isBlocked)
            return isBlocked;
    
        } else {
        //    console.log(" FINAL -> WTesty  NOT blocked by", mUser)

            return false;
        }
    } catch (error) {
      //  console.error("Error checking blocked user:", error);
     //   console.log("1. Wtesty inside Database False w/ ERROR ")

        return false; // Return false in case of any error
    }
};




export const unblockUser = async (mUser) => {
    await remove(child(BlockedUsersRef, `${currentUserId}/${mUser}`));
};