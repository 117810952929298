import React, { useState, useEffect } from "react";
import { IoPersonCircleOutline, IoPersonOutline } from "react-icons/io5";
import { GiAngelWings } from "react-icons/gi";
import { Flex, Box, Center, VStack, Image, Text } from "@chakra-ui/react"; // Assuming you're using Chakra UI for styling
import { getAuth } from "firebase/auth";
import { css, keyframes } from "@emotion/react";
import { useData } from "../App";

// Function to generate coordinates for circular arrangement with spacing
function generateCoordinatesWithSpacing(radius, count, spacing) {
    const coordinates = [];
    const angleIncrement = (2 * Math.PI) / count;
    for (let i = 0; i < count; i++) {
        const angle = i * angleIncrement;
        const x = (radius + spacing) * Math.cos(angle);
        const y = (radius + spacing) * Math.sin(angle);
        coordinates.push({ x, y });
    }
    return coordinates;

}

// Function to create a circle of IoPerson icons with spacing
function CircleOfPeopleWithSpacing({ count, size = "24px", color = "gray", radius = 50, spacing = 10 }) {
    const coordinates = generateCoordinatesWithSpacing(radius, count - 1, spacing); // Reduce count by 1 to accommodate CircleProfile



    return (
        <Flex position="relative" width={`${(radius + spacing) * 2}px`} height={`${(radius + spacing) * 2}px`} justifyContent="center" alignItems="center">
        {coordinates.map((coord, index) => (
          <div key={index} style={{ position: "absolute", top: `${radius + coord.y}px`, left: `${radius + coord.x}px` }}>
            {/* Apply the glow effect using a pseudo-element */}
            <div className="glow-effect">
              <GiAngelWings size={size} color="white" />
            </div>
          </div>
        ))}
        <Center position="absolute" top="45%" left="45%" transform="translate(-50%, -50%)">
          <div className="glow-effect">
            <GodCircle size={size} color="white" />
          </div>
        </Center>
      </Flex>
    );
}


// Profile circle component
function GodCircle({ size = "24px", color = "black" }) {
    const auth = getAuth();
    const user = auth.currentUser;
    const pulse = keyframes`
    0%, 100% { transform: scale(0.4); }
  50% { transform: scale(2); }
`;

  return (
    <Box
      w="2"
      h="2"
      borderRadius="50%"
      bg="white"
      boxShadow="0 0 5px 2px rgba(252, 242, 192)"
      css={css`
        animation: ${pulse} 37s infinite;
      `}
    />
  );
}


// Main component
function AngelAnimation() {
    const [circleCount, setCircleCount] = useState(4); // Initial circle count
    const [loopIndex, setLoopIndex] = useState(0);
    

    useEffect(() => {
        const intervalId = setInterval(() => {
            setLoopIndex((prevIndex) => (prevIndex + 1) % 4); // Increment loop index and loop after 4 iterations

            // Set circle count based on loop index
            switch (loopIndex) {
                case 0:
                    setCircleCount(6);
                    break;
                case 1:
                    setCircleCount(9);
                    break;
                case 2:
                    setCircleCount(8);
                    break;
                case 3:
                    setCircleCount(6);
                    break;
                case 4:
                    setCircleCount(4);
                    break;
                default:
                    break;
            }
        }, 5000);

        return () => clearInterval(intervalId); // Cleanup interval
    }, [loopIndex]);

    return (
        <Flex justifyContent="center" alignItems="center" flexDirection="column" mt={8}>
            <CircleOfPeopleWithSpacing count={circleCount} spacing={20} />
        </Flex>
    );
}

export default AngelAnimation;