
    import { useState, useEffect } from 'react';
    import { PrayerRequestsRef,  userInformationRef, userInformationPrayerSendRef, get, child, } from '../../Utilities/firebase';
    import { formatDate, goFormatDate } from '../../Utilities/dateUtils';

    import { getNumberOfPrayers } from '../../PostPage/PostDataModel';
    import { useData } from '../../App';
    const ProfilePrayerRequestsUpload = (uid) => {

          
      //  const {  setIsFetchingPrayerRequests, setNoPrayerRequestsFound } = useData();
        const [prayerRequests, setPrayerRequests] = useState([]);
    
    
                    get(PrayerRequestsRef)
                        .then((snapshot) => {
                            if (snapshot.exists()) {
                            
                                const PrayerRequestsData = [];
                                snapshot.forEach((childSnapshot) => {
                                    // Check if the post contains the user's UIDi
    
    
    
                                    if (childSnapshot.child('uid').val() === uid) {
                                        const postDate = childSnapshot.child('postDate').val();
                                        const formattedDate = formatDate(postDate);
                                        const bgFormattedDate = goFormatDate(postDate);
                                        const creatorUID = childSnapshot.child('uid').val();
                                        // Extract data from each PrayerRequest
    
                                      
                                        const PrayerRequestData = {
                                            postId: childSnapshot.key, // Assuming postId is the unique identifier
                                            postAudio: childSnapshot.child('postAudio').val(),
                                            postAudioDuration: childSnapshot.child('postAudioDuration').val(),
                                            postAccess: childSnapshot.child('postAccess').val(),
                                            postCaption: childSnapshot.child('postCaption').val(),
                                            postCountry: childSnapshot.child('postCountry').val(),
                                            postDate: formattedDate,
                                            postLanguage: childSnapshot.child('postLanguage').val(),
                                            prayers: childSnapshot.child('prayers').val(),
                                            times_listened: childSnapshot.child('times_listened').val(),
                                            uid: childSnapshot.child('uid').val(),
                                            url: childSnapshot.child('url').val(),
                                            rawTimeStamp: childSnapshot.child('postDate').val(),
                                      
                                           };
                                        PrayerRequestsData.push({ PrayerRequestData, bgFormattedDate, creatorUID });
    
                                   
    
    
                                    } else {
                                        //setIsFetchingPrayerRequests(false);
                                       // setNoPrayerRequestsFound(true);
                                    }
    
    
    
                                });
    
    
                                // Once PrayerRequestsData is populated with initial data, fetch additional user data
                                // Once PrayerRequestsData is populated with initial data, fetch additional user data
                                Promise.all(PrayerRequestsData.map(async ({ PrayerRequestData }, index) => {
                                    const [prayers, userSnapshot] = await Promise.all([
                                        getNumberOfPrayers(PrayerRequestData.url),
                                        get(child(userInformationRef, PrayerRequestData.uid))
                                    ]);
    
                                    if (userSnapshot.exists()) {
                                        const userData = userSnapshot.val();
                                        const { name, username, profilePhoto } = userData;
                                                // Include additional user data in corresponding PrayerRequestData
                                                const PrayerRequestData = {
                                                    postId: PrayerRequestsData[index].PrayerRequestData.postId,
                                                    postAudio: PrayerRequestsData[index].PrayerRequestData.postAudio,
                                                    postAudioDuration: PrayerRequestsData[index].PrayerRequestData.postAudioDuration,
                                                    postAccess: PrayerRequestsData[index].PrayerRequestData.postAccess,
                                                    postCaption: PrayerRequestsData[index].PrayerRequestData.postCaption,
                                                    postCountry: PrayerRequestsData[index].PrayerRequestData.postCountry,
                                                    postDate: PrayerRequestsData[index].PrayerRequestData.postDate,
                                                    postLanguage: PrayerRequestsData[index].PrayerRequestData.postLanguage,
                                                    prayers: prayers,
                                                    times_listened: PrayerRequestsData[index].PrayerRequestData.times_listened,
                                                    uid: PrayerRequestsData[index].PrayerRequestData.uid,
                                                    url: PrayerRequestsData[index].PrayerRequestData.url,
                                                    name: name,
                                                    username: username,
                                                    profilePhoto: profilePhoto,
                                                    rawTimeStamp: PrayerRequestsData[index].PrayerRequestData.rawTimeStamp,
                                                };
    
    
    
    
                                                PrayerRequestsData[index].PrayerRequestData = PrayerRequestData;
                                          } else {
                                   //           console.error(`User data does not exist for UID: ${creatorUID}`);
                                          }
                                        }))
                                        .catch((error) => {
                                            console.error('Error fetching user data:', error);
                                      
                                        })
    
    
                                
    
                                
                                    .then(() => {
                                        // Sort the PrayerRequestsData array based on bgFormattedDate
                                        PrayerRequestsData.sort((a, b) => {
                                            if (a.bgFormattedDate > b.bgFormattedDate) return -1;
                                            if (a.bgFormattedDate < b.bgFormattedDate) return 1;
                                            return 0;
                                        });
    
                                        // Extract the sorted PrayerRequestData objects from PrayerRequestsData
                                        const sortedPrayerRequests = PrayerRequestsData.map(({ PrayerRequestData }) => PrayerRequestData);
    
                                        // Set the sorted PrayerRequests in the state
    
                                
                                        setPrayerRequests(sortedPrayerRequests);
                                    
                                     //   setIsFetchingPrayerRequests(false);
                                      //  setNoPrayerRequestsFound(false);
                                
    
                                    })
                                    .catch((error) => {
                                        console.error('Error processing PrayerRequest data:', error);
                         
                                 
                                    });
    
    
    
    
                            }  else {
                              //  setIsFetchingPrayerRequests(false);
                              //  setNoPrayerRequestsFound(true);
                            }
    
                        })
                        .catch((error) => {
                            console.error('Error fetching PrayerRequests:', error);
    
                   
                        });
              
        return prayerRequests;
    };
    
    
    
export default ProfilePrayerRequestsUpload;