import React, { useState, useEffect } from 'react';
import { Center, Box, Button, Flex, HStack, Text, Spacer, VStack, Input, useBreakpointValue } from '@chakra-ui/react'
import { MdClose } from "react-icons/md";
import { AiFillAudio } from "react-icons/ai";
import { IoArrowForward } from "react-icons/io5";
import useAudioRecording from '../Utilities/recordAudioUtils';
import { FaPlay, FaPause } from "react-icons/fa";
import { CgRedo } from "react-icons/cg";
import { FaArrowCircleRight } from "react-icons/fa";
import PublisherSettings from '../PostCreation/PublisherSettings';
import { getAuth } from 'firebase/auth';

import PrayingHandsGlow from '../PrayingHandsGlow';

import { validateStoryTitleContent, validateStoryTitleLength } from '../PostCreation/CreatePostDataModel';

import ReplayAudio from '../PostCreation/ReplayAudio';

import { useData } from '../App';

import AngelAnimation from '../PostCreation/AngelAnimation';

import { usePageTitle } from '../Utilities/PageTitles';

const WelcomePage = () => {

    usePageTitle('Send your prayer requests to God');


    const auth = getAuth();
    const user = auth.currentUser;

    const { saveRecordingData, createPostBannerTitle, showPublisherSettings, setShowPublisherSettings, setStoryJustPublished } = useData();

    const { startAudioRecording, stopAudioRecording, errorMessage, setErrorMessage, audioURL } = useAudioRecording();
    const [isPermissionGranted, setIsPermissionGranted] = useState(false);
    const [audioBlob, setAudioBlob] = useState(null); // State to store the recorded audio blob
    const [isRecording, setIsRecording] = useState(false);
    const [hasFinishedRecording, setHasFinishedRecording] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [storyTitle, setStoryTitle] = useState('');
    const [storyTitleError, setStoryTitleError] = useState('');
    const [recordingTime, setRecordingTime] = useState(0);
    const [isHovered, setIsHovered] = useState(false);
    const [progressWidth, setProgressWidth] = useState(0); // State to manage progress bar width
    const [errorInRecording, setErrorInRecording] = useState(false);
    const [redoStoryMessage, setRedoStoryMessage] = useState(false);



    const handleHover = (color) => {
        document.querySelectorAll('.hoverable').forEach(element => {
            element.style.color = color;
        });
    };

    const isSmallScreen = useBreakpointValue({ base: true, md: false });





    const cancelRecording = () => {
        stopAudioRecording();
        setIsRecording(false);
        setHasFinishedRecording(false);
        resetRecording();
        setAudioBlob('');

        // for pages only
        window.scrollTo(0, 0);

        finalCheckAndHandleMicrophonePermission();
    };

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const startRecording = () => {

        setIsRecording(true);
        startAudioRecording();

        setErrorMessage("");
        setRedoStoryMessage("");

    };




    const finishRecording = () => {


        // AUDIO MUST GO IN HERE 

        stopAudioRecording();
        setIsRecording(false);
        setHasFinishedRecording(true);
        setProgressWidth(100);

        if (errorMessage) {
            setErrorMessage("Audio failed! Please grant permission to record audio before recording your prayer request.");

        }

    };

    const resetToRecord = () => {
        setHasFinishedRecording(false);
        resetRecording();
        setAudioBlob('');
        setProgressWidth(0);

        // for pages only
        window.scrollTo(0, 0);

        if (!isPermissionGranted) {
            setErrorMessage("Your prayer request was not fully recorded, because you didn't grant permission. Please grant permission to record audio before recording your prayer request.");

        }
    };

    // Add this condition to call finishRecording at 5 minutes
    const maxDuration = 300
    const redTimeTigger = 290
    const yellowTimeTigger = 270


    // FOR TESTING ONLY
    //const maxDuration = 30
    // const redTimeTigger = 25
    // const yellowTimeTigger = 20


    useEffect(() => {
        let timer;
        if (isRecording && !hasFinishedRecording) {
            timer = setInterval(() => {
                setRecordingTime(prevTime => prevTime + 1);
            }, 1000);
        } else {
            clearInterval(timer);
        }



        if (hasFinishedRecording) {
            setProgressWidth(100); // Set progressWidth to 100 if recording has finished
        } else {
            const newWidth = (recordingTime / maxDuration) * 100;
            setProgressWidth(newWidth);
        }

        if (recordingTime >= maxDuration) {
            finishRecording();
        }

        return () => clearInterval(timer);
    }, [isRecording, hasFinishedRecording, recordingTime]);


    useEffect(() => {
        if (audioURL) {
            setAudioBlob(audioURL);

            //     console.log("2.Audio FILE: " + audioURL)

            //     console.log("3.Audio FILE: " + audioURL)

        }
    }, [audioURL]);


    const resetRecording = () => {
        setRecordingTime(0);
    };

    const onShowPublisherSettings = () => {
        setStoryJustPublished(false);
        setShowPublisherSettings(true);


        window.scrollTo(0, 0);


        // Temprarily False (these two)
        setHasFinishedRecording(false);
        resetRecording();
    };


    // Format the time in MM:SS format
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };



    useEffect(() => {
        let timer;
        if (isRecording && !hasFinishedRecording) {
            timer = setInterval(() => {
                setRecordingTime(prevTime => prevTime + 1);
            }, 1000);
        } else {
            clearInterval(timer);
        }

        return () => clearInterval(timer);
    }, [isRecording, hasFinishedRecording]);

    useEffect(() => {
    window.scrollTo(0, 0);
}, []);

    useEffect(() => {


        // Check if navigator.permissions and its methods are supported
        if (navigator.permissions && navigator.permissions.query) {
            navigator.permissions.query({ name: 'microphone' }).then(permissionStatus => {
                if (permissionStatus.state === 'denied') {
                    // Microphone permission denied, set error message
                    cancelRecording();
                    setErrorMessage('Error accessing microphone. Please grant permission to record audio in your browser settings.');
                    setErrorInRecording("Your prayer request was not fully recorded. Please grant permission to record audio in your browser settings.");

                } else {
                    // Microphone permission granted or prompt required
                    // Attach event listener for permission changes
                    navigator.permissions.addEventListener('change', checkAndHandleMicrophonePermission);
                }

            });
        }

        return () => {
            if (navigator.permissions && navigator.permissions.removeEventListener) {
                navigator.permissions.removeEventListener('change', checkAndHandleMicrophonePermission);
            }
        };
    }, []);

    const checkMicrophonePermission = async () => {
        try {
            const permissionStatus = await navigator.permissions.query({ name: 'microphone' });
            return permissionStatus.state === 'granted';


        } catch (error) {
            console.error('Error checking microphone permission:', error);
            return false; // Return false if there's an error
        }
    };

    // Check microphone permission and handle the result
    const checkAndHandleMicrophonePermission = async () => {

        const isMicrophoneGranted = await checkMicrophonePermission();
        setIsPermissionGranted(isMicrophoneGranted);

        if (isMicrophoneGranted && errorMessage) {
            cancelRecording();
            setIsPermissionGranted(true);
        }

    };

    // Check microphone permission and handle the result
    const finalCheckAndHandleMicrophonePermission = async () => {
        const isMicrophoneGranted = await checkMicrophonePermission();
        setIsPermissionGranted(isMicrophoneGranted);

        if (isMicrophoneGranted && errorMessage) {

            setIsPermissionGranted(true);
            setErrorInRecording('');
            setRedoStoryMessage('');
            setErrorMessage('');
        }
    };

    useEffect(() => {
        if (isRecording && !isPermissionGranted) {
            setErrorMessage("Your prayer request is not being recorded. Please grant permission to record audio in your browser settings.");
            setErrorInRecording("Your prayer request was not fully recorded. Please grant permission to record audio in your browser settings.");
            setRedoStoryMessage("Your prayer request is not recording. Please restart!")
        }
    }, [isRecording, isPermissionGranted]);

    const proceedToNextPage = async () => {
        await checkAndHandleMicrophonePermission();

        if (!isPermissionGranted && errorInRecording) {
            setErrorMessage("Your prayer request was not fully recorded. Please grant permission to record audio in your browser settings.");
            return;
        }

        if (!storyTitle.trim()) {
            setStoryTitleError("Your prayer request must have a title!");
            return;
        }

        if (storyTitle.length < 3) {
            setStoryTitleError("Title too short!");
            return;
        }

        if (!validateStoryTitleLength(storyTitle)) {
            setStoryTitleError("Title too long! Your title must be at least 70 characters!");
            return;
        }

        if (!validateStoryTitleContent(storyTitle)) {
            setStoryTitleError("This content may violate our content policies!");
            return;
        }



        setStoryTitleError('');

        let timeDur = formatTime(recordingTime);
        saveRecordingData(audioBlob, timeDur, storyTitle);
        // goToNextPresentation


        onShowPublisherSettings();



        //   console.log("WE WILL PROCEED TO NEXT PAGE")

        //   console.log("TITLE OF STORY: " + storyTitle)

    };



    const storyPrompts = [


        { title: "Send a prayer request for a family member" },
        { title: "Send a personal prayer request" },
        { title: "Send a prayer request for a family friend" },
        { title: "Send a prayer request for your neighbor" },
        { title: "Send a prayer request for someone you know" },
        { title: "Send a prayer request for someone you just met" },
        { title: "Say whatever is on your heart" },
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setSelectedIndex((selectedIndex + 1) % storyPrompts.length);
        }, 3000);

        return () => clearInterval(interval);
    }, [selectedIndex]);



    // Calculate the indexes of the story prompts to be displayed
    const firstIndex = (selectedIndex - 1 + storyPrompts.length) % storyPrompts.length;
    const secondIndex = selectedIndex;
    const thirdIndex = (selectedIndex + 1) % storyPrompts.length;


    return (
        <VStack mt={!isSmallScreen ?  (user? "8rem" : "4rem") : "4rem"} maxWidth="400px" mx="auto" alignItems="center" justifyContent="center">

            {showPublisherSettings ? (
              
                <PublisherSettings/>
                
            ) : (
                <>


                    <VStack>

                    
                        <Text as="i" textAlign="center">"Let your request be made known unto God"</Text>


                        <Text as="b" mt="1rem" mb="1rem">Send a prayer request</Text>


                        {errorMessage && <Text mb="2rem" fontSize={isSmallScreen ? "14px" : "18px"} textAlign="center" color="red.500">{errorMessage}</Text>}

                     


                        {!errorMessage && !redoStoryMessage && (
                                <Box mb="2rem">


                                    <AngelAnimation />

                                </Box>

                            )}


                        <Center h="100vh" flexDirection="column"
                        >



                            {/* Background */}
                            <Box
                                position="absolute"
                                mb={{ base: "80px", sm: "80px", md: "77px", lg: "75px" }}
                                bottom={isSmallScreen ? "89%" : "88%"}
                                left="50%"
                                transform="translate(-50%, -50%)"
                                width="100%" // Set width to cover the entire screen
                                height="2px" // Set the same height as the progress bar
                                background="gray" // Set the background color here
                            >
                                {/* Progress bar */}
                                <Box
                                    position="absolute"
                                    top="0"
                                    left="0"
                                    width={`${progressWidth}%`}
                                    height="100%"
                                    background={progressWidth < 100 ? "#07BEFF" : "#39FF14"}
                                    zIndex="1" // Set a higher z-index for the progress bar
                                />




                            </Box>

                          

                            <div className="icon-container" style={{ marginBottom: '37rem' }}> {/* Adjust margin as needed */}

                           

                                {!isRecording && !hasFinishedRecording && (
                                    <Box mb="5">
                                        <Text textAlign="center" fontSize="xs" color="gray">
                                            {storyPrompts[firstIndex].title}
                                        </Text>
                                        <Text textAlign="center" fontSize="xs" fontWeight="bold" color="#373635">
                                            {storyPrompts[secondIndex].title}
                                        </Text>
                                        <Text textAlign="center" fontSize="xs" color="gray">
                                            {storyPrompts[thirdIndex].title}
                                        </Text>
                                    </Box>

                                )}

                                {isRecording && redoStoryMessage && !hasFinishedRecording && (

                                    <>
                                        <Text fontSize={isSmallScreen ? "10px" : "14px"} textAlign="center" pt="0px" color="red.500" mt="-4rem" mb="10px">{redoStoryMessage}</Text>

                                        <Button
                                           
                                            variant='outline'
                                            size="md"
                                            color="#64a4d3"
                                            colorScheme='#64a4d3'
                                            alignItems="center"
                                            leftIcon={<CgRedo color="#64a4d3" />}
                                            cursor="pointer"
                                            _hover={{ bg: "gray.100" }} // Change background color on hover
                                            borderRadius="md" // Apply border radius to mimic button style
                                            p="2" // Add padding for better click area
                                            onClick={cancelRecording}
                                            mb="10px"
                                          
                                        >Restart


                                        </Button>
                                       
                                    </>
                                )}

                                {hasFinishedRecording ? (
                                    <Box alignItems="center" flexDirection='column' display="flex">
                                        <Text textAlign="center" mb="3px" fontSize='lg' color="#2DC42A ">{formatTime(recordingTime)}</Text>
                                        <Text textAlign="center" mb="10px" fontSize='sm' color="#2DC42A ">COMPLETED</Text>


                                        <div className="circle-finish">
                                            <AiFillAudio className="audio-icon-finish" />
                                        </div>


                                        <Box pt="5" display="flex" alignItems="center" justifyContent="center" gap={5}>

                                            {hasFinishedRecording && (
                                                <>
                                                    <Flex
                                                        as="button"
                                                        variant='outline'
                                                        size={isSmallScreen ? "sm" : "sm"}
                                                        alignItems="center"
                                                        cursor="pointer"
                                                        _hover={{ bg: "gray.100" }} // Change background color on hover
                                                        borderRadius="md" // Apply border radius to mimic button style
                                                        p="2" // Add padding for better click area
                                                        onClick={resetToRecord}
                                                    >

                                                        <CgRedo size={isSmallScreen ? "14px" : "18px"} color="gray" />
                                                        <Text ml="2" fontSize={isSmallScreen ? "xs" : "sm"} color="gray">Redo</Text>


                                                    </Flex>


                                                    <Button
                                                        variant='outline'
                                                        color="#64a4d3"
                                                        colorScheme='#64a4d3'
                                                        size={isSmallScreen ? "sm" : "sm"}
                                                        alignItems="center"
                                                        rightIcon={<FaArrowCircleRight color="#64a4d3" />}
                                                        cursor="pointer"
                                                        _hover={{ bg: "gray.100" }} // Change background color on hover
                                                        borderRadius="md" // Apply border radius to mimic button style
                                                        p="2" // Add padding for better click area
                                                        onClick={proceedToNextPage}>Next
                                                  


                                                    </Button>

                                                </>


                                            )}


                                        </Box>

                                        <ReplayAudio
                                            postAudio={audioBlob}
                                            postAudioDuration={recordingTime}
                                        />

                                        <Box pt="5px" paddingX={{ base: "10px", md: "20px" }}>
                                            <Input
                                                variant="unstyled"
                                                color="black"
                                                _placeholder={{
                                                    color: "gray.400",
                                                }}
                                                placeholder="Add a title..."
                                                maxLength={90}
                                                width={{ base: "100%", md: "300px" }}
                                                minWidth="auto"
                                                value={storyTitle}
                                                fontSize={isSmallScreen ? "sm" : "md"}
                                                onChange={(e) => setStoryTitle(e.target.value)} />



                                            <Box justifyContent="center" width={{ base: "100%", md: "300px" }} minWidth="auto" h="1px" bg="gray.300" mb="1" margin="auto" />


                                            {storyTitleError && <Text fontSize={isSmallScreen ? "10px" : "12px"} color="red.500">{storyTitleError}</Text>}

                                        </Box>

                                    </Box>


                                ) : (
                                    <>
                                        <Flex flexDirection="column" alignItems="center" justifyContent="center">
                                            {!isRecording ? (
                                                <div className="circle">
                                                    <AiFillAudio className="audio-icon" onClick={startRecording} />
                                                </div>
                                            ) : (
                                                <>
                                                    <Box pb="4">
                                                        <Text
                                                            color={recordingTime >= yellowTimeTigger && recordingTime < redTimeTigger ? "#FFD501" : recordingTime >= redTimeTigger ? "red" : "black"}
                                                            fontSize={{ base: '14px', sm: '16px', md: '18px' }}
                                                            className={recordingTime >= yellowTimeTigger && recordingTime < redTimeTigger ? "pulse-animation" : recordingTime >= redTimeTigger ? "pulse-animation" : ""}
                                                        >
                                                            {formatTime(recordingTime)}
                                                        </Text>
                                                    </Box>


                                                    <div className="circle-rec">
                                                        <AiFillAudio className={isRecording ? "audio-icon-rec fade-in-out" : "audio-icon-rec"} onClick={finishRecording} />
                                                    </div>

                                                </>

                                            )}
                                        </Flex>
                                    </>
                                )}
                                <Center>

                                    {!isRecording && !hasFinishedRecording && (
                                        <Text mt="10px" fontSize={isSmallScreen ? "xs" : "sm"} color="#BEBEBE">Tap to record</Text>
                                    )}
                                </Center>
                            </div>



                        </Center>




                    </VStack>

                </>

            )}

        </VStack>
    )
}

export default WelcomePage