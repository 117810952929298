import React, { useState } from 'react';
import { Button, SimpleGrid, Box, Spacer, Text, Center } from '@chakra-ui/react'; // Import Chakra UI components

const LanguageSelector = ({ onSelectLanguage }) => {
    const [selectedLanguage, setSelectedLanguage] = useState(null);

    const languages = [
      { name: 'English', code: 'en', langName: 'English' },
      { name: 'Español', code: 'es', langName: 'Spanish' },
      { name: 'Français', code: 'fr', langName: 'French' },
      { name: 'Deutsch', code: 'de', langName: 'German' },
      { name: 'Polski', code: 'pl', langName: 'Polish' },
      { name: 'Русский', code: 'ru', langName: 'Russian' },
      { name: 'Português', code: 'pt', langName: 'Portuguese' },
      { name: 'Italiano', code: 'it', langName: 'Italian' },
      { name: 'Nederlands', code: 'nl', langName: 'Dutch' },
      { name: 'Slovenčina', code: 'sk', langName: 'Slovak' },
      { name: '中文', code: 'zh', langName: 'Chinese' },
      { name: '한국어', code: 'ko', langName: 'Korean' },
      { name: '日本語', code: 'ja', langName: 'Japanese' },
      { name: 'Čeština', code: 'cs', langName: 'Czech' },
      { name: 'Українська', code: 'uk', langName: 'Ukrainian' },
      { name: 'Norsk', code: 'no', langName: 'Norwegian' },
      { name: 'Svenska', code: 'sv', langName: 'Swedish' },
      { name: 'Dansk', code: 'da', langName: 'Danish' },
      { name: 'Suomi', code: 'fi', langName: 'Finnish' },
      { name: 'العربية', code: 'ar', langName: 'Arabic' }
  ];


  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
    onSelectLanguage(language);
  };

  return (
    <Box pt="40px"> {/* Add top padding here */}
     <Center pt="10px">
  <Text as='b' fontSize={{ base: '14px', sm: '15px', md: '16px' }} >Select the language of your prayer request</Text>
</Center>
      <SimpleGrid pt="15px" columns={{ base: 3, sm: 3, md: 4 }} spacing={4}>
        {languages.map((language, index) => (
          <Button
            key={index}
            variant="outline"
            onClick={() => handleLanguageSelect(language)}
            isActive={selectedLanguage === language}
            textAlign="left"
            fontSize={{ base: '12px', sm: '13px', md: '14px' }}
          >
            {language.name}
          </Button>
        ))}
      </SimpleGrid>
      <Spacer />
    </Box>
  );
};

export default LanguageSelector;