import React from 'react';
import { Text, VStack, HStack, Link } from '@chakra-ui/react';

const NotFound = () => {
    return (
        <VStack mt="8rem" gap={5}>
            <Text textAlign="center" fontSize="24px" fontWeight="bold" mx="2rem">Sorry, this page does not exist.</Text>
            <Text textAlign="center" fontSize="14px" mx="2rem">The page you are searching for may be broken or may have been removed.</Text>
            <HStack justifyContent="center">
                <Link color="blue.500" href="/requests">Go back to PrayerSend</Link>
            </HStack>
        </VStack>
    );
}

export default NotFound;