

import { auth, userInformationRef, userInformationPrayerSendRef, child, get, } from '../../Utilities/firebase';
import { useEffect, useState } from 'react';
import { currentUserId } from '../../Utilities/firebase';
import { useData } from '../../App';



const useUserProfile = () => {
    const [userData, setUserData] = useState({});



    useEffect(() => {
        const fetchUserProfile = async () => {
            const unsubscribe = auth.onAuthStateChanged((currentUser) => {
                if (currentUser) {
                    const userUID = currentUser.uid;
                   // console.log('THIS IS UID', userUID);

                    // Check if the user UID exists under userInformationRef
                    // Get the user's data from the database


                    get(child(userInformationPrayerSendRef, `${userUID}`)) // Fetch the entire user object
                        .then((snapshot) => {
                            if (snapshot.exists()) {
                                // User data exists, extract the user object
                                const userData = snapshot.val();
                                const { bio, name, profilePhoto, username } = userData; // Destructure the fields from the user object
                              //  console.log('User Data:', userData);



                                   setUserData({
                                                bio: bio, name: name, profilePhoto: profilePhoto, username: username
                                       });
                                       


                            } else {
                              //  console.log('No data available for this user');
                            }
                        })
                        .catch((error) => {
                            console.error('Error fetching user data:', error);
                        });




                } else {
                 //   console.log('User not logged in');
                }
            });

            return () => unsubscribe();
        };

        fetchUserProfile();
    }, []);

    return userData;
};




export default useUserProfile;


export const getUserInfo = async (userId) => {
    try {

       console.log("get user info", userId)
        const snapshot = await get(child(userInformationRef, `${userId}`)); // Fetch the entire user object
        if (snapshot.exists()) {

            const userName = snapshot.child('name').val();
            const userProfilePhoto = snapshot.child('profilePhoto').val();
            const userLanguage = snapshot.child('Language').val();
            const userUsername = snapshot.child('username').val();
            const userCountry = snapshot.child('country').val();

            return { userName, userProfilePhoto, userLanguage, userUsername, userCountry };
        } else {
           // console.log('User data does not exist');
            return null; // Return null if user data does not exist
        }
    } catch (error) {
        console.error('Error fetching user information:', error.message);
        throw error; // Throw the error for handling in the calling code
    }
};
