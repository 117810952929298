


import { PrayersRef, get, child, PrayerRequestsRef  } from '../../Utilities/firebase';


export const getNumberOfUserPrayers = async (uid) => {

    try {

        let numberOfUserPrayers = 0;
        
            const prayersSnapshot = await get(child(PrayersRef, `Users/${uid}`));
            if (prayersSnapshot.exists()) {
                prayersSnapshot.forEach((postSnapshot) => {
                    if (postSnapshot.child('uid').val() === uid) {
                        numberOfUserPrayers++;
                    }
                });
                return numberOfUserPrayers; // Return the number directly
            } else {
                return 0; // Return 0 if no likes found
            }
      
    } catch (error) {
        console.error('Error fetching likes:', error);
        return 0; // Return 0 in case of error
    }
};



export const getNumberOfUserPrayerRequests = async (uid) => {
    try {
        const snapshot = await get(PrayerRequestsRef);
        if (snapshot.exists()) {
            let numberOfUserPrayerRequests = 0;
            snapshot.forEach((childSnapshot) => {
                if (childSnapshot.child('uid').val() === uid) {
                    numberOfUserPrayerRequests++;
                }
            });
            return numberOfUserPrayerRequests;
        } else {
            return 0; // Return 0 if no prayer requests found
        }
    } catch (error) {
        console.error('Error fetching prayer requests:', error);
        return 0; // Return 0 in case of error
    }
};