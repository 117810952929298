import { useState, useEffect } from 'react'

import { PrayersRef , PrayerRequestsRef, userInformationRef, update, remove, get, child } from '../Utilities/firebase'

import { formatDate } from '../Utilities/dateUtils'
import { useData } from '../App'
import { currentUserId } from '../Utilities/firebase'
import { getCurrentDateTime } from '../Utilities/currentDateTimeUtils'


const PostDataModel = () => {
    const { currentURL } = useData();

    const [postData, setPostData] = useState({});
   // console.log('4.THE EXTRACTED URL LOOKS LIKE THIS: ', currentURL);

    useEffect(() => {
        const fetchPrayerRequest = () => {


            if (currentURL) {
                const url = currentURL;



                get(child(PrayerRequestsRef, `${url}`)) // Fetch the entire user object
                    .then((snapshot) => {
                        if (snapshot.exists()) {
                            // User data exists, extract the user object
                            const postData = snapshot.val();
                            const { postId, postAudio, postAudioDuration, postAccess, postCaption, prayers, times_listened, postDate, uid, url } = postData; // Destructure the fields from the user object
                          //  console.log('Post Data:', postData);


                            const formattedDate = formatDate(postDate);




                            get(child(userInformationRef, `${uid}`))
                                .then((snapshot) => {
                                    if (snapshot.exists()) {

                                        const { name, profilePhoto, username } = snapshot.val();


                                        setPostData({
                                            name: name,
                                            userProfilePhoto: profilePhoto,
                                            username: username,
                                            postId: postId,
                                            postAudio: postAudio,
                                            postAudioDuration: postAudioDuration,
                                            postAccess: postAccess,
                                            postCaption: postCaption,
                                            prayers: prayers,
                                            postTimes_Listened: times_listened,
                                            postDate: formattedDate,
                                            uid: uid,
                                            url: url
                                        });
                                    } else {
                                        // Set user data state
                                        setPostData({
                                            name: name,
                                            userProfilePhoto: profilePhoto,
                                            username: username,
                                            postId: postId,
                                            postAudio: postAudio,
                                            postAudioDuration: postAudioDuration,
                                            postAccess: postAccess,
                                            postCaption: postCaption,
                                            prayers: prayers,
                                            postTimes_Listened: times_listened,
                                            postDate: formattedDate,
                                            uid: uid,
                                            url: url
                                        });
                                    }




                                })
                                .catch((error) => {
                                    console.error('Error fetching user stories:', error);
                                });




                        } else {
                          //  console.log('No data available for this user');
                        }
                    })
                    .catch((error) => {
                        console.error('Error fetching user data:', error);
                    });




            } else {
               // console.log('URL FOR THIS POST IS EMPTY');
            }


        };


        fetchPrayerRequest();


    }, [currentURL]);

    return postData;
}


/*

export const checkFollowingStatus = async (mUser) => {

    try {
        const snapshot = await get(child(FollowingRef, currentUserId));

        if (snapshot.exists()) {

            let isFollowing = false;
            snapshot.forEach((childSnapshot) => {

                // Check if the post contains the user's UID
                if (childSnapshot.child('uid').val() === mUser) {
                    isFollowing = true;
                }
            });
            return isFollowing;
        } else {
            return false;
        }
    } catch (error) {
        console.error("Error checking blocked user:", error);

        return false; // Return false in case of any error
    }
};



export const checkFollowingStatusFromUserProfile = async (mUser) => {

    try {
        const snapshot = await get(child(FollowingRef, currentUserId));

        if (snapshot.exists()) {

            let isFollowing = false;
            snapshot.forEach((childSnapshot) => {

                // Check if the post contains the user's UID
                if (childSnapshot.child('uid').val() === mUser) {
                  //  console.log('TCTest Gold: VALUES FOLLOWING?.', childSnapshot.child('uid').val());

                    isFollowing = true;
                }
            });
            return isFollowing;
        } else {
            return false;
        }
    } catch (error) {
        console.error("Error checking blocked user:", error);

        return false; // Return false in case of any error
    }
};

*/









export const getNumberOfPrayers = async (postURL) => {

    try {

        let numberOfPostPrayers = 0;
        
            const prayersSnapshot = await get(child(PrayersRef, `Posts/${postURL}`));
            if (prayersSnapshot.exists()) {
                prayersSnapshot.forEach((postSnapshot) => {
                    if (postSnapshot.child('postURL').val() === postURL) {
                        numberOfPostPrayers++;
                    }
                });
                return numberOfPostPrayers; // Return the number directly
            } else {
                return 0; // Return 0 if no likes found
            }
      
    } catch (error) {
        console.error('Error fetching likes:', error);
        return 0; // Return 0 in case of error
    }
};




export const deletePrayer = (urlId) => {

    remove(child(PrayersRef, `${urlId}/${currentUserId}`)).then(() => {
       // console.log('Data deleted successfully. UNLIKED POST ');
    })
        .catch((error) => {
         //   console.error('Error unliking post:', error);
        });
}

export const likeUserPost = (previousUserUID, postIdSe) => {
    const dateAdded = getCurrentDateTime();
    // Communicate with database based on what you have !!!!
   // console.log("1B.^^^ WE ARE LIKING --> " + previousUserUID)
   // console.log("2B.^^^ WE ARE LIKING --> " + postIdSe)


    // :: Set as Following ---------------------------------
    get(child(userInformationRef, `${currentUserId}`)) // Fetch the entire user object
        .then((snapshot) => {
            if (snapshot.exists()) {
                // User data exists, extract the user object

                const { name, username, profilePhoto, country } = snapshot.val();

                // ---------------------
                update(child(PrayersRef, `${postIdSe}/${currentUserId}`),
                    {
                        country: country,
                        date: dateAdded,
                        name: name,
                        postId: postIdSe,
                        profilePhoto: profilePhoto,
                        receiver: previousUserUID,
                        sender: currentUserId,
                        username: username
                    })

                    .then(() => {
                      //  console.log("^^^ Successfully Liked USER's Post", postIdSe);
                    })
                    .catch((error) => {
                       // console.error('^^^ Error liking user post:', error);
                    });

                // -----------------------

            } else {
               // console.log('No data available for this user');
            }
        })
        .catch((error) => {
           // console.error('Error fetching user data:', error);
        });




};


/*

export const updatePreviousActionsOnPost = (status, previousUser) => {

    // Communicate with database based on what you have !!!!
   // console.log("&& FINAL FOLLOWING STATUS: :||||||" + status + " |||||| THE USER IS THIS PERSON: " + previousUser)

    if (status === "Following") {
        addAsFollowing(previousUser);
    } else {
        setAsUnfollow(previousUser);
    }

};



export const addAsFollowing = (userUIDFollowing) => {
    const dateAdded = getCurrentDateTime();
    // Communicate with database based on what you have !!!!
   // console.log("&& WE ARE FOLLOWING --> " + userUIDFollowing)
   // console.log("&& CURRENT TIME --> " + dateAdded)


    // :: Set as Following ---------------------------------
    get(child(userInformationRef, `${userUIDFollowing}`)) // Fetch the entire user object
        .then((snapshot) => {
            if (snapshot.exists()) {
                // User data exists, extract the user object

                const { name, username, profilePhoto, country } = snapshot.val();

                // ---------------------
                update(child(FollowingRef, `${currentUserId}/${userUIDFollowing}`),
                    {
                        country: country,
                        currentFollower: currentUserId,
                        followerCount: "0",
                        name: name,
                        profilePhoto: profilePhoto,
                        uid: userUIDFollowing,
                        date: dateAdded,
                        username: username
                    })

                    .then(() => {
                      //  console.log('&& Successfully Following USER', username);
                    })
                    .catch((error) => {
                        console.error('&& Error saving user as Following:', error);
                    });

                // -----------------------

            } else {
              //  console.log('No data available for this user');
            }
        })
        .catch((error) => {
            console.error('Error fetching user data:', error);
        });




    // :: Set Follower! --------------------------

    get(child(userInformationRef, `${currentUserId}`)) // Fetch the entire user object
        .then((snapshot) => {
            if (snapshot.exists()) {
                // User data exists, extract the user object

                const { name, username, profilePhoto, country } = snapshot.val();
                // ---------------------
                update(child(FollowersRef, `${userUIDFollowing}/${currentUserId}`),
                    {
                        country: country,
                        currentLeader: userUIDFollowing,
                        followerCount: "0", name: name,
                        profilePhoto: profilePhoto,
                        uid: currentUserId,
                        date: dateAdded,
                        username: username
                    })

                    .then(() => {
                      //  console.log('&& Successfully Following USER', username);
                    })
                    .catch((error) => {
                        console.error('&& Error saving user as Following:', error);
                    });



                // ---------------------

            } else {
              //  console.log('No data available for this user');
            }
        })
        .catch((error) => {
            console.error('Error fetching user data:', error);
        });


};


export const setAsUnfollow = (userUIDToUnfollow) => {



    const dateAdded = getCurrentDateTime();
    // Communicate with database based on what you have !!!!
   /// console.log("&& WE UNFOLLOW --> " + userUIDToUnfollow)
   // console.log("&& CURRENT TIME --> " + dateAdded)

    // Remove Following 
    remove(child(FollowingRef, `${currentUserId}/${userUIDToUnfollow}`)).then(() => {
      //  console.log('Data deleted successfully. CURRENTLY NOT FOLLOWING ');
    })
        .catch((error) => {
            console.error('Error deleting data:', error);
        });

    // Remove Follower 
    remove(child(FollowersRef, `${userUIDToUnfollow}/${currentUserId}`)).then(() => {
       // console.log('Data deleted successfully. A FOLLOWER WAS LOST');
    })
        .catch((error) => {
            console.error('Error deleting data:', error);
        });



};


*/


export const deletePrayerRequest = (urlId, storyURL) => {
    // Assuming StoryPostsRef is a reference to the Firebase Realtime Database location
    get(child(PrayerRequestsRef, `${urlId}`)) // Fetch the entire user object
        .then((snapshot) => {
            if (snapshot.exists()) {
                // Delete the node

            //    console.log(` BTest ONLY NODE WITH THIS POST ID :: ${postId}  WILL BE DELETED`);


                remove(child(PrayerRequestsRef, urlId))
                    .then(() => {
                 //       console.log(`Node with ID ${postId} deleted successfully`);
                    })
                    .catch((error) => {
                //        console.error(`Error deleting node with ID ${postId}:`, error);
                    });


            } else {
              //  console.log("No post found with postId:", postId);

            }
        })
        .then(() => {
           // console.log("Post successfully deleted!");
        })
        .catch((error) => {
            console.error("Error deleting post:", error);
        });

}


export const checkIfPrayerRqExists = async (urlId) => {
   // console.log("2.Htest Does Story exist?" , storyURL)

    try {
        const snapshot = await get(child(PrayerRequestsRef, urlId));
       // console.log("3.Htest Does Story exist?" , snapshot.exists())

        return snapshot.exists();
        
    } catch (error) {
        console.error("Error checking if story exists:", error);
        return false; // Return false if there's an error
    }
};


export const updateNumberOfListens = async (urlId, times_listened) => {

    update(child(PrayerRequestsRef, urlId), { times_listened: times_listened })
  
};


export default PostDataModel;