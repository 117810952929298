import { userInformationRef, NotificationsRef, child, get, update } from '../Utilities/firebase';
import { currentUserId } from '../Utilities/firebase';
import { useEffect, useState } from 'react';
import { timeAgo } from '../Utilities/dateUtils';
import { formatDateTimeAgo, goFormatDate } from '../Utilities/dateUtils';

const useNotificationPrayersList = (uid) => {
   
    const [prayerData, setPrayerData] = useState([]);

    useEffect(() => {
        const fetchPrayers = () => {

        

             get(child(NotificationsRef, uid)) // Fetch the entire user object
               .then((snapshot) => {
               if (snapshot.exists()) {
               
                   const PrayersData = [];
                   snapshot.forEach((childSnapshot) => {
                       // Check if the post contains the user's UIDi



                           const postDate = childSnapshot.child('postDate').val();
                           const formattedDate = formatDateTimeAgo(postDate);
                           const bgFormattedDate = goFormatDate(postDate);
                           const senderUID = childSnapshot.child('sender').val();

                           // Extract data from each PrayerRequest

                         
                           const Prayers = {
                               id: childSnapshot.key, // Assuming postId is the unique identifier
                               postAudio: childSnapshot.child('postAudio').val(),
                               postAudioDuration: childSnapshot.child('postAudioDuration').val(),
                               postCountry: childSnapshot.child('postCountry').val(),
                               postDate: formattedDate,
                               postLanguage: childSnapshot.child('postLanguage').val(),
                               postURL: childSnapshot.child('postURL').val(),
                               prayerRequestTitle: childSnapshot.child('prayerRequestTitle').val(),
                               times_listened: childSnapshot.child('times_listened').val(),
                               status: childSnapshot.child('status').val(),
                               uid: childSnapshot.child('uid').val(),
                               url: childSnapshot.child('url').val(),
                               rawTimeStamp: childSnapshot.child('postDate').val(),
                               
                         
                              };
                              PrayersData.push({ Prayers, bgFormattedDate, senderUID });

                      





                   });


                   // Once PrayersData is populated with initial data, fetch additional user data
                   // Once PrayersData is populated with initial data, fetch additional user data
                   Promise.all(PrayersData.map(({ senderUID }, index) =>
                       get(child(userInformationRef, senderUID))

                           .then((userSnapshot) => {

                            
                            if (userSnapshot.exists()) {
                                   const userData = userSnapshot.val();
                                   const { name, username, profilePhoto } = userData;
                                   // Include additional user data in corresponding Prayers
                                   const Prayers = {
                                       id: PrayersData[index].Prayers.id,
                                       postAudio: PrayersData[index].Prayers.postAudio,
                                       postAudioDuration: PrayersData[index].Prayers.postAudioDuration,
                                       postCountry: PrayersData[index].Prayers.postCountry,
                                       postDate: PrayersData[index].Prayers.postDate,
                                       postLanguage: PrayersData[index].Prayers.postLanguage,
                                       postURL: PrayersData[index].Prayers.postURL,
                                       prayerRequestTitle: PrayersData[index].Prayers.prayerRequestTitle,
                                       times_listened: PrayersData[index].Prayers.times_listened,
                                       status: PrayersData[index].Prayers.status,
                                       uid: PrayersData[index].Prayers.uid,
                                       url: PrayersData[index].Prayers.url,
                                       name: name,
                                       username: username,
                                       profilePhoto: profilePhoto,
                                       rawTimeStamp: PrayersData[index].Prayers.rawTimeStamp,
                                   };
                                   PrayersData[index].Prayers = Prayers;
                             
                             }
                           })
                           .catch((error) => {
                               console.error('Error fetching user data:', error);
                         
                           })
                   ))

                   
                       .then(() => {
                           // Sort the PrayersData array based on bgFormattedDate
                           PrayersData.sort((a, b) => {
                               if (a.bgFormattedDate > b.bgFormattedDate) return -1;
                               if (a.bgFormattedDate < b.bgFormattedDate) return 1;
                               return 0;
                           });

                           // Extract the sorted Prayers objects from PrayersData
                           const sortedPrayers = PrayersData.map(({ Prayers }) => Prayers);

                           // Set the sorted PrayerRequests in the state

                   
                           setPrayerData(sortedPrayers);
                       
                        //   setIsFetchingPrayerRequests(false);
                         //  setNoPrayerRequestsFound(false);
                   

                       })
                       .catch((error) => {
                           console.error('Error processing PrayerRequest data:', error);
            
                    
                       });




               }  else {
                 //  setIsFetchingPrayerRequests(false);
                 //  setNoPrayerRequestsFound(true);
               }

           })
           .catch((error) => {
               console.error('Error fetching PrayerRequests:', error);

      
           });

           

     
        };



            fetchPrayers();


        }, [uid]);



    return prayerData;
}


export default useNotificationPrayersList;



export const checkIfNotificationExists = async (uid) => {
    try {
      const snapshot = await get(child(NotificationsRef, uid));
      return snapshot.exists();
    } catch (error) {
      console.error("Error checking notification:", error);
      return false; // Return false in case of error
    }
  };


export const getNumberOfNotifications = async (uid) => {
    if (!currentUserId) {
        return 0;
    }

    try {
        const newNotiSnapshot = await get(child(NotificationsRef, uid));
        if (newNotiSnapshot.exists()) {
            let notificationsCount = 0;
            newNotiSnapshot.forEach((newNotiSnap) => {
                const status = newNotiSnap.child('status').val();
                if (status === 'unread') {
                    notificationsCount++;
                }
            });
            return notificationsCount;
        } else {
            return 0;
        }
    } catch (error) {
        console.error('Error fetching notifications:', error);
        return 0;
    }
};


export const setNotificationsAsRead = async (mUser) => {
    try {
        const newSnapshot = await get(child(NotificationsRef, mUser)); // Fixed currentUserId access
        //console.log("2. LTest mUser", mUser);

        if (newSnapshot.exists()) {
            newSnapshot.forEach((newSnap) => { // Fixed variable name
                const status = newSnap.child('status').val(); // Fixed variable name
                if (status === 'unread') {
                    // Update the status of the chat to 'read'
                    update(newSnap.ref, { status: 'read' }) // Update status
                        .then(() => {
                            // console.log('Status updated to read for friend:', newSnap.key); // Fixed variable name
                        })
                        .catch((error) => {
                            console.error('Error updating status:', error);
                        });
                }
            });
        } else {
            // Handle the case where there are no new notifications
        }
    } catch (error) {
        console.error('Error fetching notifications:', error); // Fixed error message
    }
};