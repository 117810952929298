import React from 'react'
import fonts from '../Utilities/fonts'
import { VStack, Heading, Text, Spacer, ChakraProvider , UnorderedList, ListItem} from '@chakra-ui/react'
import { usePageTitle } from '../Utilities/PageTitles'
const CommunityGuidelines = () => {

  usePageTitle('Community Guidelines');
  
  return (
    <ChakraProvider theme={fonts}>
   
    
  <VStack pt='6rem'>
  <Heading as="h3" pb="1rem" size="lg" fontWeight="bold" mb="1rem" fontFamily="'Work Sans', sans-serif">
    Community Guidelines
  </Heading>
    <Text pt="2rem" size="lg" textAlign="center">PrayerSend's Community Guidelines</Text>

    </VStack>



    <VStack align="flex-start" pt='6rem' px={{ base: '2rem', sm: '2rem', md: '4rem', lg: '14rem' }}>
    <Text>PrayerSend's community guidelines supplement those of YenZek, ensuring a consistent and unified experience across our platforms. As an extension of YenZek, PrayerSend adheres to the core principles and policies established by YenZek, while also addressing specific considerations unique to the PrayerSend community.</Text>

    <Text pt="1rem">i.{')'} No profanity when sending prayer requests</Text>
    <UnorderedList sx={{ paddingLeft: "2rem" }}>
    <ListItem>Please refrain from using vulgar language when sending prayer requests.
    </ListItem>
    </UnorderedList>

    <Text pt="2rem">ii.{')'} No politics when sending prayers</Text>
    <UnorderedList sx={{ paddingLeft: "2rem" }}>
    <ListItem>Please avoid including political rhetoric in your prayers unless it is specifically mentioned within the prayer request.
    </ListItem>
    </UnorderedList>

    <Text pt="1rem">iii.{')'} No denominational References when sending prayers</Text>

    <UnorderedList sx={{ paddingLeft: "2rem" }}>
    <ListItem>Keep your prayers free from denominational references. Do not mention the name of the denomination you’re affiliated with in your prayers. We’re here to unite, not divide.
    </ListItem>
    </UnorderedList>
    <Text pt="1rem">iv.{')'} Focus on the person when sending prayers</Text>

    <UnorderedList sx={{ paddingLeft: "2rem" }}>
    <ListItem>Remember, your prayer should focus on the person you are praying for. It’s about them not you! 
</ListItem>
</UnorderedList>




<Text pl="1rem" pt="2rem">Last updated:  June 3, 2024</Text>

        <Spacer mb="10rem" />

      </VStack>
    </ChakraProvider>


  )
}

export default CommunityGuidelines