import { useState, useEffect } from 'react'


import { PrayersRef, userInformationRef, update, remove, get, child  } from '../../Utilities/firebase'



import { formatDateTimeAgo, goFormatDate } from '../../Utilities/dateUtils'



const useProfilePrayersList = (uid) => {
   
    const [prayerData, setPrayerData] = useState([]);

    useEffect(() => {
        const fetchPrayers = () => {

        

             get(child(PrayersRef, `Users/${uid}`)) // Fetch the entire user object
               .then((snapshot) => {
               if (snapshot.exists()) {
               
                   const PrayersData = [];
                   snapshot.forEach((childSnapshot) => {
                       // Check if the post contains the user's UIDi



                           const postDate = childSnapshot.child('postDate').val();
                           const formattedDate = formatDateTimeAgo(postDate);
                           const bgFormattedDate = goFormatDate(postDate);
                           const receiverUID = childSnapshot.child('receiver').val();

                           // Extract data from each PrayerRequest

                         
                           const Prayers = {
                               id: childSnapshot.key, // Assuming postId is the unique identifier
                               postAudio: childSnapshot.child('postAudio').val(),
                               postAudioDuration: childSnapshot.child('postAudioDuration').val(),
                               postCountry: childSnapshot.child('postCountry').val(),
                               postDate: formattedDate,
                               postLanguage: childSnapshot.child('postLanguage').val(),
                               postURL: childSnapshot.child('postURL').val(),
                               prayerRequestTitle: childSnapshot.child('prayerRequestTitle').val(),
                               times_listened: childSnapshot.child('times_listened').val(),
                               uid: childSnapshot.child('uid').val(),
                               url: childSnapshot.child('url').val(),
                               rawTimeStamp: childSnapshot.child('postDate').val(),
                               
                         
                              };
                              PrayersData.push({ Prayers, bgFormattedDate, receiverUID });

                      





                   });


                   // Once PrayersData is populated with initial data, fetch additional user data
                   // Once PrayersData is populated with initial data, fetch additional user data
                   Promise.all(PrayersData.map(({ receiverUID }, index) =>
                       get(child(userInformationRef, receiverUID))

                           .then((userSnapshot) => {

                            
                            if (userSnapshot.exists()) {
                                   const userData = userSnapshot.val();
                                   const { name, username, profilePhoto } = userData;
                                   // Include additional user data in corresponding Prayers
                                   const Prayers = {
                                       id: PrayersData[index].Prayers.id,
                                       postAudio: PrayersData[index].Prayers.postAudio,
                                       postAudioDuration: PrayersData[index].Prayers.postAudioDuration,
                                       postCountry: PrayersData[index].Prayers.postCountry,
                                       postDate: PrayersData[index].Prayers.postDate,
                                       postLanguage: PrayersData[index].Prayers.postLanguage,
                                       postURL: PrayersData[index].Prayers.postURL,
                                       prayerRequestTitle: PrayersData[index].Prayers.prayerRequestTitle,
                                       times_listened: PrayersData[index].Prayers.times_listened,
                                       uid: PrayersData[index].Prayers.uid,
                                       url: PrayersData[index].Prayers.url,
                                       name: name,
                                       username: username,
                                       profilePhoto: profilePhoto,
                                       rawTimeStamp: PrayersData[index].Prayers.rawTimeStamp,
                                   };
                                   PrayersData[index].Prayers = Prayers;
                             
                             }
                           })
                           .catch((error) => {
                               console.error('Error fetching user data:', error);
                         
                           })
                   ))

                   
                       .then(() => {
                           // Sort the PrayersData array based on bgFormattedDate
                           PrayersData.sort((a, b) => {
                               if (a.bgFormattedDate > b.bgFormattedDate) return -1;
                               if (a.bgFormattedDate < b.bgFormattedDate) return 1;
                               return 0;
                           });

                           // Extract the sorted Prayers objects from PrayersData
                           const sortedPrayers = PrayersData.map(({ Prayers }) => Prayers);

                           // Set the sorted PrayerRequests in the state

                   
                           setPrayerData(sortedPrayers);
                       
                        //   setIsFetchingPrayerRequests(false);
                         //  setNoPrayerRequestsFound(false);
                   

                       })
                       .catch((error) => {
                           console.error('Error processing PrayerRequest data:', error);
            
                    
                       });




               }  else {
                 //  setIsFetchingPrayerRequests(false);
                 //  setNoPrayerRequestsFound(true);
               }

           })
           .catch((error) => {
               console.error('Error fetching PrayerRequests:', error);

      
           });

           

     
        };



            fetchPrayers();


        }, [uid]);



    return prayerData;
}


export default useProfilePrayersList;





