import React, { useEffect } from 'react'
import { Center, Image, VStack, Flex, Text } from '@chakra-ui/react'
import { useData } from './App';
const LoadingPage = () => {
  
    const { setNotFoundPageVisible } = useData();

    useEffect(() => {
        // Set NotFoundPageVisible to true when the LoadingPage mounts
        setNotFoundPageVisible(true);

        // Event listener to handle browser/tab close
        const handleUnload = () => {
            setNotFoundPageVisible(false);
        };

        window.addEventListener('beforeunload', handleUnload);

        // Clean up function to remove event listener when the component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, [setNotFoundPageVisible]);

    return (
        <Center h="100vh"> {/* Center vertically and horizontally */}
              <VStack>
                <Image src='/PrayerSendLogoText.png' h={20} alt='PrayerSend Logo'></Image>

                <Flex mt="-30px" mb="3px" flexDirection="column">
                  <Text fontSize={10} color="gray" >from</Text>

                  <Image src='/yenzeknamelogo.png' h={8} alt='YenZek Logo'></Image>
                </Flex>
              </VStack>
        </Center>
    )
}

export default LoadingPage