import React, { useState, useEffect, useRef } from 'react';
import { Box, Link, Flex, Image, VStack, Tooltip, Button, Text, Spacer, useBreakpointValue } from '@chakra-ui/react'



import { MdChurch } from "react-icons/md";

import { MdOutlineChurch } from "react-icons/md";


import { getAuth } from 'firebase/auth';


import { GoHome, GoPeople } from "react-icons/go";
import { GoHomeFill } from "react-icons/go";



import { IoSettingsOutline } from "react-icons/io5";

import { IoSettingsSharp } from "react-icons/io5";



import { IoMdNotificationsOutline, IoMdNotifications } from "react-icons/io";



import { BiMessageSquareAdd, BiSolidMessageSquareAdd } from "react-icons/bi";


import { IoPersonOutline } from "react-icons/io5";


import { useNavigate, useLocation } from 'react-router-dom';
import useUserUsername from '../pages/ProfilePage/ProfileUsername';

import { getUserInfo } from '../pages/ProfilePage/Profile';

import { currentUserId } from '../Utilities/firebase';

import { useData } from '../App';

import { getNumberOfNotifications } from './NotificationsData';

const Sidebar = () => {

    const auth = getAuth();

    const user = auth.currentUser;


    const [numberOfNotifications, setNumberOfNotifications] = useState(0);


    useEffect(() => {
        const fetchNotifications = async () => {
            if (user) {
                const notifications = await getNumberOfNotifications(user.uid);
                setNumberOfNotifications(notifications);
            }
        };

        fetchNotifications();
    }, [user]);


    const navigate = useNavigate();

    const { username } = useUserUsername();

    const [userProfilePhoto, setUserProfilePhoto] = useState(null);


    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const userInfo = await getUserInfo(currentUserId);
                if (userInfo) {
                    setUserProfilePhoto(userInfo.userProfilePhoto);
                }

            } catch (error) {

            }
        };

        fetchUserInfo();
    }, [currentUserId]);


    const currentYear = new Date().getFullYear();

    const isSmallScreen = useBreakpointValue({ base: true, lg: false });



    const { pathname } = useLocation()
    const {

        isHomeSelected, setHomeSelected,
        isNotificationsSelected, setNotificationsSelected,
        isAddNewPostSelected, setAddNewPostSelected,
        isProfileSelected, setProfileSelected,

        isSettingsSelected, setSettingsSelected,


        isUserOnProfilePages, setIsUserOnProfilePages,

        setShowBeforeLoginSignupPopup,





    } = useData();




    const goToPrayerRequests = () => {
        // Navigate to Profile
        navigate('/requests');


        setHomeSelected(true);
        setNotificationsSelected(false);
        setAddNewPostSelected(false);
        setProfileSelected(false);

        setSettingsSelected(false);

    };


    const goToNotifications = () => {

        if (user) {

            setHomeSelected(false);
            setNotificationsSelected(prevState => !prevState);
            setAddNewPostSelected(false);
            setProfileSelected(false);

            setSettingsSelected(false);

        } else {
            // User is not signed in, navigate to home page
            // navigate("/");
            presentLoginPopup();
        }

    };



    const goToSendPrayerRequest = () => {
        if (user) {
            setHomeSelected(false);
            setNotificationsSelected(false);
            setAddNewPostSelected(false);
            setProfileSelected(false);

            setSettingsSelected(false);
            navigate("/");



        } else {
            // User is not signed in, navigate to home page
            // navigate('/login');

            presentLoginPopup();
        }

    };

    const goToProfile = () => {




        if (user) {
            // User is signed in, navigate to profile page

            navigate(`/${username}`);



            setHomeSelected(false);
            setNotificationsSelected(false);
            setAddNewPostSelected(false);
            setProfileSelected(true);

            setSettingsSelected(false);

        } else {
            // User is not signed in, navigate to home page
            // navigate("/login");
            presentLoginPopup();

        }




    };


    const goToSettings = () => {

        setHomeSelected(false);
        setNotificationsSelected(false);
        setAddNewPostSelected(false);
        setProfileSelected(false);

        setSettingsSelected(true);


    };




    const presentLoginPopup = () => {

        if (!user) {

            setShowBeforeLoginSignupPopup(true);

        }
    };




    const includedPaths = [
        '/listen',
        '/requests',


    ];

    const isMainPathIncluded = includedPaths.some(path => pathname.includes(path));




    return (
        <Box
            pl={isSmallScreen ? null : "5px"}
            position="fixed"
            top="0"
            left="0" // Fixed to the left
            height="100%" // Take up the full height of the viewport
            bg="white"
            borderRight="1px solid #e4e4e4" // Optional: Add a border to the right side
            transition="transform 0.3s ease"
            zIndex="1"
            pt={
                user ? "0rem" : (isMainPathIncluded || isUserOnProfilePages ? "5rem" : "8px")
            }
            pb="8px">

            <VStack paddingInlineStart="6px" paddingInlineEnd="8px" pt="5px">
                <Flex direction="column">
                    {/* ADD LOGO HERE */}

                    {user && (
                        <>
                            {/* Logo */}
                            <Box pl="5px" onClick={goToSendPrayerRequest} display={isSmallScreen ? "block" : "none"} py={4}> {/* Show yenzeklogoonly.png when screen is small */}
                                <Image src="/PrayerSendLogo.png" h={8} w={8} alt='PrayerSend Logo' />
                            </Box>
                            <Box pr="10px" onClick={goToSendPrayerRequest} display={isSmallScreen ? "none" : "block"}> {/* Show yenzeknamelogo.png when screen is medium */}
                                <Image src="/PrayerSendLogoText.png" maxH="70px" alt='PrayerSend Logo' />
                            </Box>
                        </>
                    )}

                    <Flex direction="column" pt="1rem">


                        <Tooltip
                            hasArrow
                            label={"Prayer Requests"}
                            placement='right'
                            ml={1}
                            openDelay={500}

                            display={{ base: "block", md: "none" }}


                        >
                            <Flex
                                gap={4}
                                borderRadius={6}
                                p={2}
                                w={{ base: 10, md: "full" }}
                                justifyContent="flex-start" // Align items to the left
                                onClick={goToPrayerRequests}
                                _hover={{
                                    bg: 'gray.100',
                                    transform: "scaleY(1.022)"
                                }} style={{
                                    transition: "transform 0.1s",
                                    cursor: "pointer",
                                }}>


                                <Flex alignItems="center"> {/* Wrap icon and text in a Flex container */}
                                    {isHomeSelected ? <MdChurch style={{ fontSize: "24px", fontWeight: "bold" }} /> : <MdOutlineChurch style={{ fontSize: "24px" }} />}
                                    <Box
                                        display={{ base: "none", md: "block" }}
                                        style={{ marginLeft: '16px', fontWeight: isHomeSelected ? "bold" : "normal" }}
                                        fontSize="16px">Prayer Requests</Box> {/* Add margin directly to the text */}

                                </Flex>
                            </Flex>
                        </Tooltip>








                        <Tooltip
                            hasArrow
                            label={"Notifications"}
                            placement='right'
                            ml={1}
                            openDelay={500}
                            display={{ base: "block", md: "none" }}

                        >
                            <Flex
                                gap={4}
                                borderRadius={6}
                                p={2}
                                w={{ base: 10, md: "full" }}
                                justifyContent="flex-start" // Align items to the left
                                onClick={goToNotifications}
                                _hover={{
                                    bg: 'gray.100',
                                    transform: "scale(1.022)",
                                }} style={{
                                    transition: "transform 0.1s",
                                    cursor: "pointer",
                                    position: 'relative'  // Ensure this container is relative
                                }}>



                                <Flex alignItems="center"> {/* Wrap icon and text in a Flex container */}

                                    {isNotificationsSelected ? <IoMdNotifications style={{ fontSize: "24px", fontWeight: "bold" }} /> : <IoMdNotificationsOutline style={{ fontSize: "24px" }} />}
                                    <Box
                                        display={{ base: "none", md: "block" }}
                                        style={{ marginLeft: '16px', fontWeight: isNotificationsSelected ? "bold" : "normal" }}
                                        fontSize="16px">Notifications</Box> {/* Add margin directly to the text */}

                                    {numberOfNotifications !== null && numberOfNotifications > 0 && (

                                        <Box
                                            bg="red"
                                            borderRadius="50%"

                                            display="inline-flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            width="1.3rem"
                                            height="1.2rem"
                                            position="absolute"
                                            top="1px"
                                            left="18px"





                                        >
                                            <Text color="white" as='b' fontSize="11px">{numberOfNotifications}</Text>
                                        </Box>
                                    )}


                                </Flex>


                            </Flex>


                        </Tooltip>




                        <Tooltip
                            hasArrow
                            label={"Add New Prayer Request"}
                            placement='right'
                            ml={1}
                            openDelay={500}
                            display={{ base: "block", md: "none" }}
                        >
                            <Flex
                                gap={4}
                                borderRadius={6}
                                p={2}
                                w={{ base: 10, md: "full" }}
                                justifyContent="flex-start" // Align items to the left
                                onClick={goToSendPrayerRequest}
                                _hover={{
                                    bg: 'gray.100',
                                    transform: "scale(1.022)",
                                }} style={{
                                    transition: "transform 0.1s",
                                    cursor: "pointer",
                                }}>

                                <Flex alignItems="center" >

                                    {isAddNewPostSelected ? <BiSolidMessageSquareAdd
                                        style={{
                                            fontSize: "24px",
                                            fontWeight: "bold",
                                        }} /> : <BiMessageSquareAdd style={{
                                            fontSize: "24px",
                                        }} />}
                                    <Box
                                        display={{ base: "none", md: "block" }}
                                        style={{ marginLeft: '16px', fontWeight: isAddNewPostSelected ? "bold" : "normal" }}
                                        fontSize="16px">New Prayer Request</Box> {/* Add margin directly to the text */}

                                </Flex>
                            </Flex>
                        </Tooltip>



                        <Tooltip
                            hasArrow
                            label={"Profile"}
                            placement='right'
                            ml={1}
                            openDelay={500}
                            display={{ base: "block", md: "none" }}
                        >

                            <Flex

                                gap={4}
                                borderRadius={6}
                                p={2}
                                w={{ base: 10, md: "full" }}
                                justifyContent="flex-start" // Align items to the left
                                onClick={goToProfile}
                                _hover={{
                                    bg: 'gray.100',
                                    transform: "scale(1.022)",
                                }} style={{
                                    transition: "transform 0.1s",
                                    cursor: "pointer",
                                }}>
                                <Flex alignItems="center"
                                >

                                    {user ? (
                                        <Image
                                            borderRadius="full"
                                            boxSize="26px"
                                            src={userProfilePhoto}
                                            alt="profile pic"
                                            border={isProfileSelected ? "1.5px solid black" : "none"} // Apply border if isProfileSelected is true
                                        />

                                    ) : (
                                        <>
                                            <IoPersonOutline style={{ fontSize: "24px" }} /> {/* Adjust icon size */}
                                        </>
                                    )}


                                    <Box
                                        display={{ base: "none", md: "block" }}
                                        style={{ marginLeft: '16px', fontWeight: isProfileSelected ? "bold" : "normal" }}
                                        fontSize="16px">Profile</Box> {/* Add margin directly to the text */}


                                </Flex>
                            </Flex>
                        </Tooltip>

                    </Flex>







                    {!user && (


                        <Box display={isSmallScreen ? "none" : "block"} mx="auto" pt="10px">
                            <Button

                                variant="outline"
                                onClick={presentLoginPopup}
                                _hover={{
                                    bg: 'white',
                                    color: '#081b4f',
                                    outline: 'none', // Remove the outline on hover
                                }}
                                style={{
                                    transition: "transform 0.1s",
                                    cursor: "pointer",
                                }}>
                                Log in
                            </Button>
                        </Box>

                    )}


                </Flex>


                {user && (


                    <>


                        <Flex
                            mt={isSmallScreen ? "22.5rem" : "22rem"}
                            gap={4}
                            borderRadius={6}
                            p={2}

                            w={{ base: 10, md: "full" }}
                            justifyContent="flex-start" // Align items to the left
                            onClick={() => window.open('https://www.yenzek.com', '_blank')}
                            _hover={{
                                bg: 'gray.100',
                                transform: "scale(1.022)",
                            }} style={{
                                transition: "transform 0.1s",
                                cursor: "pointer",
                            }}>

                            <Flex alignItems="center"   >

                                <Image

                                    borderRadius="full"
                                    boxSize="26px"
                                    src="/yenzeklogoonly.png"
                                    alt="yenzek button"// Apply border if isProfileSelected is true
                                />

                                <Box pl="15px"
                                    display={{ base: "none", md: "block" }}
                                    fontSize="16px">YenZek</Box> {/* Add margin directly to the text */}



                            </Flex>

                        </Flex>





                        <Flex
                            gap={4}
                            borderRadius={6}
                            p={2}
                            w={{ base: 10, md: "full" }}
                            justifyContent="flex-start" // Align items to the left
                            onClick={goToSettings}
                            _hover={{
                                bg: 'gray.100',
                                transform: "scale(1.022)",
                            }} style={{
                                transition: "transform 0.1s",
                                cursor: "pointer",
                            }}>

                            <Flex alignItems="center" >

                                {isSettingsSelected ? <IoSettingsSharp
                                    style={{
                                        fontSize: "24px",
                                        fontWeight: "bold",
                                    }} /> : <IoSettingsOutline style={{
                                        fontSize: "24px",
                                    }} />}

                                <Box
                                    display={{ base: "none", md: "block" }}
                                    style={{ marginLeft: '16px', fontWeight: isSettingsSelected ? "bold" : "normal" }}
                                    fontSize="16px">Settings</Box> {/* Add margin directly to the text */}



                            </Flex>


                        </Flex>



                    </>



                )}


            </VStack>
        </Box>
    );
};

export default Sidebar;