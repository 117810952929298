import { useState, useEffect } from 'react'

import { PrayersRef , userInformationRef, update, remove, get, child } from '../Utilities/firebase'

import { formatDate } from '../Utilities/dateUtils'
import { useData } from '../App'
import { currentUserId } from '../Utilities/firebase'
import { getCurrentDateTime } from '../Utilities/currentDateTimeUtils'
import { formatDateTimeAgo } from '../Utilities/dateUtils'
import { goFormatDate } from '../Utilities/dateUtils'


const usePrayers = (postURL) => {
   
    const [prayerData, setPrayerData] = useState([]);
/*
    useEffect(() => {
        const fetchPrayer = () => {
*/
            if (postURL) {
                const url = postURL;

        

             get(child(PrayersRef, `Posts/${url}`)) // Fetch the entire user object
               .then((snapshot) => {
               if (snapshot.exists()) {
               
                   const PrayersData = [];
                   snapshot.forEach((childSnapshot) => {
                       // Check if the post contains the user's UIDi



                           const postDate = childSnapshot.child('postDate').val();
                           const formattedDate = formatDateTimeAgo(postDate);
                           const bgFormattedDate = goFormatDate(postDate);
                           const creatorUID = childSnapshot.child('uid').val();
                           // Extract data from each PrayerRequest

                         
                           const Prayers = {
                               id: childSnapshot.key, // Assuming postId is the unique identifier
                               postAudio: childSnapshot.child('postAudio').val(),
                               postAudioDuration: childSnapshot.child('postAudioDuration').val(),
                               postCountry: childSnapshot.child('postCountry').val(),
                               postDate: formattedDate,
                               postLanguage: childSnapshot.child('postLanguage').val(),
                               times_listened: childSnapshot.child('times_listened').val(),
                               uid: childSnapshot.child('uid').val(),
                               url: childSnapshot.child('url').val(),
                               rawTimeStamp: childSnapshot.child('postDate').val(),
                         
                              };
                              PrayersData.push({ Prayers, bgFormattedDate, creatorUID });

                      





                   });


                   // Once PrayersData is populated with initial data, fetch additional user data
                   // Once PrayersData is populated with initial data, fetch additional user data
                   Promise.all(PrayersData.map(({ creatorUID }, index) =>
                       get(child(userInformationRef, creatorUID))
                           .then((userSnapshot) => {
                            if (userSnapshot.exists()) {
                                   const userData = userSnapshot.val();
                                   const { name, username, profilePhoto } = userData;
                                   // Include additional user data in corresponding Prayers
                                   const Prayers = {
                                       id: PrayersData[index].Prayers.id,
                                       postAudio: PrayersData[index].Prayers.postAudio,
                                       postAudioDuration: PrayersData[index].Prayers.postAudioDuration,
                                       postCountry: PrayersData[index].Prayers.postCountry,
                                       postDate: PrayersData[index].Prayers.postDate,
                                       postLanguage: PrayersData[index].Prayers.postLanguage,
                                       times_listened: PrayersData[index].Prayers.times_listened,
                                       uid: PrayersData[index].Prayers.uid,
                                       url: PrayersData[index].Prayers.url,
                                       name: name,
                                       username: username,
                                       profilePhoto: profilePhoto,
                                       rawTimeStamp: PrayersData[index].Prayers.rawTimeStamp,
                                   };
                                   PrayersData[index].Prayers = Prayers;
                             } else {
                      //           console.error(`User data does not exist for UID: ${creatorUID}`);
                             }
                           })
                           .catch((error) => {
                               console.error('Error fetching user data:', error);
                         
                           })
                   ))

                   
                       .then(() => {
                           // Sort the PrayersData array based on bgFormattedDate
                           PrayersData.sort((a, b) => {
                               if (a.bgFormattedDate > b.bgFormattedDate) return -1;
                               if (a.bgFormattedDate < b.bgFormattedDate) return 1;
                               return 0;
                           });

                           // Extract the sorted Prayers objects from PrayersData
                           const sortedPrayers = PrayersData.map(({ Prayers }) => Prayers);

                           // Set the sorted PrayerRequests in the state

                   
                           setPrayerData(sortedPrayers);
                       
                        //   setIsFetchingPrayerRequests(false);
                         //  setNoPrayerRequestsFound(false);
                   

                       })
                       .catch((error) => {
                           console.error('Error processing PrayerRequest data:', error);
            
                    
                       });




               }  else {
                 //  setIsFetchingPrayerRequests(false);
                 //  setNoPrayerRequestsFound(true);
               }

           })
           .catch((error) => {
               console.error('Error fetching PrayerRequests:', error);

      
           });

           


            }
        /*
        };



            fetchPrayer();


        }, []);
*/


    return prayerData;
}


export const checkForPrayers = async (postURL) => {
    return get(child(PrayersRef, `Posts/${postURL}`)) // Fetch the entire user object
        .then((snapshot) => {
            return snapshot.exists(); // Return true if the post exists, false otherwise
        })
        .catch((error) => {
            console.error("Error getting prayer post:", error);
            return false; // Return false in case of an error
        });
}


export default usePrayers;


