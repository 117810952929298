// Function to format the date
const formatDate = (dateString) => {
    const [date, time] = dateString.split(' ');
    const [day, month, year] = date.split('-');

    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const today = new Date();
    const currentYear = today.getFullYear();

    if (parseInt(year) === currentYear) {
        return `${months[parseInt(month) - 1]} ${parseInt(day)}`;
    } else {
        return `${months[parseInt(month) - 1]} ${parseInt(day)}, ${year}`;
    }

    
};

// Function to format the date for sorting
const goFormatDate = (dateString) => {
    const [date, time] = dateString.split(' ');
    const [day, month, year] = date.split('-');

    return new Date(`${year}-${month}-${day}`); // Create a Date object with the formatted date
};

export { formatDate, goFormatDate };





const formatDateTimeAgo = (dateTimeString) => {
    const [datePart, timePart] = dateTimeString.split(' '); // Split date and time parts
    const [year, month, day] = datePart.split('-');
    const [hours, minutes, seconds] = timePart.split(':'); // Split hours, minutes, seconds

    return new Date(year, month - 1, day, hours, minutes, seconds); // Create a Date object with date and time parts
};

const timeAgo = (dateTimeString) => {
    const date = formatDateTimeAgo(dateTimeString); // Use formatDateTimeAgo to parse the date and time
    const now = new Date();
    const diff = now - date;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (seconds < 60) {
        return seconds === 1 ? '1 second ago' : `${seconds} seconds ago`;
    } else if (minutes < 60) {
        return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
    } else if (hours < 24) {
        return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
    } else if (days < 7) {
        return days === 1 ? '1 day ago' : `${days} days ago`;
    } else if (weeks < 4.35) { // More accurate week calculation
        return weeks === 1 ? '1 week ago' : `${weeks} weeks ago`;
    } else if (months < 12) {
        return months === 1 ? '1 month ago' : `${months} months ago`;
    } else {
        return years === 1 ? '1 year ago' : `${years} years ago`;
    }
};

export { formatDateTimeAgo, timeAgo };