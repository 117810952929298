import React, { useState, useEffect } from 'react';
import { Center, Box, HStack, VStack, Flex, Text, Spacer, useBreakpointValue} from '@chakra-ui/react'

import { MdClose } from "react-icons/md";
import { IoArrowForward } from "react-icons/io5";
import { IoArrowBack } from "react-icons/io5";

// Someone
import { IoMdPerson } from "react-icons/io";

//Myself
import { IoPersonOutline } from "react-icons/io5";

//People/Organization
import { IoPeopleSharp } from "react-icons/io5";

// Place 
import { BsBuildings } from "react-icons/bs";

// Product/Service
import { FaBox } from "react-icons/fa";

// Idea 
import { TfiThought } from "react-icons/tfi";

// Angel 
import { GiAngelWings } from "react-icons/gi";

// Send

import { LuSend } from "react-icons/lu";

// Pray 

import { PiHandsPrayingFill } from "react-icons/pi";

// Send 

import { BiMessageAltAdd } from "react-icons/bi";



import { useData } from '../App';

import { useNavigate } from 'react-router-dom';

const PostActionSettings = ({ onShowCreatePost, onShowPublisherSettings }) => {

    const navigate = useNavigate();


    const { 
        saveCategoryData, 
        setStoryJustPublished, 
        setShowPublisherSettings, 
        setShowShareToPrayerSend} = useData();


        const resetAudioPosting = () => {
            setStoryJustPublished(false);
            setShowPublisherSettings(false);
        }
    
    const [isHovered, setIsHovered] = useState(false);
   
 
    const handleHover = (color) => {
        document.querySelectorAll('.hoverable').forEach(element => {
            element.style.color = color;
        });

     
    };



    const goToHome = () => {


        navigate('/requests');


    };

  

    const goBack = () => {
       onShowCreatePost();
      

    };

    const [selectedIcon, setSelectedIcon] = useState(null);
    const isSmallScreen = useBreakpointValue({ base: true, md: false });
    const [storyCategorySelected, setStoryCategorySelected] = useState(false);
    const [category, setCategory] = useState('');
    const [nameCategory, setNameCategory] = useState('');
    const [categoryError, setCategoryError] = useState('');

    const handleClick = (id) => {
        setSelectedIcon(id);
        setStoryCategorySelected(true);
        setCategoryError('');


        if (id) {
            switch (id) {
                case "1":
            //    console.log("Go back to prayer request");
                 resetAudioPosting();
                    break;

                
                case "2":
                    
                  setShowShareToPrayerSend(true);
                
                    break;

                case "3":
                    goToHome();
                    resetAudioPosting();
                  
          // console.log("See other prayer requests");

                    break;

            
               
            }
        }
    };


    const proceedToNextPage = () => {

        if (!storyCategorySelected) {
            setCategoryError("You must choose a selection");
                return;
            } 
    // DO SOMETHING HERE

    }

   const IconWithText = ({ icon, text, id }) => (
        <Box 
            flexDirection="row"
            borderWidth="1px"  
            borderStyle="solid" 
            borderColor={selectedIcon === id ? "black" : "gray.200"} 
            p={2} 
            borderRadius="md" 
            width="100%" 
            height="100%" 
            margin="0.5rem"
            onClick={() => handleClick(id)} // Add onClick event handler
            _hover={{ bg: 'gray.50' }}
            style={{ 
                cursor: "pointer"
            
            }}
           
        >
    
    <Flex flexDirection="row" justifyContent="flex-start" alignItems="center" gap="7px" ml="1rem">
    {icon({ size: 18 })}

    <Text
      style={{
        fontSize: '14px',
        textAlign: 'center',
        cursor: 'pointer',
        fontWeight: selectedIcon === id ? 'bold' : 'normal',
      }}
    >
      {text}
    </Text>
  </Flex>

         


        </Box>
    );
   


    const BigIconWithText = ({ icon, text, id }) => (
        <Box borderWidth="1px" 
        borderStyle="solid" 
        borderColor={selectedIcon === id ? "black" : "gray.200"} 
        p={2} 
        borderRadius="md" 
        width="120px" 
        height="120px" 
        margin="0.5rem"
        onClick={() => handleClick(id)} // Add onClick event handler
        _hover={{ bg: 'gray.50' }} 
        style={{ 
            cursor: "pointer"
        
        }}
        
          
        >
            {icon({ size: 18 })}
            <Text 
                style={{ 
                    fontSize: "14px", 
                    marginTop: "8px", 
                    textAlign: "center", 
                    cursor: "pointer",
                    fontWeight: selectedIcon === id ? "bold" : "normal" 
                
                }}
            >
                {text}
            </Text>
        </Box>
    );
   

  return (



     


    <Center h="100vh" flexDirection="column">


  
    <Box
        position="absolute"
        left="20px"
        top="20px"
        display="flex"
        alignItems="center"
    >
        <>
        <IoArrowBack size={isSmallScreen ? "18px" : "24px"}   onClick={goBack} />
          
                          
              </>
       


    </Box>

     <HStack>
        <div
     className= "glow-effect">
       
    
                            <GiAngelWings size={isSmallScreen ? "14px" : "18px"} color="white" />
                        </div>
        
      
    <Text 
    pt="10px" 
    textAlign="center" 
    as='b' 
    mb="1rem"
    fontSize={isSmallScreen ? "md" : "lg"} // Conditional font size
>
    Your prayer request was sent
</Text>
<div
     className= "glow-effect">
       
    
                            <GiAngelWings size={isSmallScreen ? "14px" : "18px"} color="white" />
                        </div>

</HStack>   
          <Box
            position="absolute"
            right="20px"
            top="20px"
            display="flex"
            alignItems="center"
            onMouseEnter={() => {
                setIsHovered(true);
                handleHover('#3BD528');
            }}
            onMouseLeave={() => {
                setIsHovered(false);
                handleHover('#007AFF');
            }}
            onClick={proceedToNextPage}
        >
            <Text
                as='b'
                fontSize={isSmallScreen ? "sm" : "md"}
                color="#007AFF"
                mr="5px"
                display="inline-block"
                className="hoverable"
                style={{
                       
                    cursor: "pointer",

                }}
            >
                Next
            </Text>
            <IoArrowForward
                size={isSmallScreen ? "18px" : "24px"}
                color="#007AFF"
                className="hoverable"
            />
        </Box>

                    {categoryError && <Text fontSize={12} color="red.500">{categoryError}</Text>}


<Box></Box>
<Box pt="10px">
{isSmallScreen ? (
       
<>
       <VStack justify="center">
     

       <IconWithText icon={BiMessageAltAdd} text="Send another prayer request"  id="1"   />
       <IconWithText icon={LuSend} text="Invite a friend to PrayerSend"  id="2"   />
       <IconWithText icon={IoPeopleSharp} text="See other prayer requests"  id="3"   />

     </VStack>
   
          
          </>
              
         
              ) : (
              <> 
  

  <HStack justify="center">
       <BigIconWithText icon={BiMessageAltAdd} text="Send another prayer request"  id="1"  />
       <BigIconWithText icon={LuSend} text="Invite a friend to PrayerSend"  id="2"  />
       <BigIconWithText icon={IoPeopleSharp} text="See other prayer requests"  id="3"  />
     </HStack>
   
  



    </>

                )}
  </Box>
<Spacer/>


</Center>




  )
}

export default PostActionSettings