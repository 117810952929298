import React, { useRef, useEffect, useState } from 'react';
import { Box, Flex, Text, IconButton } from '@chakra-ui/react';
import { AiOutlineClose, AiOutlineLink } from 'react-icons/ai';
import { LiaWhatsapp, LiaFacebook, LiaInstagram, LiaTelegram, LiaFacebookMessenger, LiaRedditAlien, LiaLinkedin } from 'react-icons/lia';
import { FiMessageCircle } from 'react-icons/fi';
import { RiTwitterXFill } from "react-icons/ri";
import { MdOutlineMailOutline } from "react-icons/md";
import { SlSocialVkontakte } from "react-icons/sl";
import { FacebookShareButton, WhatsappShareButton, FacebookMessengerShareButton, TwitterShareButton, TelegramShareButton, RedditShareButton, LinkedinShareButton, VKShareButton, EmailShareButton } from "react-share";

import { useToast } from '@chakra-ui/react';

const SlideUpPanel = ({ isOpen, onClose, shareLink }) => {
  const panelRef = useRef(null);
  const [shareMessage, setShareMessage] = useState('');

  const toast = useToast();


  const handleClickOutside = (event) => {
    if (panelRef.current && !panelRef.current.contains(event.target)) {
      onClose();
    }
  };

  const handleCloseClick = () => {
    copyLinkToClipboard();
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);



  const copyLinkToClipboard = () => {
    
    const link = shareLink; // Assuming shareLink contains the URL to be copied
    navigator.clipboard.writeText(link)
      .then(() => {
        toast({
          title: "Link copied",
          status: "success",
          duration: 3000, // Optional duration in milliseconds
          isClosable: true,
        });
      
      })
      .catch(error => {
        console.error('Failed to copy link: ', error);
        // Handle error, if any
      });


      
  };

  useEffect(() => {
    setShareMessage('');
    if (shareLink) {
    if (shareLink.includes("listen=")) {
      setShareMessage("Prayer for me on PrayerSend");
    } else {
      setShareMessage("Join me on PrayerSend");
    }
  }

  }, [shareLink]);

  return (
    <Box
      ref={panelRef}
      position="fixed"
      bottom="0"
      left="0"
      width="100%"
      bg="rgba(0, 0, 0, 0.88)" // Dark transparent background
      color="white" // White text color
      borderTop="1px solid #e4e4e4"
      transition="transform 0.3s ease"
      transform={isOpen ? 'translateY(0)' : 'translateY(100%)'}
      zIndex="1"
      pl={5}
      pr={5}
      pt={3}
      pb={3}
    >
      <Flex justify="center" align="center">
        <Text pl="8" fontSize="md" fontWeight="bold" textAlign="center">Share to...</Text>
        <IconButton
          variant="ghost"
          icon={<AiOutlineClose />}
          onClick={handleCloseClick}
          aria-label="Close"
        />
      </Flex>
      <Flex p="2" overflowX="auto" whiteSpace="nowrap" pr={2}>
        {/* Icons and text will be populated here */}
        
      
<Flex onClick={handleCloseClick} >
        <IconBox  icon={<AiOutlineLink />} label="Copy Link" />
        </Flex>
<WhatsappShareButton onClick={handleCloseClick} title={shareMessage} url={shareLink}>
  <IconBox icon={<LiaWhatsapp />} label="WhatsApp" />
</WhatsappShareButton>

{/*
<IconBox icon={<FiMessageCircle />} label="SMS" />
*/}

<FacebookShareButton onClick={handleCloseClick} title={shareMessage} url={shareLink} hashtag="#prayersend">
  <IconBox icon={<LiaFacebook />} label="Facebook" />
</FacebookShareButton>
<TwitterShareButton onClick={handleCloseClick} title={shareMessage} url={shareLink}>
  <IconBox icon={<RiTwitterXFill />} label="Twitter" />
</TwitterShareButton>
<FacebookMessengerShareButton onClick={handleCloseClick} title={shareMessage} url={shareLink}><IconBox icon={<LiaFacebookMessenger />} label="Messenger" /></FacebookMessengerShareButton>



<TelegramShareButton onClick={handleCloseClick} title={shareMessage} url={shareLink}>
  <IconBox icon={<LiaTelegram />} label="Telegram" />
</TelegramShareButton>
{/*
<IconBox icon={<LiaInstagram />} label="Instagram" />
*/}
<RedditShareButton onClick={handleCloseClick} title={shareMessage} url={shareLink}>
  <IconBox icon={<LiaRedditAlien />} label="Reddit" />
</RedditShareButton>
<LinkedinShareButton onClick={handleCloseClick} title={shareMessage} url={shareLink}>
  <IconBox icon={<LiaLinkedin />} label="LinkedIn" />
</LinkedinShareButton>
<VKShareButton onClick={handleCloseClick} title={shareMessage} url={shareLink}>
  <IconBox pr="5" icon={<SlSocialVkontakte />} label="VK" />
</VKShareButton>
<EmailShareButton onClick={handleCloseClick} title={shareMessage} url={shareLink} subject={shareMessage}>
  <IconBox icon={<MdOutlineMailOutline />} label="Email" />
</EmailShareButton>


        
      </Flex>
    </Box>
  );
};

const IconBox = ({ icon, label }) => {
  return (
    <Flex flexDirection="column" alignItems="center" p="2">
      {React.cloneElement(icon, { size: "24px" })} {/* Adjust the size as needed */}
      <Text fontSize="xs" mt="1">
        {label}
      </Text>
    </Flex>
  );
};

export default SlideUpPanel;