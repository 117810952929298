import React, { useState, useRef, useEffect } from 'react';
import '../../PostPage/seekbar.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { currentUserId } from '../../Utilities/firebase';

import { VStack, Flex, Text, Image, HStack, Box } from '@chakra-ui/react';
import { FaCirclePlay } from "react-icons/fa6";
import { FaCirclePause } from "react-icons/fa6";



import { useData } from '../../App';
import PrayingHandsGlow from '../../PrayingHandsGlow';

const ProfilePrayerAudio = ({ postAudio, postAudioDuration, postTimes_Listened, creatorUID,
    userProfilePhoto,
    name,
    username,
    postDate, prayerRequestTitle, selfProfilePhoto, userProfileId, profileOwnerName }) => {





    const [isPlaying, setIsPlaying] = useState(false); // Change initial state to true
    const [currentTime, setCurrentTime] = useState(0);
    const [triggered, setTriggered] = useState(false);
    const [userInteracted, setUserInteracted] = useState(false);


    const { openPrayerSenderPopup, setPrayerIsPlaying, prayerRequestIsPlaying, getUserProfilePageURL } = useData();


    const audioRef = useRef(null);

    const { pathname } = useLocation();

    const navigate = useNavigate();




    const goToUserProfile = async () => {
   
           await getUserProfilePageURL(username);
      
           
          navigate(`/${username}`);
    }


    const includedTalkCirclePaths = ['/talkcircles'];
    const isUserOnTalkCircle = includedTalkCirclePaths.some(path => pathname.includes(path));

    // Convert time format "M:SS" to seconds
    const convertTimeToSeconds = (time) => {
        if (!time || !time.includes(':')) {
            console.error('Invalid time format:', time);
            return 0; // Return 0 or a default value to avoid further errors
        }
        const [minutes, seconds] = time.split(':').map(Number);
        return minutes * 60 + seconds;
    };
    const [durationInSeconds, setDurationInSeconds] = useState(0);

    useEffect(() => {
        if (postAudioDuration) {
            setDurationInSeconds(convertTimeToSeconds(postAudioDuration));
        }
    }, [postAudioDuration]);

    useEffect(() => {
        const audioElement = audioRef.current;
        // Check if the audio element is paused
        if (prayerRequestIsPlaying) {
            audioElement.pause();
            setIsPlaying(false);

        }
    }, [prayerRequestIsPlaying]);



    useEffect(() => {
        const audioElement = audioRef.current;


        // Start playing audio only when isPlaying state is true
        if (isPlaying) {
            audioElement.play();

            const playPromise = audioElement.play();
            if (playPromise !== undefined && !isUserOnTalkCircle) {
                playPromise.catch(error => {

                    // console.error("Playback prevented by the browser. User interaction required.", error);
                    audioElement.play();
                    setPrayerIsPlaying(true);

                });
            }

        } else {
            audioElement.pause();

            setTriggered(false);

        }

        // Cleanup function to pause audio when the component unmounts
        return () => {
            audioElement.pause();
        };
    }, [isPlaying, postAudio]);




    const updateListenCount = () => {
        // Convert string to integer, increment, and possibly update database

        const newListenCount = parseInt(postTimes_Listened, 10) + 1;

        if (newListenCount) {

            // updateNumberOfListens(storyURL, postId, newListenCount);

        }

    };












    // Add this useEffect to handle the end of audio playback
    useEffect(() => {
        const audioElement = audioRef.current;

        const handleEnded = () => {
            setIsPlaying(false); // Set isPlaying to false when playback ends

            setPrayerIsPlaying(false);


        };

        audioElement.addEventListener('ended', handleEnded);

        return () => {
            audioElement.removeEventListener('ended', handleEnded);
        };
    }, [postAudio]);


    useEffect(() => {
        const audioElement = audioRef.current;

        if (openPrayerSenderPopup) {
            audioElement.pause();
            setIsPlaying(false); // Set isPlaying to false when playback ends
            setPrayerIsPlaying(false);
        }


    }, [openPrayerSenderPopup]);






    useEffect(() => {

        if (!triggered && durationInSeconds < 30 && (durationInSeconds - currentTime <= 2) && currentUserId !== creatorUID) {

            if (!isUserOnTalkCircle) {
                updateListenCount();
            }
            setTriggered(true);

        }

        if (!triggered && currentTime >= 30 && currentUserId !== creatorUID) {
            if (!isUserOnTalkCircle) {
                updateListenCount();
            }
            setTriggered(true);
        }
    }, [currentTime, currentUserId, creatorUID, triggered]);


    //         setPrayerIsPlaying(prevIsPlaying => !prevIsPlaying);



    /*
        const togglePlay = () => {
           
    
            setIsPlaying(prevIsPlaying => !prevIsPlaying);
    
        };
    
    */

    const togglePlay = () => {
        const audioElement = audioRef.current;

        // Pause the previous audio if it was playing
        const previousAudio = document.querySelector('audio.isPlaying');
        if (previousAudio && previousAudio !== audioElement) {
            previousAudio.pause();
            previousAudio.classList.remove('isPlaying');
            setIsPlaying(false);
        }

        // Play or pause the current audio
        if (!isPlaying) {
            audioElement.play();
            audioElement.classList.add('isPlaying');
        } else {
            audioElement.pause();
            audioElement.classList.remove('isPlaying');

        }

        // Update the state
        setIsPlaying(!isPlaying);
        setPrayerIsPlaying(!isPlaying);
    };


    const handleTimeUpdate = () => {
        setCurrentTime(audioRef.current.currentTime);
    };



    const handleSeek = (e) => {
        const newTime = e.target.value;
        setCurrentTime(newTime);
        audioRef.current.currentTime = newTime;
    };



    const playedPercentage = (currentTime / durationInSeconds) * 100;
    const seekBarStyle = {
        '--played-width': `${playedPercentage}%`,
        background: `linear-gradient(to right, ${isPlaying ? '#FFCC00' : '#64a4d3'} ${playedPercentage}%, #c4c4c4 ${playedPercentage}%)`
    };



    useEffect(() => {
        // Remove 'isPlaying' class from previously playing audio on component mount
        // and whenever the post audio source changes
        const previousAudio = document.querySelector('audio.isPlaying');
        if (previousAudio) {
            previousAudio.classList.remove('isPlaying');
        }
    }, [postAudio]); // Add postAudio as a dependency



    return (


        <VStack  >

            <HStack pr="20px">
                <Image
                    borderRadius="full"
                    boxSize="30px"
                    src={selfProfilePhoto}
                    alt="profile pic"
                />
                <Box zIndex={-1}>

                    <PrayingHandsGlow isPlaying={isPlaying} />



                </Box>


                <button className="play-pause-button" onClick={togglePlay}>
                    {isPlaying ? <FaCirclePause color="#FFCC00" size={24} /> : <FaCirclePlay size={24} color="#64a4d3" />}
                </button>



                <div className="post-audio">
                    <audio
                        ref={audioRef}
                        src={postAudio}
                        onTimeUpdate={handleTimeUpdate}

                    // onPlay={() => setIsPlaying(true)}
                    // onPause={() => setIsPlaying(false)}
                    />

                    <div className="audio-controls">






                        <input
                            type="range"
                            className="seek-bar no-thumb"
                            min="0"
                            max={durationInSeconds}
                            value={currentTime}
                            onChange={handleSeek}
                            style={seekBarStyle}
                        />





                    </div>
                </div>


            </HStack>

            <Box>
                {userProfileId !== currentUserId ? (
                    <>
                        <Text fontSize="12px" as="b">{profileOwnerName} prayed for {name}</Text>

                    </>

                ) : (
                <>
                    <Text fontSize="12px" as="b">You prayed for {name}</Text>
                </>
 
   )}
            </Box>


            <HStack>





                <Flex alignItems="center" flexDirection="row" gap="5px">
                    {/* Profile picture (small) */}


                    <Text as="i" fontSize="12px">"{prayerRequestTitle}.."</Text>

                    <Flex flexDirection="row" onClick={goToUserProfile}>
                        <Image
                            borderRadius="full"
                            boxSize="30px"
                            src={userProfilePhoto}
                            alt="profile pic"
                        />




                        {/* Name and username */}
                        <Flex flexDirection="column" ml={2}>
                            <Text fontSize="12">{name}</Text>
                            <Text fontSize="12">{username}</Text>
                        </Flex>
                    </Flex>

                </Flex>


            </HStack>





        </VStack>




    );
};

const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};
export default ProfilePrayerAudio